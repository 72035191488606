<template>
  <div class="promotion" @click="toThreeWeb(config.url)">
    <yqt-image
      class="img"
      :src="config.image"
      lazyload
      :loading-img="loadingImg"
      fit="cover"
      alt="item pic"
    />
  </div>
</template>

<script>
import YqtImage from "@/components/image/Image";
const loadingImg = require("@/static/image/loading_750x80.gif");

export default {
  name: "Promotion",
  components: {
    YqtImage,
  },
  props: ["config"],
  data() {
    return {
      loadingImg: loadingImg,
    };
  },
  methods: {
    toThreeWeb(url) {
      location.href = url;
    },
  },
};
</script>

<style lang="less" scoped>
@import "../../common/less/variable.less";
@import "../../common/less/mixin.less";

.promotion {
  width: 100%;
  // margin-bottom: .2rem;
  margin-top: 0.2rem;

  .img {
    width: 92%;
    height: 1.52rem;
    margin-bottom: 0.1rem;
    margin-left: 4%;
  }
}
</style>
