var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "page", class: _vm.white },
    [
      _c("HeadNav"),
      _c(
        "div",
        {
          staticClass: "search-blank flex-center",
          attrs: { slot: "navbar" },
          slot: "navbar",
        },
        [
          _c("search-box", {
            attrs: { type: "search", search_val: _vm.keyword },
            on: {
              search: _vm.searchItem,
              input: _vm.checkInput,
              changeKeyword: _vm.changeKeyword,
            },
          }),
        ],
        1
      ),
      _c(
        "yd-infinitescroll",
        { ref: "infinitescrollDemo", attrs: { callback: _vm.loadItemList } },
        [
          _c(
            "div",
            {
              staticClass: "search-result-wrap",
              attrs: { slot: "list" },
              slot: "list",
            },
            [
              !_vm.pointsTopic
                ? _c("div", { staticClass: "price-search" }, [
                    _c(
                      "div",
                      {
                        staticClass: "price-input flex-center",
                        staticStyle: { width: "3.8rem" },
                      },
                      [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.min_price,
                              expression: "min_price",
                            },
                          ],
                          attrs: { type: "number", placeholder: "最低价格" },
                          domProps: { value: _vm.min_price },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.min_price = $event.target.value
                            },
                          },
                        }),
                      ]
                    ),
                    _c("span", { staticClass: "line" }),
                    _c(
                      "div",
                      {
                        staticClass: "price-input flex-center",
                        staticStyle: { width: "3.8rem" },
                      },
                      [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.max_price,
                              expression: "max_price",
                            },
                          ],
                          attrs: { type: "number", placeholder: "最高价格" },
                          domProps: { value: _vm.max_price },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.max_price = $event.target.value
                            },
                          },
                        }),
                      ]
                    ),
                  ])
                : _vm._e(),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.itemList.length,
                      expression: "itemList.length",
                    },
                  ],
                  staticClass: "search-tab",
                },
                [
                  _c(
                    "ul",
                    { staticClass: "tab-list" },
                    _vm._l(_vm.tabs, function (item, index) {
                      return _c(
                        "li",
                        {
                          key: index,
                          class: { active: _vm.activeTab === index },
                          on: {
                            click: function ($event) {
                              return _vm.clickTab(index)
                            },
                          },
                        },
                        [
                          _c("span", { staticClass: "txt" }, [
                            _vm._v(_vm._s(item)),
                          ]),
                          index === 2 || (_vm.pointsTopic && index === 1)
                            ? _c("i", {
                                staticClass: "icon icon-price",
                                class: {
                                  rise:
                                    _vm.activeTab === index && _vm.priceToggle,
                                  drop:
                                    _vm.activeTab === index && !_vm.priceToggle,
                                },
                              })
                            : _vm._e(),
                        ]
                      )
                    }),
                    0
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "btn toggle-btn flex-center",
                      on: { click: _vm.toggleStyle },
                    },
                    [
                      _c("i", {
                        staticClass: "icon icon-col",
                        class: { row: !!_vm.rowToggle },
                      }),
                    ]
                  ),
                ]
              ),
              !_vm.rowToggle
                ? _c(
                    "div",
                    { staticClass: "search-result" },
                    [
                      _c("item-list", {
                        attrs: { itemList: _vm.itemList },
                        on: { "click-item": _vm.clickItem },
                      }),
                    ],
                    1
                  )
                : _c(
                    "div",
                    { staticClass: "search-result" },
                    [
                      _c("row-item-list", {
                        attrs: { itemList: _vm.itemList },
                        on: { "click-item": _vm.clickItem },
                      }),
                    ],
                    1
                  ),
            ]
          ),
          _c(
            "span",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.itemList.length,
                  expression: "itemList.length",
                },
              ],
              attrs: { slot: "doneTip" },
              slot: "doneTip",
            },
            [_vm._v("- 没有更多了 -")]
          ),
        ]
      ),
      _c("yd-backtop"),
      _vm.searchHistory.length && _vm.showHistory
        ? _c("div", { staticClass: "search-history-wrap" }, [
            _c("h3", { staticClass: "title" }, [_vm._v("搜索历史记录")]),
            _c(
              "ul",
              { staticClass: "search-history" },
              _vm._l(_vm.searchHistory, function (item, index) {
                return _c(
                  "li",
                  {
                    key: index,
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                        return _vm.searchItem(item)
                      },
                    },
                  },
                  [
                    _c("i", { staticClass: "icon icon-time" }),
                    _c("p", { staticClass: "txt" }, [_vm._v(_vm._s(item))]),
                    _c(
                      "div",
                      {
                        staticClass: "btn del-btn flex-center",
                        on: {
                          click: function ($event) {
                            $event.stopPropagation()
                            return _vm.deleteHistory(index)
                          },
                        },
                      },
                      [_c("i", { staticClass: "icon icon-delete" })]
                    ),
                  ]
                )
              }),
              0
            ),
            _c(
              "footer",
              {
                staticClass: "clear-history",
                on: { click: _vm.clearAllHistory },
              },
              [_vm._v("清空搜索历史")]
            ),
          ])
        : _vm._e(),
      _vm.emptyResult
        ? _c("div", { staticClass: "search-none" }, [
            _c("div", { staticClass: "icon icon-search-none" }),
            _c("p", { staticClass: "tip" }, [
              _vm._v("没有搜到相关产品，换个名称试试吧"),
            ]),
            _c(
              "div",
              {
                staticClass: "btn go-btn flex-center",
                on: { click: _vm.goShopping },
              },
              [_vm._v("去逛逛")]
            ),
          ])
        : _vm._e(),
      _c("confirm-alert", {
        attrs: { confirmText: "确定清空所有历史记录吗" },
        on: { cancel: _vm.cancelClear, confirm: _vm.confirmClear },
        model: {
          value: _vm.showClearAlert,
          callback: function ($$v) {
            _vm.showClearAlert = $$v
          },
          expression: "showClearAlert",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }