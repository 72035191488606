var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "page" }, [
    _c(
      "div",
      { staticClass: "group-item-wrap" },
      [
        _c("div", { staticClass: "item-info" }, [
          _c("div", { staticClass: "img-box" }, [
            _c("img", {
              attrs: { src: _vm.item_info.base_pic, alt: "item img" },
            }),
          ]),
          _c("div", { staticClass: "img-right" }, [
            _c("h3", { staticClass: "item-name" }, [
              _vm._v(_vm._s(_vm.item_info.item_name)),
            ]),
            _c("h4", { staticClass: "item-intro" }, [
              _vm._v(_vm._s(_vm.item_info.item_desc)),
            ]),
            _c(
              "ul",
              { staticClass: "feature-list" },
              _vm._l(_vm.sku_info, function (item, index) {
                return _c("li", { key: index }, [
                  _vm._v(_vm._s(item.pro_info.value)),
                ])
              }),
              0
            ),
            _vm.businessType == 2 && _vm.showWay == 1
              ? _c("p", { staticClass: "item-price" }, [
                  _vm.price_status == 1
                    ? _c("span", { staticClass: "txt" }, [_vm._v("拼团价：")])
                    : _vm._e(),
                  _vm.mall_price_status == 1
                    ? _c("span", { staticClass: "price" }, [
                        _c("span", { staticClass: "num" }, [
                          _vm._v(
                            _vm._s(
                              (
                                _vm.group_buy_open_info.open_price *
                                _vm.integralRate
                              ).toFixed(2)
                            )
                          ),
                        ]),
                        _c("span", { staticClass: "sign" }, [_vm._v("积分")]),
                      ])
                    : _vm._e(),
                  _vm.mall_price_status == 1
                    ? _c("span", { staticClass: "old-price" }, [
                        _vm._v(
                          _vm._s(
                            (
                              _vm.item_info.mall_price * _vm.integralRate
                            ).toFixed(2)
                          ) + "积分"
                        ),
                      ])
                    : _vm._e(),
                ])
              : _c("p", { staticClass: "item-price" }, [
                  _vm.price_status == 1
                    ? _c("span", { staticClass: "txt" }, [_vm._v("拼团价：")])
                    : _vm._e(),
                  _vm.price_status == 1
                    ? _c("span", { staticClass: "price" }, [
                        _c("span", { staticClass: "sign" }, [_vm._v("¥")]),
                        _c("span", { staticClass: "num" }, [
                          _vm._v(_vm._s(_vm.group_buy_open_info.open_price)),
                        ]),
                      ])
                    : _vm._e(),
                  _vm.mall_price_status == 1
                    ? _c("span", { staticClass: "old-price" }, [
                        _vm._v("¥" + _vm._s(_vm.item_info.mall_price)),
                      ])
                    : _vm._e(),
                ]),
            _c("span", { staticClass: "item-num" }, [
              _vm._v("x" + _vm._s(_vm.group_buy_open_info.item_num)),
            ]),
          ]),
        ]),
        _c("group-play"),
      ],
      1
    ),
    _c("div", { staticClass: "group-info" }, [
      _vm.hasPay
        ? _c("h3", { staticClass: "title" }, [
            _c("span", { staticClass: "gray-txt" }, [_vm._v("还差")]),
            _c("span", { staticClass: "num" }, [
              _vm._v(_vm._s(_vm.group_buy_open_info.surplus_num)),
            ]),
            _c("span", { staticClass: "gray-txt" }, [_vm._v("人即可拼团成功")]),
          ])
        : _c("h3", { staticClass: "title" }, [_vm._v("支付成功即可开始组团")]),
      _c("div", { staticClass: "group-list-wrap" }, [
        _c(
          "ul",
          { staticClass: "group-list" },
          _vm._l(_vm.groupList, function (item, index) {
            return _c("li", { key: index, class: { active: !!item.user_id } }, [
              _c("img", {
                attrs: { src: item.user_info.heagurl, alt: "headimg" },
                on: { error: _vm.errorHeadImg },
              }),
              item.user_id === _vm.group_open_user_id
                ? _c("span", { staticClass: "head-badge" }, [_vm._v("团长")])
                : _vm._e(),
            ])
          }),
          0
        ),
        _vm.hasPay
          ? _c(
              "p",
              { staticClass: "countdown-wrap" },
              [
                !_vm.hasOver
                  ? [
                      _c("span", { staticClass: "gray-txt" }, [_vm._v("剩余")]),
                      _c(
                        "yd-countdown",
                        {
                          attrs: {
                            time: _vm.group_buy_open_info.end_time,
                            callback: (_vm.hasOver = false),
                            timetype: "timestamp",
                            format: "{%h}:{%m}:{%s}",
                          },
                        },
                        [
                          _c("span", { staticClass: "time" }, [
                            _vm._v("{%h}:{%m}:{%s}"),
                          ]),
                        ]
                      ),
                      _c("span", { staticClass: "gray-txt" }, [_vm._v("失效")]),
                    ]
                  : [
                      _c("span", { staticClass: "gray-txt" }, [
                        _vm._v("已结束"),
                      ]),
                    ],
              ],
              2
            )
          : _vm._e(),
      ]),
    ]),
    _c(
      "div",
      {
        staticClass: "btn buy-btn flex-center",
        on: { click: _vm.handleGroup },
      },
      [_vm._v("立即拼团")]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }