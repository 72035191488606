<template>
  <div id="group-buy">
    <h3 class="title">{{ title }}</h3>
    <div class="cell">
      <span class="left">
        <span class="brief">{{ brief }}</span>
      </span>
      <span class="right" @click.prevent="clickMore">
        <span class="btn check-btn">查看更多</span>
      </span>
      <i class="icon icon-arrow-right" @click.prevent="clickMore"></i>
    </div>
    <ul class="item-list">
      <li
        class="item"
        v-for="(item, index) in groupList"
        :key="index"
        @click="clickGroup(item)"
      >
        <div class="img-box">
          <yqt-image
            class="img"
            :src="item.base_pic"
            lazyload
            :loading-img="loadingImg"
            fit="cover"
            alt="item pic"
          />
        </div>
        <p class="item-name">{{ item.item_name }}</p>
        <p class="item-price" v-if="businessType == 2">
          <span class="price" v-if="price_status == 1">
            <span class="num">
              {{ priceShow(item.display_type, item.group_buy_price) }}
            </span>
          </span>
        </p>
        <p class="item-price" v-else>
          <span class="price" v-if="price_status == 1">
            <span class="sign">&yen;</span>
            <span class="num">{{ item.group_buy_price }}</span>
          </span>
        </p>
        <span class="btn buy-btn">拼团</span>
        <!-- /成团数 -->
        <div class="group-num-wrap">
          <yd-badge bgcolor="#FE552E" color="#FFF">
            <span class="group-num">{{ item.people_limit }}人成团</span>
          </yd-badge>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { getGroup } from "@/services";
import { priceShow } from "@/utils/goodsUtil";
import YqtImage from "@/components/image/Image";
const loadingImg = require("@/static/image/loading_320x320.gif");

const TITLE = "超值团购";
export default {
  name: "GroupBuy",
  components: {
    YqtImage,
  },
  data() {
    return {
      title: "", // 标题
      groupList: [], // 秒杀列表
      loadingImg: loadingImg,
    };
  },
  props: {
    brief: {
      type: String,
      default: "",
    },
  },
  computed: {
    ...mapState({
      businessType: (state) => state.businessInfo.type, //1现金商城，2积分商品，3内采商城
      showWay: (state) => state.config.show_way, // 商品展示方式，1积分，2现金
      price_status: (state) => state.config.price_status, // 是否展示价格 1展示 0 不展示
      mall_price_status: (state) => state.config.mall_price_status, //  是否展示价格 1展示 0 不展示
      integralRate: (state) => state.config.integral_rate, // 积分比例
    }),
  },
  created() {
    this.title = TITLE;
    this.loadData();
  },
  methods: {
    // 积分3.0显示
    priceShow,

    // 获取数据
    async loadData() {
      try {
        const res = await getGroup(this.$store.state.userInfo.business_id);
        if (res.code === 0) {
          this.groupList = res.data;
        } else {
          this.$dialog.toast({
            mes: res.msg,
            icon: "error",
          });
        }
      } catch (error) {
        console.warn(error);
      }
    },
    // 点击团购商品
    clickGroup(item) {
      this.$router.push({
        name: "goods-detail",
        query: {
          item_id: item.item_id,
        },
      });
    },
    // 点击查看更多
    clickMore() {
      this.$router.push("group_buy");
    },
  },
};
</script>

<style lang="less" scoped>
@import "../../common/less/variable.less";
@import "../../common/less/mixin.less";

#group-buy {
  padding: 0.4rem 0.3rem 0.2rem 0.3rem;

  .title {
    .sc(@fontsize-large-xx, @color-dark-grey);
    line-height: 0.56rem;
    font-weight: bold;
  }

  .cell {
    position: relative;
    .fj;
    align-items: center;
    padding-right: 0.24rem;

    .left {
      .brief {
        .sc(@fontsize-large, @color-light-grey);
        line-height: 0.44rem;
      }
    }

    .right {
      position: relative;
      display: flex;
      justify-content: flex-end;

      .check-btn {
        .sc(@fontsize-medium, @color-light-grey);
        line-height: 0.4rem;
      }
    }

    .icon-arrow-right {
      .ct;
      right: 0;
      display: inline-block;
      .wh(0.12rem, 0.21rem);
      .bis("../../assets/icpn_gengduo");
    }
  }

  .item-list {
    position: relative;
    overflow: hidden;

    .item {
      .wh(3.35rem, 4.85rem);
      margin-top: 0.2rem;
      float: left;
      position: relative;
      background: #fff;
      box-shadow: 0px 3px 20px 0px rgba(0, 0, 0, 0.05);
      border-radius: 0.08rem;
      overflow: hidden;
      z-index: 0;

      .img-box {
        .wh(100%, 3.35rem);
        margin-bottom: 0.14rem;
        .img {
          .wh(100%, 100%);
        }
      }

      &:nth-child(odd) {
        margin-right: 0.2rem;
      }

      .item-name {
        padding: 0 0.2rem;
        margin-bottom: 0.12rem;
        .sc(0.26rem, rgba(0, 0, 0, 0.87));
        line-height: 0.36rem;
        word-break: break-all;
        word-wrap: break-word;
        .ellipsis(2);
      }

      .item-price {
        padding-left: 0.2rem;

        .price {
          color: @color-dark-grey;
          line-height: 0.28rem;
          font-weight: bold;

          .sign {
            font-size: @fontsize-small;
          }

          .num {
            font-size: @fontsize-large;
          }
        }
      }

      .buy-btn {
        position: absolute;
        right: 0;
        bottom: 0.2rem;
        .wh(0.9rem, 0.48rem);
        padding-left: 0.26rem;
        padding-top: 0.08rem;
        .sc(@fontsize-small, #fff);
        line-height: 0.34rem;
        font-weight: 500;
        white-space: nowrap;
        background-color: var(--main-color);
        border-radius: 2rem 0px 0px 2rem;
        z-index: 2;
      }

      .group-num-wrap {
        position: absolute;
        top: 0.2rem;
        left: 0.2rem;
        display: flex;
        align-items: center;
        z-index: 2;

        .group-num {
          .sc(0.22rem, #fff);
          line-height: 0.32rem;
          white-space: nowrap;
        }
      }
    }
  }
}
</style>
