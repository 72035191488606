<template>
  <div id="seconds-kill">
    <h3 class="title">{{ title }}</h3>
    <div class="cell">
      <span class="left">
        <span class="brief">{{ brief }}</span>
      </span>
      <span class="right" @click.prevent="clickMore">
        <span class="btn check-btn">查看更多</span>
      </span>
      <i class="icon icon-arrow-right" @click.prevent="clickMore"></i>
    </div>
    <ul class="kill-list" v-lazy-container="lazyloadConf">
      <li
        class="kill-item"
        v-for="(item, index) in killList"
        :key="index"
        @click="clickSeconds(item)"
      >
        <div class="img-box">
          <img :data-src="item.base_pic" alt="item pic" />
        </div>
        <p class="item-name">
          <span class="source-name" v-if="item.source_type">
            {{ getSourceTypes(item.source_type) }}
          </span>
          {{ item.item_name }}
        </p>
        <!-- <p class="item-price" v-if="businessType == 2">
			<span class="seconds-price" v-if="price_status==1">
				<span class="num">{{(item.limit_buy_price * integralRate).toFixed(2)}}</span>
				<span class="sign">积分</span>
			</span>
			<span class="old-price" v-if="price_status==1">{{(item.price * integralRate).toFixed(2)}}积分</span>
        </p> -->
        <p class="item-price" v-if="businessType == 2">
          <span class="seconds-price" v-if="price_status == 1">
            <span class="num">
              {{
                priceShow(
                  item.display_type,
                  item.limit_buy_price,
                  item.max_integral_percentage || ""
                )
              }}
            </span>
          </span>
          <span class="old-price" v-if="price_status == 1">
            {{
              priceShow(
                item.display_type,
                item.mall_price,
                item.max_integral_percentage || ""
              )
            }}
          </span>
        </p>
        <p class="item-price" v-else>
          <span class="seconds-price" v-if="price_status == 1">
            <span class="sign">&yen;</span>
            <span class="num">{{ item.limit_buy_price }}</span>
          </span>
          <span class="old-price" v-if="price_status == 1"
            >&yen;{{ item.mall_price }}</span
          >
        </p>
        <span class="btn kill-btn">抢购</span>
        <!-- /场次、倒计时 -->
        <div class="countdown-wrap">
          <span v-if="item.kill_status === 1" class="session">{{
            item.start_time | formatDateMDS
          }}</span>
          <span v-else class="session">秒杀中</span>
          <yd-countdown
            :time="item.end_time"
            timetype="timestamp"
            format="{%h}:{%m}:{%s}"
          >
            <em class="time">{%h}</em><i>:</i> <em class="time">{%m}</em
            ><i>:</i>
            <em class="time">{%s}</em>
          </yd-countdown>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { getSeconds } from "@/services";
import { priceShow } from "@/utils/goodsUtil";
import { orderMixin } from "@/mixins/orderMixin";
const loadingImg = require('@/static/image/loading_320x320.gif')

const TITLE = "限时秒杀",
  BRIEF = "秒杀的快感";
export default {
  name: "SecondsKill",
  mixins: [orderMixin],
  data() {
    return {
      title: "", // 标题
      killList: [], // 秒杀列表
      lazyloadConf: {
        selector: "img",
        loading: loadingImg,
      },
    };
  },
  props: {
    brief: {
      type: String,
      default: "",
    },
  },
  computed: {
    ...mapState({
      businessType: (state) => state.businessInfo.type, //1现金商城，2积分商品，3内采商城
      price_status: (state) => state.config.price_status, // 是否展示价格 1展示 0 不展示
      mall_price_status: (state) => state.config.mall_price_status, //  是否展示价格 1展示 0 不展示
      integralRate: (state) => state.config.integral_rate, // 积分比例
    }),
  },
  created() {
    this.title = TITLE;
    this.loadData();
  },
  methods: {
    // 积分3.0显示
    priceShow,

    // 获取数据
    async loadData() {
      try {
        const res = await getSeconds(this.$store.state.userInfo.business_id);
        if (res.code === 0) {
          this.killList = res.data;
          this.killList.forEach((item, index) => {
            let nowDate = Date.parse(new Date()) / 1000;
            if (nowDate < item.start_time) {
              this.$set(this.killList[index], "kill_status", 1); // 1  表示还未开始
            } else {
              this.$set(this.killList[index], "kill_status", 2); // 2  表示已开始
            }
          });
          console.log("killList", this.killList);
        } else {
          this.$dialog.toast({
            mes: res.msg,
            icon: "error",
          });
        }
      } catch (error) {
        console.warn(error);
      }
    },
    // 点击秒杀商品
    clickSeconds(item) {
      this.$router.push({
        name: "goods-detail",
        query: {
          item_id: item.item_id,
          item_active_type: 2,
        },
      });
    },
    // 点击查看更多
    clickMore() {
      this.$router.push("seconds_kill");
    },
  },
};
</script>

<style lang="less" scoped>
@import "../../common/less/variable.less";
@import "../../common/less/mixin.less";

#seconds-kill {
  padding: 0.4rem 0.3rem 0;

  .title {
    .sc(@fontsize-large-xx, @color-dark-grey);
    line-height: 0.56rem;
    font-weight: bold;
  }

  .cell {
    position: relative;
    .fj;
    align-items: center;
    padding-right: 0.24rem;

    .left {
      .brief {
        .sc(@fontsize-large, @color-light-grey);
        line-height: 0.44rem;
      }
    }

    .right {
      position: relative;
      display: flex;
      justify-content: flex-end;

      .check-btn {
        .sc(@fontsize-medium, @color-light-grey);
        line-height: 0.4rem;
      }
    }

    .icon-arrow-right {
      .ct;
      right: 0;
      display: inline-block;
      .wh(0.12rem, 0.21rem);
      .bis("../../assets/icpn_gengduo");
    }
  }

  .kill-list {
    position: relative;
    overflow: hidden;

    .kill-item {
      .wh(3.3rem, 4.6rem);
      margin-top: 0.2rem;
      float: left;
      position: relative;
      background: #fff;
      box-shadow: 0px 3px 20px 0px rgba(0, 0, 0, 0.05);
      border-radius: 0.08rem;
      overflow: hidden;
      z-index: 0;

      .img-box {
        .wh(100%, 2.6rem);
        margin-bottom: 0.14rem;

        img {
          .wh(100%, 100%);
        }
      }

      .item-name {
        padding: 0 0.2rem;
        margin-bottom: 0.12rem;
        .sc(0.26rem, rgba(0, 0, 0, 0.87));
        line-height: 0.36rem;
        word-break: break-all;
        word-wrap: break-word;
        .ellipsis(2);

        .source-name {
          position: relative;
          top: -0.02rem;
          display: inline-block;
          height: 0.3rem;
          line-height: 0.355rem;
          padding: 0 0.15rem;
          border-radius: 0.2rem;
          background-color: var(--main-color);
          color: #ffffff;
          font-size: 0.18rem;
        }
      }

      .item-price {
        padding-left: 0.2rem;

        .seconds-price {
          color: @color-dark-grey;
          line-height: 0.28rem;
          font-weight: bold;

          .sign {
            font-size: @fontsize-small;
          }

          .num {
            font-size: @fontsize-large;
          }
        }

        .old-price {
          display: inline-block;
          margin-left: 0.08rem;
          .sc(0.16rem, rgba(0, 0, 0, 0.38));
          line-height: 0.2rem;
          font-weight: bold;
          text-decoration: line-through;
        }
      }

      .kill-btn {
        position: absolute;
        right: 0;
        bottom: 0.2rem;
        .wh(0.9rem, 0.48rem);
        padding-left: 0.26rem;
        padding-top: 0.08rem;
        .sc(@fontsize-small, #fff);
        line-height: 0.34rem;
        font-weight: 500;
        white-space: nowrap;
        background-color: var(--main-color);
        border-radius: 2rem 0px 0px 2rem;
        z-index: 2;
      }

      .countdown-wrap {
        position: absolute;
        top: 0.2rem;
        left: 0.2rem;
        display: flex;
        align-items: center;
        .wh(2.4rem, 0.42rem);
        padding-left: 1.08rem;
        .bis("../../assets/img_home_miaosha_time");
        z-index: 2;

        .session {
          .ct;
          left: 0.2rem;
          display: inline-block;
          .sc(@fontsize-small, #fff);
          line-height: 0.28rem;
          font-weight: bold;
          white-space: nowrap;
          z-index: 3;
        }

        .time {
          .sc(@fontsize-small, #FE552E);
          // line-height: .28rem;
          font-weight: bold;
          text-align: center;
        }

        & /deep/ span {
          text-align: center;
        }
      }

      &:nth-child(odd) {
        margin-right: 0.3rem;
      }
    }
  }
}
</style>
