var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "page" },
    [
      _c(
        "yd-infinitescroll",
        { ref: "infinitescrollDemo", attrs: { callback: _vm.loadList } },
        [
          _c(
            "ul",
            {
              staticClass: "comment-list",
              attrs: { slot: "list" },
              slot: "list",
            },
            _vm._l(_vm.list, function (item, index) {
              return _c(
                "li",
                {
                  key: index,
                  staticClass: "comment-item",
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      return _vm.clickComment(item)
                    },
                  },
                },
                [
                  _c("div", { staticClass: "headimg-box" }, [
                    _c("img", { attrs: { src: item.heagurl, alt: "headimg" } }),
                  ]),
                  _c("div", { staticClass: "headimg-right" }, [
                    _c("div", { staticClass: "title" }, [
                      _c("span", { staticClass: "nickname" }, [
                        _vm._v(_vm._s(item.nickname)),
                      ]),
                      _c(
                        "div",
                        { staticClass: "rate-wrap" },
                        [
                          _c("yd-rate", {
                            attrs: {
                              readonly: true,
                              "active-color": "#FF5252",
                              size: "0.2rem",
                            },
                            model: {
                              value: item.star,
                              callback: function ($$v) {
                                _vm.$set(item, "star", $$v)
                              },
                              expression: "item.star",
                            },
                          }),
                          _c("span", { staticClass: "time" }, [
                            _vm._v(
                              _vm._s(_vm._f("formatDate")(item.create_time))
                            ),
                          ]),
                        ],
                        1
                      ),
                    ]),
                    _c("p", { staticClass: "comment-txt" }, [
                      _vm._v(_vm._s(item.content)),
                    ]),
                    item.photo_list.length
                      ? _c(
                          "div",
                          { staticClass: "comment-pic-wrap" },
                          [
                            _c(
                              "yd-lightbox",
                              {
                                staticClass: "img-list",
                                attrs: { num: item.photo_list.length },
                              },
                              _vm._l(item.photo_list, function (i, k) {
                                return _c("yd-lightbox-img", {
                                  key: k,
                                  attrs: { src: i.img },
                                  nativeOn: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                    },
                                  },
                                })
                              }),
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                  ]),
                ]
              )
            }),
            0
          ),
          _c("span", { attrs: { slot: "doneTip" }, slot: "doneTip" }, [
            _vm._v("- 没有更多了 -"),
          ]),
        ]
      ),
      _c("yd-backtop"),
      _vm.emptyList ? _c("empty") : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }