var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "promotion",
      on: {
        click: function ($event) {
          return _vm.toThreeWeb(_vm.config.url)
        },
      },
    },
    [
      _c("yqt-image", {
        staticClass: "img",
        attrs: {
          src: _vm.config.image,
          lazyload: "",
          "loading-img": _vm.loadingImg,
          fit: "cover",
          alt: "item pic",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }