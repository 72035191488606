<template>
  <div class="page">
    <yd-infinitescroll :callback="loadItemList" ref="infinitescrollDemo">
      <div slot="list">
        <!-- S group item list -->
        <div class="item-list-wrap">
          <row-item-list type="group" :itemList="itemList" @click-item="clickItem"></row-item-list>
        </div>
        <!-- E group item list -->
      </div>

      <!-- 数据全部加载完毕显示 -->
      <span slot="doneTip">- 没有更多了 -</span>
    </yd-infinitescroll>

    <yd-backtop></yd-backtop>

    <!-- S 列表为空 -->
    <empty v-if="emptyList"></empty>
    <!-- E 列表为空 -->
  </div>
</template>

<script>
import RowItemList from "@/components/list/RowItemList";
import Empty from '@/components/empty/Empty';

import {getGroupList} from '@/services';

export default {
  name: "GroupBuyIndex",
  components: {
    RowItemList,
    Empty
  },
  data() {
    return {
      page: 1, // 开始查询的下标
      pageSize: 10, // 每次查询的数量
      itemList: [], // 秒杀商品列表
      emptyList: false // 是否为空
    };
  },
  created() {
	sessionStorage.route = this.$route.name;
    this.loadItemList();
  },
  methods: {
    // 加载数据
    async loadItemList() {
      try {
        const res = await getGroupList(this.page, this.pageSize);
        if (res.code === 0) {
          const {data, total} = res.data;
          this.itemList = [...this.itemList, ...data];
          this.emptyList = !this.itemList.length;
          
          if (this.itemList.length === total) {
            // 所有数据加载完毕
          this.$refs.infinitescrollDemo.$emit("ydui.infinitescroll.loadedDone");
          return;
          }
          // 单次请求数据完毕
        this.$refs.infinitescrollDemo.$emit("ydui.infinitescroll.finishLoad");

        this.page++;
        } else {
          this.$dialog.toast({
            mes: res.msg,
            timeout: 1500,
            icon: "error"
          });
        }
        this.emptyList = !this.itemList.length;
      } catch (error) {
        console.warn("Something bad happened: ", error);
      }
    },
    // 点击商品
    clickItem(item) {
      this.$router.push({
        name: "goods-detail",
        query: {
          item_id: item.item_id
        }
      });
    }
  }
};
</script>

<style lang="less" scoped>
@import "../../../common/less/variable.less";
@import "../../../common/less/mixin.less";

.page {
  .bis(#fff);

  /deep/ .item-list-wrap {
    padding-left: 0.3rem;
    padding-right: 0.3rem;

    /deep/ .row-item:first-child {
      margin-top: 0.3rem;
    }
  }
}
</style>
