<template>
  <yd-layout class="page">
    <!-- S horizontal scroll tab -->
    <div class="horizontal-scroll-wrap" v-if="tabs.length" slot="navbar">
      <ul class="tab-list">
        <li
            class="flex-center"
            v-for="(item, index) in tabs"
            :key="index"
            :class="{active: activeTab === index}"
            @click="clickTab(index)"
        >
          <span class="time">{{item.start_time | formatHm}}</span>
          <span class="status" v-if="">{{statusMap.get(item.status)}}</span>
        </li>
      </ul>
    </div>
    <!-- E horizontal scroll tab -->

    <yd-infinitescroll :callback="loadItemList" ref="infinitescrollDemo" v-if="tabs.length">
      <div slot="list">
        <!-- S time clock -->
        <div class="time-clock" v-if="tabs[activeTab].status === 1 || tabs[activeTab].status === 2 || tabs[activeTab].status === 3">
          <span class="tip">{{tip}}</span>
          <span class="countdown-wrap">
            <span class="txt">距{{countdownTxt}}</span>
            <yd-countdown :time="countdown" timetype="timestamp" format="{%h}:{%m}:{%s}">
              <em class="time">{%h}</em><i>:</i>
              <em class="time">{%m}</em><i>:</i>
              <em class="time">{%s}</em>
            </yd-countdown>
          </span>
        </div>
        <!-- E time clock -->

        <!-- S snatch item list -->
        <div class="item-list-wrap">
          <row-item-list type="seconds" :activeTab="activeTab" :itemList="itemList" @click-item="clickItem"></row-item-list>
        </div>
        <!-- E snatch item list -->
      </div>

      <!-- 数据全部加载完毕显示 -->
      <span  v-if="!emptyList" slot="doneTip">- 没有更多了 -</span>
    </yd-infinitescroll>

    <yd-backtop></yd-backtop>

    <!-- S 列表为空 -->
    <empty v-if="emptyList"></empty>
    <!-- E 列表为空 -->
  </yd-layout>
</template>

<script>
import Test1 from "@/assets/img_home_fangjian01@3x.png";
import Test2 from "@/assets/img_home_tuangou02@3x.png";
import RowItemList from "@/components/list/RowItemList";
import Empty from "@/components/empty/Empty";

import {getLimitBuyTime, getLimitBuyItemList} from "@/services";

const now = {
  getTime: function () {
    return Math.floor(+new Date() / 1000);
  }
};

const statusMap = new Map([[1, "即将开抢"], [2, "已开抢"], [3, "已开抢"]]);

export default {
  name: "SecondsKill",
  components: {
    RowItemList,
    Empty
  },
  data() {
    return {
      statusMap: statusMap,
      activeTab: 0, // 当前激活的 tab 下标
      tabs: [], // 秒杀tab
      tip: "", // 秒杀提示
      countdownTxt: "", // 倒计时txt
      countdown: 0, // 倒计时
      page: 1, // 开始查询的下标
      pageSize: 10, // 每次查询的数量
      itemList: [], // 秒杀商品列表
      emptyList: false // 是否为空
    };
  },
  async created() {
    await this.getTabs();
	  sessionStorage.route = this.$route.name;
  },
  methods: {
    // 加载数据
    async loadItemList() {
      try {
        const res = await getLimitBuyItemList(
          this.page,
          this.pageSize,
          this.tabs[this.activeTab].id
        );
        if (res.code === 0) {
          const {data, total} = res.data;
          this.itemList = [...this.itemList, ...data];
          this.emptyList = !this.itemList.length;
          if (this.itemList.length === total) {
            // 所有数据加载完毕
            this.$refs.infinitescrollDemo.$emit(
              "ydui.infinitescroll.loadedDone"
            );
            return;
          }
          // 单次请求数据完毕
          this.$refs.infinitescrollDemo.$emit("ydui.infinitescroll.finishLoad");

          this.page++;
        } else {
          this.$dialog.toast({
            mes: res.msg,
            timeout: 1500,
            icon: "error"
          });
        }
        this.emptyList = !this.itemList.length;
      } catch (error) {
        console.warn("Something bad happened in getLimitBuyItemList: ", error);
      }
    },
    // 点击商品
    clickItem(item) {
      this.$router.push({
        name: "goods-detail",
        query: {
          item_id: item.item_id,
          item_active_type: 2
        }
      });
    },
    // 获取 tabs
    async getTabs() {
      try {
        const res = await getLimitBuyTime();
        if (res.code === 0) {
          const {data} = res.data;
          let now_time = now.getTime();
          if(data.length===0){
            this.emptyList = !data.length;
            return
          }
          for (let i = 0; i < data.length; i++) {
            data[i].status = "";
            let start_time = data[i].start_time,
              end_time = data[i].end_time;
            if (now_time < start_time) {
              // 未开抢
              data[i].status = 1;
            } else if (now_time >= start_time) {
              if (data[i + 1] && data[i + 1].start_time) {
                if (now_time < data[i + 1].start_time) {
                  data[i].status = 2;
                } else {
                  data[i].status = 3;
                }
              } else {
                if (now_time < end_time) {
                  data[i].status = 2;
                } else {
                  data[i].status = 3;
                }
              }
            } else if (now_time >= end_time) {
              data[i].status = 3;
            }
          }
          this.tabs = data;
          if (this.tabs && this.tabs.length > 0) {
            this.loadItemList();
          }
          const activeTabItem = this.tabs[this.activeTab];
          if (activeTabItem.status === 1) {
            this.tip = "即将开始 先下单先得哦";
            this.countdown = activeTabItem.start_time;
            this.countdownTxt = "开始";
          } else if (activeTabItem.status === 2 || activeTabItem.status === 3) {
            this.tip = "抢购中 先下单先得哦";
            this.countdown = activeTabItem.end_time;
            this.countdownTxt = "结束";
          }
          console.log({data});
          console.log('tabs', this.tabs)
        } else {
          this.$dialog.toast({
            mes: res.msg,
            timeout: 1500,
            icon: "error"
          });
        }
      } catch (error) {
        console.warn("Something bad happened in getLimitBuyTime: ", error);
      }
    },
    // 切换 tab
    clickTab(index) {
      this.activeTab = index;
      this.page = 1;
      this.itemList = [];
      this.$refs.infinitescrollDemo.$emit("ydui.infinitescroll.reInit");
      const activeTabItem = this.tabs[this.activeTab];
      if (activeTabItem.status === 1) {
        this.tip = "即将开始 先下单先得哦";
        this.countdown = activeTabItem.start_time;
        this.countdownTxt = "开始";
      } else if (activeTabItem.status === 2 || activeTabItem.status === 3) {
        this.tip = "抢购中 先下单先得哦";
        this.countdown = activeTabItem.end_time;
        this.countdownTxt = "结束";
      }
      this.loadItemList();
    }
  }
};
</script>

<style lang="less" scoped>
@import "../../common/less/variable.less";
@import "../../common/less/mixin.less";

.page {
  background-color: #fff;

  .horizontal-scroll-wrap {
    position: relative;
    .wh(100%, 1.08rem);
    background: linear-gradient(
        227deg,
        rgba(255, 68, 68, 1) 0%,
        rgba(252, 62, 108, 1) 100%
    );
    // filter: blur(10px);
    overflow: hidden;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;

    .tab-list {
      display: flex;
      justify-content: flex-start;
      height: 100%;

      li {
        display: flex;
        flex-direction: column;
        align-items: center;
        .wh(1.5rem, 100%);
        text-align: center;
        white-space: nowrap;
        padding: 0 0.4rem;

        .time {
          .sc(@fontsize-large-x, #fff);
          line-height: 0.5rem;
        }

        .status {
          .sc(0.22rem, #fff);
          line-height: 0.32rem;
        }

        &.active {
          .time {
            .sc(0.38rem, #fff);
            line-height: 0.52rem;
            font-weight: bolder;
          }

          .status {
            font-weight: bold;
          }
        }
      }
    }
  }

  /deep/ .time-clock {
    position: relative;
    .fj;
    align-items: center;
    .wh(100%, 0.6rem);
    padding-left: 0.24rem;
    padding-right: 0.24rem;
    background-color: #ffeceb;

    .tip {
      .sc(0.26rem, #fd4060);
      line-height: 0.36rem;
      word-wrap: break-word;
      word-break: break-all;
    }

    .countdown-wrap {
      .ct;
      right: 0.24rem;
      display: flex;
      align-items: center;
      z-index: 2;

      .txt {
        margin-right: 0.12rem;
        .sc(@fontsize-small, #fd4060);
        line-height: 0.34rem;
      }

      .time {
        display: inline-block;
        .wh(0.32rem, 0.32rem);
        margin-top: 0.1rem;
        .sc(0.22rem, #fff);
        line-height: 0.32rem;
        text-align: center;
        background: linear-gradient(
            227deg,
            rgba(255, 68, 68, 1) 0%,
            rgba(252, 62, 108, 1) 100%
        );
        border-radius: 2px;
        // filter: blur(10px);
        overflow: hidden;
      }

      .colon {
        display: inline-block;
        .wh(0.12rem, 0.32rem);
        .sc(0.22rem, #ff7f00);
        transform: translateY(-25%);
        text-align: center;
        line-height: 0.32rem;
      }
    }
  }

  /deep/ .item-list-wrap {
    /deep/ .row-item:first-child {
      margin-top: 0.3rem;
    }
  }
}
</style>
