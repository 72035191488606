<template>
  <div id="new-category">
    <!-- 一行四个 -->
    <ul
      class="category-list four"
      v-if="fourStatus"
      :class="borderStatus === 1 ? 'border-four' : ''"
    >
      <li
        class="category-item"
        v-for="(item, index) in categoryList"
        :key="index"
        @click="clickCategory(item)"
      >
        <div class="img-box flex-center">
          <img
            class="category-icon"
            :src="item.img | ossProcess('style/w_60_h_auto')"
            alt="icon"
          />
        </div>
        <div class="txt-box flex-center">
          <span class="category-txt">{{ item.title }}</span>
        </div>
      </li>
    </ul>
    <!-- 一行五个 -->
    <ul
      class="category-list five"
      v-if="fiveStatus"
      :class="borderStatus === 1 ? 'border-five' : ''"
    >
      <li
        class="category-item"
        v-for="(item, index) in categoryList"
        :key="index"
        @click="clickCategory(item)"
      >
        <div class="flex-center icon-style">
          <img :src="item.img | ossProcess('style/w_120_h_auto')" alt="icon" />
        </div>
        <div class="txt-box flex-center">
          <span class="category-txt">{{ item.title }}</span>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import { getSubject, getPointInfo, getCashInfo } from "@/services";
import { tripartite } from "../../utils/custom";
import { mapState } from "vuex";

export default {
  name: "NewCategory",
  props: ["show", "config"],
  data() {
    return {
      listWidth: {}, // 列表宽度
      iconStyle: {}, // icon边距
      fourStatus: false, // 一行4列
      fiveStatus: false, // 一行5列
      borderStatus: 0, // 白底显示情况；0不显示、1显示
      categoryList: [], // 分类列表
      businessType: [
        1495, 1512, 306, 486, 1489, 1519, 1545, 1546, 1791, 1792, 2148, 2149,
      ], //指定商城跳转
    };
  },

  created() {
    this.loadData();
  },

  computed: {
    ...mapState({
      businessId: (state) => state.businessInfo.id || "",
    }),
  },

  methods: {
    // icon导航宽度设置
    iconWidth(length) {
      if (length > 8) {
        this.iconStyle = {
          padding: "0.3rem",
        };
      } else {
        this.iconStyle = {
          padding: "0.4rem",
        };
      }
      if (length > 5) {
        length = length / 2;
      }
      this.listWidth = {
        width: 100 / length + "%",
      };
    },

    // 获取数据
    async loadData() {
      try {
        const res = await getSubject(this.$store.state.userInfo.business_id, 1);
        if (res.code === 0) {
          this.categoryList = res.data;
          if (this.config) {
            this.borderStatus = this.config.borderType;
          }
          // this.categoryList.splice(8, 2);
          if (this.categoryList.length > 8) {
            this.fiveStatus = true;
          } else {
            this.fourStatus = true;
          }
          if (this.businessType.includes(this.businessId)) {
            this.categoryList.forEach((item, index) => {
              console.log(item, "item");
              if (index === 7) {
                this.$set(this.categoryList[index], "type", 5);
              }
            });
          }
          if (tripartite("VUE_APP_TABENLJJ")) {
            this.categoryList.forEach((item, index) => {
              if (index === 0) {
                this.$set(this.categoryList[index], "type", 4);
                this.$set(this.categoryList[index], "link", "#/tuobenShopList");
              }
            });
          }
        } else {
          this.$dialog.toast({
            mes: res.msg,
            icon: "error",
          });
        }
      } catch (error) {
        console.warn(error);
      }
    },
    // 点击分类
    clickCategory(item) {
      // 类型 1无跳转 2商品专题 3商品 4自定义网址
      switch (item.type) {
        case 1:
          break;
        case 2:
          this.$router.push({
            name: "special_topic",
            query: {
              subject_id: item.subject_id,
            },
          });
          break;
        case 3:
          this.$router.push({
            name: "goods-detail",
            query: {
              item_id: item.item_id,
            },
          });
          break;
        case 4:
          window.open(item.link);
          break;
        case 5:
          // eslint-disable-next-line no-case-declarations
          const shopType = localStorage.getItem("businessInfo")
            ? JSON.parse(localStorage.getItem("businessInfo"))
            : {};
          shopType.type === 1 ? this.toPointShop() : this.toCashShop();
          break;
        default:
          break;
      }
    },

    // 商城跳转
    async toCashShop() {
      try {
        const res = await getCashInfo();
        if (res.code === 0) {
          sessionStorage.setItem("businessId", res.data.business_id);
          sessionStorage.setItem("userId", res.data.user_id);
          window.location.href = `?bid=${res.data.bid}#/`;
        } else {
          throw new Error("未登录");
        }
        // window.location.href = 'https://wx.yqtb2b.com/?bid=f7f84c7e204f03f4f76a7d53894a9eb6#/'
      } catch (e) {
        this.$dialog.toast({
          mes: "请登录",
          icon: "error",
        });
        this.$router.push("/login");
      }
    },
    async toPointShop() {
      try {
        const res = await getPointInfo();
        if (res.code === 0) {
          sessionStorage.setItem("businessId", res.data.business_id);
          sessionStorage.setItem("userId", res.data.user_id);
          window.location.href = `?bid=${res.data.bid}#/`;
        } else {
          throw new Error("未登录");
        }
      } catch (e) {
        this.$dialog.toast({
          mes: "请登录",
          icon: "error",
        });
        this.$router.push("/login");
      }
    },
  },

  watch: {
    show(val) {
      if (val) {
        this.loadData();
      }
    },
  },
};
</script>

<style lang="less" scoped>
@import "../../common/less/variable.less";
@import "../../common/less/mixin.less";

#new-category {
  padding-bottom: 0.2rem;

  .four {
    margin: 0 0.3rem;

    .category-item {
      width: 25%;
      margin-bottom: 0.3rem;

      .img-box {
        height: 1rem;
        margin-bottom: 0.2rem;
      }

      .txt-box {
        height: 0.36rem;
      }
    }

    .category-icon {
      .wh(1rem, 1rem);
    }

    .category-txt {
      .sc(0.26rem, @color-grey);
      line-height: 0.36rem;
    }
  }

  .five {
    margin: 0 0.4rem;

    .category-item {
      width: 20%;
      margin-bottom: 0.11rem;

      .icon-style {
        padding: 0.25rem;
      }

      .txt-box {
        color: #333333;
        font-size: 0.2rem;
      }
    }
  }

  .category-list {
    position: relative;
    overflow: hidden;

    .category-item {
      float: left;
      position: relative;
      z-index: 0;
    }
  }

  .border-four {
    border-radius: 0.2rem;
    background-color: #ffffff;
    padding-top: 0.3rem;
    box-shadow: 0 0 5px #e0e0e0;
    -webkit-box-shadow: 0 0 5px #e0e0e0;
  }

  .border-five {
    border-radius: 0.2rem;
    background-color: #ffffff;
    padding: 0.1rem 0 0.3rem 0;
    box-shadow: 0 0 5px #e0e0e0;
    -webkit-box-shadow: 0 0 5px #e0e0e0;
  }
}
</style>
