var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "group-play" }, [
    _vm._m(0),
    _c(
      "div",
      { staticClass: "group-rule" },
      _vm._l(_vm.groupRules, function (item, index) {
        return _c("div", { key: index, staticClass: "rule-item" }, [
          _c("div", [_vm._v(_vm._s(index + 1))]),
          _c("p", [_vm._v(_vm._s(item))]),
        ])
      }),
      0
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "group-img" }, [
      _c("img", {
        attrs: { src: require("@/assets/img_pingtuanwanfa@2x.png"), alt: "" },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }