var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { id: "announcement" } }, [
    _c(
      "div",
      { staticClass: "announcement-cell" },
      [
        _c("img", {
          staticClass: "icon icon-announcement",
          attrs: { src: _vm.iconAnnouncement, alt: "icon" },
          on: { click: _vm.clickIcon },
        }),
        _c(
          "yd-rollnotice",
          { attrs: { autoplay: "2000" } },
          [
            _c("yd-rollnotice-item"),
            _vm._l(_vm.announcementList, function (item, index) {
              return _c(
                "yd-rollnotice-item",
                {
                  key: index,
                  staticClass: "dom",
                  nativeOn: {
                    click: function ($event) {
                      return _vm.clickAnnouncement(item)
                    },
                  },
                },
                [
                  _c("span", { staticClass: "announcement-content" }, [
                    _vm._v(_vm._s(item.title)),
                  ]),
                ]
              )
            }),
          ],
          2
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }