var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "wrap" }, [
    _c("div", { attrs: { id: "box" } }, [
      _c("div", { staticClass: "marquee" }, [_vm._v(_vm._s(_vm.text))]),
      _c("div", {
        staticStyle: { display: "none", "margin-top": "-5px" },
        attrs: { id: "copy" },
      }),
    ]),
    _c("div", { attrs: { id: "node" } }, [_vm._v(_vm._s(_vm.text))]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }