var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "page" },
    [
      _c(
        "yd-infinitescroll",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.list.length,
              expression: "list.length",
            },
          ],
          ref: "infinitescrollDemo",
          attrs: { callback: _vm.loadList },
        },
        [
          _c(
            "ul",
            {
              staticClass: "announcement-list",
              attrs: { slot: "list" },
              slot: "list",
            },
            _vm._l(_vm.list, function (item, index) {
              return _c(
                "li",
                {
                  key: index,
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      return _vm.clickAnnouncemnet(item)
                    },
                  },
                },
                [
                  _c("div", { staticClass: "title" }, [
                    _c("div", { staticClass: "left" }, [
                      _c("span", { staticClass: "txt" }, [_vm._v("商城公告")]),
                      !item.is_read
                        ? _c("span", { staticClass: "red-dot" })
                        : _vm._e(),
                    ]),
                    _c("span", { staticClass: "time" }, [
                      _vm._v(
                        _vm._s(_vm._f("formatDateTimeHm")(item.create_time))
                      ),
                    ]),
                  ]),
                  _c("p", { staticClass: "content" }, [
                    _vm._v(_vm._s(item.title)),
                  ]),
                  _c("i", { staticClass: "icon icon-detail" }),
                ]
              )
            }),
            0
          ),
          _c("span", { attrs: { slot: "doneTip" }, slot: "doneTip" }, [
            _vm._v("- 没有更多了 -"),
          ]),
        ]
      ),
      _c("yd-backtop"),
      _vm.emptyList
        ? _c("empty", {
            attrs: {
              tip: "暂时没有任何商城公告哦～",
              icon: "~@/assets/ic_huifu_kong@3x.png",
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }