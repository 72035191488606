var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "page" },
    [
      _c(
        "yd-infinitescroll",
        { ref: "infinitescrollDemo", attrs: { callback: _vm.loadItemList } },
        [
          _c("div", { attrs: { slot: "list" }, slot: "list" }, [
            _c(
              "div",
              { staticClass: "item-list-wrap" },
              [
                _c("row-item-list", {
                  attrs: { type: "group", itemList: _vm.itemList },
                  on: { "click-item": _vm.clickItem },
                }),
              ],
              1
            ),
          ]),
          _c("span", { attrs: { slot: "doneTip" }, slot: "doneTip" }, [
            _vm._v("- 没有更多了 -"),
          ]),
        ]
      ),
      _c("yd-backtop"),
      _vm.emptyList ? _c("empty") : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }