<template>
  <div class="page">
    <div class="group-item-wrap">
      <!-- S 参团商品信息 -->
      <div class="item-info">
        <div class="img-box">
          <img :src="item_info.base_pic" alt="item img">
        </div>
        <div class="img-right">
          <h3 class="item-name">{{item_info.item_name}}</h3>
          <h4 class="item-intro">{{item_info.item_desc}}</h4>
          <ul class="feature-list">
            <li v-for="(item, index) in sku_info" :key="index">{{item.pro_info.value}}</li>
          </ul>
          <p class="item-price" v-if="businessType == 2 && showWay == 1">
            <span class="txt" v-if="price_status==1">拼团价：</span>
            <span class="price" v-if="mall_price_status==1">
              <span class="num">{{(group_buy_open_info.open_price * integralRate).toFixed(2)}}</span>
              <span class="sign">积分</span>
            </span>
            <span class="old-price" v-if="mall_price_status==1">{{(item_info.mall_price * integralRate).toFixed(2)}}积分</span>
          </p>
          <p class="item-price" v-else>
            <span class="txt" v-if="price_status==1">拼团价：</span>
            <span class="price" v-if="price_status==1">
              <span class="sign">&yen;</span>
              <span class="num">{{group_buy_open_info.open_price}}</span>
            </span>
            <span class="old-price" v-if="mall_price_status==1">&yen;{{item_info.mall_price}}</span>
          </p>
          <span class="item-num">x{{group_buy_open_info.item_num}}</span>
        </div>
      </div>
      <!-- E 参团商品信息 -->

      <!-- S 拼团玩法 -->
      <group-play></group-play>
      <!-- E 拼团玩法 -->
    </div>

    <!-- S 拼团信息 -->
    <div class="group-info">
      <h3 class="title" v-if="hasPay">
        <span class="gray-txt">还差</span>
        <span class="num">{{group_buy_open_info.surplus_num}}</span>
        <span class="gray-txt">人即可拼团成功</span>
      </h3>
      <h3 class="title" v-else>支付成功即可开始组团</h3>
      <div class="group-list-wrap">
        <ul class="group-list">
          <li v-for="(item, index) in groupList" :key="index" :class="{active: !!item.user_id}">
            <img :src="item.user_info.heagurl" alt="headimg" @error="errorHeadImg">
            <span class="head-badge" v-if="item.user_id === group_open_user_id">团长</span>
          </li>
        </ul>
        <p class="countdown-wrap" v-if="hasPay">
          <template v-if="!hasOver">
            <span class="gray-txt">剩余</span>
            <yd-countdown :time="group_buy_open_info.end_time" :callback="hasOver = false" timetype="timestamp" format="{%h}:{%m}:{%s}">
              <span class="time">{%h}:{%m}:{%s}</span>
            </yd-countdown>
            <span class="gray-txt">失效</span>
          </template>
          <template v-else>
            <span class="gray-txt">已结束</span>
          </template>
        </p>
      </div>
    </div>
    <!-- E 拼团信息 -->

    <!-- S 拼团按钮 -->
    <div class="btn buy-btn flex-center" @click="handleGroup">立即拼团</div>
    <!-- E 拼团按钮 -->
  </div>
</template>

<script>
import DefaultHeadImg from "@/assets/ic_head_default@3x.png";
import Test1 from "@/assets/img_home_fangjian01@3x.png";
import TestHead from "@/assets/img_head_40@3x.png";
import groupPlay from "@/components/goods/groupPlay";
import {baseUrl} from "@/utils/env";
import {getGroupUserList} from '@/services';
import {orderCollectDetail} from '@/services/orderApi';
import {mapState} from "vuex";
export default {
  name: "GroupBuying",
  components: {
    groupPlay
  },
  data() {
    return {
      group_open_user_id: '', // 开团人user_id
      item_info: {}, // 拼团商品信息
      sku_info: [], // 商品属性信息
      group_buy_open_info: {}, // 商品开团信息
      groupList: [], // 拼团成员列表
      countdown: 0, // 剩余时间
      hasPay: true, // 是否已经支付
      hasOver: false // 倒计时是否已结束
    };
  },
  computed: {
    ...mapState({
      businessType: state => state.businessInfo.type,//1现金商城，2积分商品，3内采商城
      showWay: state => state.config.show_way, // 商品展示方式，1积分，2现金
      price_status: state => state.config.price_status, // 是否展示价格 1展示 0 不展示
      mall_price_status: state => state.config.mall_price_status, //  是否展示价格 1展示 0 不展示
      integralRate: state => state.config.integral_rate // 积分比例
    }),
    groupNum() {
      let num = 0;
      for (let i = 0; i < this.groupList.length; i++) {
        if (!!this.groupList[i].user_id) {
          num++;
        }
      }
      return num;
    }
  },
  created(){
    this.loadData();
    this.pay_order_id = this.$route.query.pay_order_id;
    if(this.pay_order_id){
      this.getDetail()
    }
  },
  methods: {
    // 获取数据
    async loadData() {
      try {
        const res = await getGroupUserList(this.$route.query.group_buy_open_id);
        if (res.code === 0) {
          const {user_id, is_open, group_buy_open_info, item_info, sku_info, group_buy_user_list} = res.data;
          let now = Math.floor(+new Date() / 1000);
          this.group_open_user_id = user_id;
          this.hasPay = is_open === 1;
          this.hasOver = group_buy_open_info.end_time > now ? false : true;
          this.group_buy_open_info = group_buy_open_info;
          this.item_info = item_info;
          this.sku_info = sku_info;
          let tem = [];
          for(let i = 0; i < group_buy_open_info.people_limit; i++) {
            tem[i] = {
              user_id: '',
              user_info: {
                heagurl: ''
              }
            };
          }
          for(let j = 0; j < group_buy_user_list.length; j++) {
            tem[j] = group_buy_user_list[j];
          }
          this.groupList = tem;
        } else {
          this.$dialog.toast({
            mes: res.msg,
            timeout: 1500,
            icon: "error"
          });
        }
      } catch (error) {
        console.warn("Something bad happened: ", error);
      }
    },
    /**
     * 订单信息
     * @returns {Promise<void>}
     */
    async getDetail() {
      try {
        const res = await orderCollectDetail(this.pay_order_id);
        if (res.code === 0) {
          const data = res.data;
          this.goods_info = data.item_list[0];
        }
      } catch (err) {
        throw err;
      }
    },
    // 点击立即拼团
    handleGroup() {
      let data = {
        item_id:this.group_buy_open_info.item_id,
        item_property_id:this.group_buy_open_info.item_property_id,
        is_group: 2,
        number: this.group_buy_open_info.item_num,
        goodsType: 3,  // 商品类型判断  0： 普通商品，  1： 限时购商品在售状态   2： 限时购商品预售状态   3： 团购商品
        group_buy_open_id: this.$route.query.group_buy_open_id
      }
      this.$store.commit("setSettleData", data);
      window.location.href=`${baseUrl}/#/submit_order?add_type=1`
      // this.$dialog.toast({
      //   mes: "You clicked btn",
      //   timeout: 1500
      // });
    },
    // 默认显示图片
    errorHeadImg(e) {
      e.target.src = DefaultHeadImg;
    }
  }
};
</script>

<style lang="less" scoped>
@import "../../../common/less/variable.less";
@import "../../../common/less/mixin.less";

.page {
  .bj(#f7f7f7);

  .group-item-wrap {
    padding: 0.48rem 0.3rem 0.09rem;
    background-color: #fff;

    /deep/ .group-play {
      border-top: 0;
    }
  }

  .item-info {
    position: relative;
    .wh(100%, 2.2rem);
    padding-top: 0.24rem;
    padding-left: 2.4rem;
    margin-bottom: 0.31rem;
    background-color: #fff;
    box-shadow: 0px 0.06rem 0.4rem 0px rgba(0, 0, 0, 0.05);
    border-radius: 0.08rem;
    overflow: hidden;
    z-index: 1;

    .img-box {
      .ct;
      left: 0;
      .wh(2.2rem, 2.2rem);
      background-color: #faf8fb;
      z-index: 2;

      img {
        .wh(100%, 100%);
      }
    }

    .item-name {
      max-width: 100%;
      .sc(@fontsize-medium, rgba(0, 0, 0, 0.87));
      line-height: 0.4rem;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .item-intro {
      .ellipsis(2);
      .sc(@fontsize-small, @color-light-grey);
      line-height: 0.34rem;
    }

    .feature-list {
      position: relative;
      margin-bottom: 0.14rem;
      overflow: hidden;

      li {
        float: left;
        position: relative;
        padding: 0.02rem 0.12rem;
        margin-top: 0.12rem;
        .sc(0.22rem, var(--main-color));
        line-height: 0.32rem;
        text-align: center;
        white-space: nowrap;
        border: 1px solid var(--main-color);
        border-radius: 0.18rem;
        overflow: hidden;
        z-index: 0;

        & + li {
          margin-left: 0.12rem;
        }
      }
    }

    .item-price {
      position: absolute;
      left: 2.4rem;
      bottom: 0.2rem;

      .txt {
        .sc(@fontsize-medium, var(--main-color));
        line-height: 0.4rem;
      }

      .price {
        color: @color-dark-grey;
        line-height: 0.28rem;
        font-weight: bold;

        .sign {
          font-size: @fontsize-small;
        }

        .num {
          font-size: @fontsize-large;
        }
      }

      .old-price {
        display: inline-block;
        margin-left: 0.08rem;
        .sc(0.16rem, rgba(0, 0, 0, 0.38));
        line-height: 0.2rem;
        font-weight: bold;
        text-decoration: line-through;
      }
    }

    .item-num {
      position: absolute;
      right: 0.2rem;
      bottom: 0.2rem;
      .sc(@fontsize-small, @color-dark-grey);
      line-height: 0.28rem;
      font-weight: bolder;
    }
  }

  .group-info {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0.34rem 0.3rem 3.52rem;

    .title {
      .sc(@fontsize-medium, @color-grey);
      line-height: 0.4rem;
      text-align: center;

      .num {
        margin: 0 0.08rem;
        color: var(--main-color);
        font-weight: bolder;
      }
    }

    .group-list-wrap {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      min-height:2.4rem;
      padding: 0 0.24rem 0.24rem 0.66rem;
      .bis("../../../assets/img_tuangou_bg_shixiao");

      .group-list {
        position: relative;
        padding-top: 0.72rem;
        margin-bottom: 0.14rem;

        li {
          float: left;
          position: relative;
          .wh(0.8rem, 0.8rem);
          border-radius: 50%;
          z-index: 0;

          img {
            .wh(100%, 100%);
            border-radius: 50%;
            overflow: hidden;
          }

          .head-badge {
            .cl;
            bottom: -0.1rem;
            display: inline-block;
            .wh(0.56rem, 0.26rem);
            padding-top: 0.02rem;
            padding-left: 0.02rem;
            .sc(0.2rem, #fff);
            line-height: 0.26rem;
            text-align: center;
            background-color: var(--main-color);
            border-radius: 0.13rem;
            overflow: hidden;
            z-index: 3;
          }

          &.active {
            border: 1px solid var(--main-color);
            margin-right: 0.4rem;
            margin-bottom: 0.32rem;
          }

          & + li {
            margin-right: 0.4rem;
            margin-bottom: 0.32rem;
          }
        }
      }

      .countdown-wrap {
        display: flex;
        align-items: center;

        .gray-txt {
          .sc(0.22rem, @color-light-grey);
          line-height: 0.32rem;
        }

        .time {
          margin: 0 0.08rem;
          .sc(@fontsize-medium, var(--main-color));
          line-height: 0.4rem;
          font-weight: bold;
        }
      }
    }
  }

  .buy-btn {
    .wh(6rem, 0.88rem);
    margin: 0.4rem auto;
    .sc(@fontsize-large, #fff);
    line-height: 0.88rem;
    text-align: center;
    font-weight: bold;
    white-space: nowrap;
    background-color: var(--main-color);
    border-radius: 0.44rem;
    overflow: hidden;
    .touch-active(var(--main-color));
  }
}
</style>
