var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { id: "new-category" } }, [
    _vm.fourStatus
      ? _c(
          "ul",
          {
            staticClass: "category-list four",
            class: _vm.borderStatus === 1 ? "border-four" : "",
          },
          _vm._l(_vm.categoryList, function (item, index) {
            return _c(
              "li",
              {
                key: index,
                staticClass: "category-item",
                on: {
                  click: function ($event) {
                    return _vm.clickCategory(item)
                  },
                },
              },
              [
                _c("div", { staticClass: "img-box flex-center" }, [
                  _c("img", {
                    staticClass: "category-icon",
                    attrs: {
                      src: _vm._f("ossProcess")(item.img, "style/w_60_h_auto"),
                      alt: "icon",
                    },
                  }),
                ]),
                _c("div", { staticClass: "txt-box flex-center" }, [
                  _c("span", { staticClass: "category-txt" }, [
                    _vm._v(_vm._s(item.title)),
                  ]),
                ]),
              ]
            )
          }),
          0
        )
      : _vm._e(),
    _vm.fiveStatus
      ? _c(
          "ul",
          {
            staticClass: "category-list five",
            class: _vm.borderStatus === 1 ? "border-five" : "",
          },
          _vm._l(_vm.categoryList, function (item, index) {
            return _c(
              "li",
              {
                key: index,
                staticClass: "category-item",
                on: {
                  click: function ($event) {
                    return _vm.clickCategory(item)
                  },
                },
              },
              [
                _c("div", { staticClass: "flex-center icon-style" }, [
                  _c("img", {
                    attrs: {
                      src: _vm._f("ossProcess")(item.img, "style/w_120_h_auto"),
                      alt: "icon",
                    },
                  }),
                ]),
                _c("div", { staticClass: "txt-box flex-center" }, [
                  _c("span", { staticClass: "category-txt" }, [
                    _vm._v(_vm._s(item.title)),
                  ]),
                ]),
              ]
            )
          }),
          0
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }