<template>
  <div class="recommend-list">
    <div class="title flex-center">
      <div class="img-title"></div>
    </div>
    <item-list :itemList="itemList" @click-item="clickItem"></item-list>
  </div>
</template>

<script>
import ItemList from "@/components/list/ItemList";

export default {
  name: "RecommendList",
  components: {
    ItemList
  },
  props: {
    itemList: Array
  },
  methods: {
    // 点击商品
    clickItem(item) {
      this.$emit("click-item", item);
    }
  }
}
</script>

<style lang="less" scoped>
@import "../../common/less/variable.less";
@import "../../common/less/mixin.less";

.recommend-list {
  padding: 0 .30rem .40rem;

  .title {
    .img-title {
      .wh(3.22rem, .58rem);
      .bis('../../assets/img_home_cainixihuan');
    }
  }
}
</style>
