var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "nav-container" },
    [
      _c(
        "router-link",
        { staticClass: "nav-item flex-center", attrs: { to: "home" } },
        [
          _vm.$route.name === "home"
            ? _c("icon-svg", {
                staticClass: "main-color",
                attrs: { "icon-class": "yi_ic_home_blue_" },
              })
            : _c("icon-svg", {
                staticClass: "grey",
                attrs: { "icon-class": "yi_ic_home_grey_" },
              }),
          _c("span", { staticClass: "nav-txt" }, [_vm._v("首页")]),
        ],
        1
      ),
      _c(
        "router-link",
        { staticClass: "nav-item flex-center", attrs: { to: "category" } },
        [
          _vm.$route.name === "category"
            ? _c("icon-svg", {
                staticClass: "main-color",
                attrs: { "icon-class": "yi_ic_type_blue_" },
              })
            : _c("icon-svg", {
                staticClass: "grey",
                attrs: { "icon-class": "yi_ic_type_grey_" },
              }),
          _c("span", { staticClass: "nav-txt" }, [_vm._v("分类")]),
        ],
        1
      ),
      _vm.cart_show && _vm.businessInfo.mall_display_only !== 1
        ? _c(
            "router-link",
            {
              staticClass: "nav-item flex-center",
              attrs: { to: "shopping_cart" },
            },
            [
              _vm.$route.name === "shopping_cart"
                ? _c("icon-svg", {
                    staticClass: "main-color",
                    attrs: { "icon-class": "yi_ic_shopping_blue_" },
                  })
                : _c("icon-svg", {
                    staticClass: "grey",
                    attrs: { "icon-class": "yi_ic_shopping_grey_" },
                  }),
              _c("span", { staticClass: "nav-txt" }, [_vm._v("购物车")]),
            ],
            1
          )
        : _vm._e(),
      _vm.businessInfo.mall_display_only !== 1
        ? _c(
            "router-link",
            { staticClass: "nav-item flex-center", attrs: { to: "my" } },
            [
              _vm.$route.name === "my"
                ? _c("icon-svg", {
                    staticClass: "main-color",
                    attrs: { "icon-class": "yi_ic_my_blue_" },
                  })
                : _c("icon-svg", {
                    staticClass: "grey",
                    attrs: { "icon-class": "yi_ic_my_grey_" },
                  }),
              _c("span", { staticClass: "nav-txt" }, [_vm._v("我的")]),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }