import { render, staticRenderFns } from "./yqt-footer.vue?vue&type=template&id=13c725ae&scoped=true&"
import script from "./yqt-footer.vue?vue&type=script&lang=js&"
export * from "./yqt-footer.vue?vue&type=script&lang=js&"
import style0 from "./yqt-footer.vue?vue&type=style&index=0&id=13c725ae&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "13c725ae",
  null
  
)

export default component.exports