<template>
  <div class="group-play">
    <div class="group-img">
      <img src="~@/assets/img_pingtuanwanfa@2x.png" alt>
    </div>
    <div class="group-rule">
      <div class="rule-item" v-for="(item, index) in groupRules" :key="index">
        <div>{{index + 1}}</div>
        <p>{{item}}</p>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "groupPlay",
  data() {
    return {
      groupRules: ["选择商品开团/参团", "邀请好友参团", "人满成团"]
    };
  }
};
</script>
<style scoped lang="less">
@import "~@/common/less/variable.less";

.group-play {
  height: 0.94rem;
  border-top: 1px solid #e1e1e1;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .group-img {
    width: 1.12rem;
    height: 0.34rem;

    img {
      width: 1.12rem;
      height: 0.34rem;
    }
  }

  .group-rule {
    height: 0.94rem;
    display: flex;
    align-items: center;

    .rule-item {
      display: flex;
      align-items: center;
      margin-right: 5px;

      div {
        width: 0.28rem;
        height: 0.28rem;
        border-radius: 0.28rem;
        background: var(--main-color);
        font-size: 0.22rem;
        color: #fff;
        margin-right: 2px;
        text-align: center;
        line-height: 0.28rem;
      }

      p {
        font-size: 0.22rem;
        color: #666666;
      }
    }

    .rule-item:nth-last-child(1) {
      margin-right: 0;
    }
  }
}
</style>