<template>
  <div class="page">
    <HeadNav/>
    <yd-infinitescroll v-show="list.length" :callback="loadList" ref="infinitescrollDemo">
      <coupon-list slot="list"  :is_start="false" :list="list" @operate="operate"></coupon-list>
      <!-- 数据全部加载完毕显示 -->
      <span slot="doneTip">- 没有更多了 -</span>
    </yd-infinitescroll>

    <yd-backtop></yd-backtop>

    <!-- S 列表为空 -->
    <empty v-if="emptyList" tip="本次活动已经结束，敬请期待下次~"></empty>
    <!-- E 列表为空 -->

    <!-- S 弹窗提示 -->
    <alert-tip :type="type" :showAlert="show_tip" :alertText="tip" @changeShowAlert="changeShow"></alert-tip>
    <!-- E 弹窗提示 -->
  </div>
</template>

<script>
import CouponList from "@/components/list/CouponList";
import HeadNav from "@/components/common/HeadNav";
import AlertTip from "@/components/common/AlertTip";
import Empty from '@/components/empty/Empty';
import {toolTip} from "@/mixins";

import {getVouchersList, userVouchers} from '@/services';

export default {
  name: "CouponCenter",
  components: {
    CouponList,
    AlertTip,
    Empty,
    HeadNav
  },
  mixins: [toolTip],
  data() {
    return {
      page: 1, // 开始查询的下标
      pageSize: 10, // 每次查询的数量
      list: [], // 优惠券列表
      emptyList: false // 是否为空
    };
  },
  created() {
	sessionStorage.route = this.$route.name;
    this.loadList();
  },
  methods: {
    // 加载数据
    async loadList() {
      try {
        const res = await getVouchersList(this.page, this.pageSize);
        if (res.code === 0) {
          const {total, data} = res.data;
          this.list = [...this.list, ...data];
          this.emptyList = !this.list.length;
          if (this.list.length === total) {
            // 所有数据加载完毕
          this.$refs.infinitescrollDemo.$emit("ydui.infinitescroll.loadedDone");
          return;
          }

          // 单次请求数据完毕
        this.$refs.infinitescrollDemo.$emit("ydui.infinitescroll.finishLoad");

        this.page++;
        } else {
          this.$dialog.toast({
            mes: res.msg,
            timeout: 1500,
            icon: "error"
          });
        }
        this.emptyList = !this.list.length;
      } catch (error) {
        console.warn("Something bad happened: ", error);
      }
    },
    // 点击按钮
    async operate(item) {
      if (item.status == 1) {
        try {
          const res = await userVouchers(item.id);
          if (res.code === 0) {
            item.status = 2;
            this.show_layer("success", "优惠券领取成功");
          } else {
            this.show_layer("cancel", res.msg);
          }
        } catch (error) {
          console.warn("Something bad happened: ", error);
        }
      } else if (item.status == 2) {
        // if (item.use_type === 1) {
          this.$router.push('home');
        // }
      } else {
        return;
      }
    }
  }
};
</script>

<style lang="less" scoped>
@import "../../common/less/variable.less";
@import "../../common/less/mixin.less";

.page {
  .bj(#fff);
}
</style>
