var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "ul",
    { staticClass: "coupon-list" },
    _vm._l(_vm.list, function (item, index) {
      return _c(
        "li",
        {
          key: index,
          staticClass: "coupon-item",
          class: [
            {
              old:
                item.status === 4 ||
                item.status === 5 ||
                item.status === 3 ||
                item.status === 6,
            },
            { view: item.status === 6 },
          ],
        },
        [
          _vm.businessType == 2
            ? [
                _c("span", { staticClass: "value" }, [
                  _c("span", { staticClass: "num" }, [
                    _vm._v(_vm._s(item.amount || item.reduce)),
                  ]),
                  _c("span", { staticClass: "sign" }, [_vm._v("积分")]),
                ]),
                _c("div", { staticClass: "content" }, [
                  _c("p", { staticClass: "type" }, [
                    _vm._v(_vm._s(item.title)),
                  ]),
                  _c("p", { staticClass: "valid" }, [
                    _vm._v(
                      "\n            开始：" +
                        _vm._s(_vm._f("formatDateStdCh")(item.start_time))
                    ),
                    _c("br"),
                    _vm._v(
                      "\n            结束：" +
                        _vm._s(_vm._f("formatDateStdCh")(item.end_time))
                    ),
                  ]),
                  _c("p", { staticClass: "limit" }, [
                    _vm._v(
                      "\n\t\t\t\t\t\t" +
                        _vm._s(item.full_type === 2 ? "每" : "") +
                        "\n\t\t\t\t\t\t满" +
                        _vm._s(item.amount_limit || item.full) +
                        "积分可用\n\t\t\t\t\t"
                    ),
                  ]),
                ]),
              ]
            : [
                _c("span", { staticClass: "value" }, [
                  _c("span", { staticClass: "sign" }, [_vm._v("¥")]),
                  _c("span", { staticClass: "num" }, [
                    _vm._v(_vm._s(parseFloat(item.amount || item.reduce))),
                  ]),
                ]),
                _c("div", { staticClass: "content" }, [
                  _c("p", { staticClass: "type" }, [
                    _vm._v(_vm._s(item.title)),
                  ]),
                  _c("p", { staticClass: "valid" }, [
                    _vm._v(
                      "\n            开始：" +
                        _vm._s(_vm._f("formatDateStdCh")(item.start_time))
                    ),
                    _c("br"),
                    _vm._v(
                      "\n            结束：" +
                        _vm._s(_vm._f("formatDateStdCh")(item.end_time))
                    ),
                  ]),
                  _c("p", { staticClass: "limit" }, [
                    _vm._v(
                      "\n\t\t\t\t\t\t" +
                        _vm._s(item.full_type === 2 ? "每" : "") +
                        "\n\t\t\t\t\t\t满" +
                        _vm._s(parseFloat(item.amount_limit || item.full)) +
                        "可用\n\t\t\t\t\t"
                    ),
                  ]),
                ]),
              ],
          _c("div", { staticClass: "dashed-line" }),
          item.status !== 6
            ? _c("div", [
                _c(
                  "div",
                  {
                    staticClass: "btn operate-btn flex-center",
                    class: _vm.map.get(item.status),
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                        return _vm.operate(item)
                      },
                    },
                  },
                  [_vm._v(_vm._s(_vm.txtMap.get(item.status)) + "\n        ")]
                ),
              ])
            : _c("div", [
                _c(
                  "div",
                  {
                    staticClass: "btn operate-btn flex-center",
                    class: _vm.map.get(item.status),
                  },
                  [_vm._v(_vm._s(_vm.txtMap.get(item.status)) + "\n        ")]
                ),
              ]),
        ],
        2
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }