var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.showStues,
          expression: "showStues",
        },
      ],
      staticClass: "head_nav",
    },
    [
      _c("yd-navbar", [
        _c(
          "div",
          {
            staticClass: "home-icon",
            attrs: { slot: "left" },
            on: {
              click: function ($event) {
                $event.stopPropagation()
                return _vm.goBack()
              },
            },
            slot: "left",
          },
          [
            _c("img", {
              staticClass: "icon_img",
              attrs: { src: require("@/assets/icon_go_page@2x.png"), alt: "" },
            }),
          ]
        ),
      ]),
      _c("p", { staticClass: "blank" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }