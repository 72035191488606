// 不显示购物车商城
const NO_CART = [
	'VUE_APP_ZJG'
];

export const shopMixin = {
	methods: {
		getCartStatus(type) {
			return NO_CART.indexOf(type);
		}
	}
};