<template>
  <div class="page">
    <yd-infinitescroll v-show="list.length" :callback="loadList" ref="infinitescrollDemo">
      <ul class="announcement-list" slot="list">
        <li v-for="(item, index) in list" :key="index" @click.stop="clickAnnouncemnet(item)">
          <div class="title">
            <div class="left">
              <span class="txt">商城公告</span>
              <span class="red-dot" v-if="!item.is_read"></span>
            </div>
            <span class="time">{{item.create_time | formatDateTimeHm}}</span>
          </div>
          <p class="content">{{item.title}}</p>
          <i class="icon icon-detail"></i>
        </li>
      </ul>
      <!-- 数据全部加载完毕显示 -->
      <span slot="doneTip">- 没有更多了 -</span>
    </yd-infinitescroll>

    <yd-backtop></yd-backtop>

    <!-- S 列表为空 -->
    <empty v-if="emptyList" tip="暂时没有任何商城公告哦～" icon="~@/assets/ic_huifu_kong@3x.png"></empty>
    <!-- E 列表为空 -->
  </div>
</template>

<script>
import Empty from '@/components/empty/Empty';

import {getNoticeList} from "@/services";
export default {
  name: "AnnouncementList",
  components: {
    Empty
  },
  data() {
    return {
      page: 1, // 开始查询的下标
      pageSize: 10, // 每次查询的数量
      list: [], // 优惠券列表
      emptyList: false // 是否为空
    };
  },
  created() {
    this.loadList();
  },
  methods: {
    // 加载数据
    async loadList() {
      try {
        const res = await getNoticeList(this.$store.state.userInfo.business_id, this.page, this.pageSize);
        if (res.code === 0) {
          const {list, total} = res.data;
          this.list = [...this.list, ...list];
          this.emptyList = !this.list.length;

          if (this.list.length === total) {
            // 所有数据加载完毕
          this.$refs.infinitescrollDemo.$emit("ydui.infinitescroll.loadedDone");
          return;
          }
          // 单次请求数据完毕
        this.$refs.infinitescrollDemo.$emit("ydui.infinitescroll.finishLoad");

        this.page++;
        } else {
          this.$dialog.toast({
            mes: res.msg,
            timeout: 1500,
            icon: "error"
          });
        }
        this.emptyList = !this.list.length;
      } catch (error) {
        console.warn("Something bad happened: ", error);
      }
    },
    // 点击公告
    clickAnnouncemnet(item) {
      this.$router.push({
        name: "announcement_details",
        query: {
          id: item.id
        }
      });
    }
  }
};
</script>

<style lang="less" scoped>
@import "../../../common/less/variable.less";
@import "../../../common/less/mixin.less";

.page {
  padding: 0.3rem;
  .bj(#fff);

  & /deep/ .announcement-list {
    width: 100%;

    li {
      position: relative;
      .wh(100%, 1.34rem);
      padding: 0.2rem 0.6rem 0.2rem 0.2rem;
      background-color: #fff;
      box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.05);
      border-radius: 0.08rem;
      overflow: hidden;

      .title {
        .fj;
        align-items: center;

        .left {
          display: flex;
          align-items: center;

          .txt {
            .sc(@fontsize-medium, @color-light-grey);
            line-height: 0.4rem;
          }

          .red-dot {
            .wh(6px, 6px);
            margin-left: 0.1rem;
            background-color: #ff1344;
            border-radius: 50%;
            overflow: hidden;
          }
        }

        .time {
          .sc(@fontsize-small, @color-light-grey);
          line-height: 0.34rem;
          text-align: right;
        }
      }

      .content {
        max-width: 100%;
        margin-top: 0.1rem;
        .sc(@fontsize-large, @color-dark-grey);
        line-height: 0.44rem;
        font-weight: bold;
        word-wrap: break-word;
        word-break: break-all;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .icon-detail {
        .ct;
        right: 0.16rem;
        display: inline-block;
        .wh(0.24rem, 0.24rem);
        .bis("../../../assets/Rectangle");
        z-index: 2;
      }

      & + li {
        margin-top: 0.3rem;
      }
    }
  }
}
</style>
