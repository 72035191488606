var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { id: "seconds-kill" } }, [
    _c("h3", { staticClass: "title" }, [_vm._v(_vm._s(_vm.title))]),
    _c("div", { staticClass: "cell" }, [
      _c("span", { staticClass: "left" }, [
        _c("span", { staticClass: "brief" }, [_vm._v(_vm._s(_vm.brief))]),
      ]),
      _c(
        "span",
        {
          staticClass: "right",
          on: {
            click: function ($event) {
              $event.preventDefault()
              return _vm.clickMore.apply(null, arguments)
            },
          },
        },
        [_c("span", { staticClass: "btn check-btn" }, [_vm._v("查看更多")])]
      ),
      _c("i", {
        staticClass: "icon icon-arrow-right",
        on: {
          click: function ($event) {
            $event.preventDefault()
            return _vm.clickMore.apply(null, arguments)
          },
        },
      }),
    ]),
    _c(
      "ul",
      {
        directives: [
          {
            name: "lazy-container",
            rawName: "v-lazy-container",
            value: _vm.lazyloadConf,
            expression: "lazyloadConf",
          },
        ],
        staticClass: "kill-list",
      },
      _vm._l(_vm.killList, function (item, index) {
        return _c(
          "li",
          {
            key: index,
            staticClass: "kill-item",
            on: {
              click: function ($event) {
                return _vm.clickSeconds(item)
              },
            },
          },
          [
            _c("div", { staticClass: "img-box" }, [
              _c("img", {
                attrs: { "data-src": item.base_pic, alt: "item pic" },
              }),
            ]),
            _c("p", { staticClass: "item-name" }, [
              item.source_type
                ? _c("span", { staticClass: "source-name" }, [
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm.getSourceTypes(item.source_type)) +
                        "\n          "
                    ),
                  ])
                : _vm._e(),
              _vm._v("\n          " + _vm._s(item.item_name) + "\n        "),
            ]),
            _vm.businessType == 2
              ? _c("p", { staticClass: "item-price" }, [
                  _vm.price_status == 1
                    ? _c("span", { staticClass: "seconds-price" }, [
                        _c("span", { staticClass: "num" }, [
                          _vm._v(
                            "\n              " +
                              _vm._s(
                                _vm.priceShow(
                                  item.display_type,
                                  item.limit_buy_price,
                                  item.max_integral_percentage || ""
                                )
                              ) +
                              "\n            "
                          ),
                        ]),
                      ])
                    : _vm._e(),
                  _vm.price_status == 1
                    ? _c("span", { staticClass: "old-price" }, [
                        _vm._v(
                          "\n            " +
                            _vm._s(
                              _vm.priceShow(
                                item.display_type,
                                item.mall_price,
                                item.max_integral_percentage || ""
                              )
                            ) +
                            "\n          "
                        ),
                      ])
                    : _vm._e(),
                ])
              : _c("p", { staticClass: "item-price" }, [
                  _vm.price_status == 1
                    ? _c("span", { staticClass: "seconds-price" }, [
                        _c("span", { staticClass: "sign" }, [_vm._v("¥")]),
                        _c("span", { staticClass: "num" }, [
                          _vm._v(_vm._s(item.limit_buy_price)),
                        ]),
                      ])
                    : _vm._e(),
                  _vm.price_status == 1
                    ? _c("span", { staticClass: "old-price" }, [
                        _vm._v("¥" + _vm._s(item.mall_price)),
                      ])
                    : _vm._e(),
                ]),
            _c("span", { staticClass: "btn kill-btn" }, [_vm._v("抢购")]),
            _c(
              "div",
              { staticClass: "countdown-wrap" },
              [
                item.kill_status === 1
                  ? _c("span", { staticClass: "session" }, [
                      _vm._v(_vm._s(_vm._f("formatDateMDS")(item.start_time))),
                    ])
                  : _c("span", { staticClass: "session" }, [_vm._v("秒杀中")]),
                _c(
                  "yd-countdown",
                  {
                    attrs: {
                      time: item.end_time,
                      timetype: "timestamp",
                      format: "{%h}:{%m}:{%s}",
                    },
                  },
                  [
                    _c("em", { staticClass: "time" }, [_vm._v("{%h}")]),
                    _c("i", [_vm._v(":")]),
                    _c("em", { staticClass: "time" }, [_vm._v("{%m}")]),
                    _c("i", [_vm._v(":")]),
                    _c("em", { staticClass: "time" }, [_vm._v("{%s}")]),
                  ]
                ),
              ],
              1
            ),
          ]
        )
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }