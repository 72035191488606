<template>
  <div class="nav-container">
    <router-link to="home" class="nav-item flex-center">
      <icon-svg
        class="main-color"
        v-if="$route.name === 'home'"
        icon-class="yi_ic_home_blue_"
      />
      <icon-svg class="grey" v-else icon-class="yi_ic_home_grey_" />
      <span class="nav-txt">首页</span>
    </router-link>
    <router-link to="category" class="nav-item flex-center">
      <icon-svg
        class="main-color"
        v-if="$route.name === 'category'"
        icon-class="yi_ic_type_blue_"
      />
      <icon-svg class="grey" v-else icon-class="yi_ic_type_grey_" />
      <span class="nav-txt">分类</span>
    </router-link>
    <router-link
			v-if="cart_show && businessInfo.mall_display_only !== 1"
      to="shopping_cart"
      class="nav-item flex-center"
    >
      <icon-svg
        class="main-color"
        v-if="$route.name === 'shopping_cart'"
        icon-class="yi_ic_shopping_blue_"
      />
      <icon-svg v-else class="grey" icon-class="yi_ic_shopping_grey_" />
      <span class="nav-txt">购物车</span>
    </router-link>
    <router-link 
			v-if="businessInfo.mall_display_only !== 1"
			to="my"
			class="nav-item flex-center"
		>
      <icon-svg
        class="main-color"
        v-if="$route.name === 'my'"
        icon-class="yi_ic_my_blue_"
      />
      <icon-svg v-else class="grey" icon-class="yi_ic_my_grey_" />
      <span class="nav-txt">我的</span>
    </router-link>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { shopMixin } from "@/mixins/shopMixin.js";

export default {
  name: "foot_nav",
	mixins: [shopMixin],
  data() {
    return {
      cart_show: true //默认显示购物车,特殊商场不显示
    };
  },
  computed: {
    ...mapState({
      businessInfo: state => state.businessInfo
			// tripartite_type: state => state.tripartite_type
    })
  },
  watch: {
    "businessInfo.show_item_sn": {
      handler(newName) {
        // console.log(newName, "newName");
        if (newName == 1) {
          this.cart_show = false;
        } else {
          this.cart_show = true;
        }
      },
      immediate: true
    }
  }
};
</script>

<style lang="less" scoped>
@import "../../assets/iconfont/iconfont.css";
@import "../../common/less/variable.less";
@import "../../common/less/mixin.less";

.nav-container {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  .fj;
  align-items: center;
  height: 0.98rem;
  background: #fff;
  box-shadow: 0 -1px 0.2rem 0 rgba(0, 0, 0, 0.1);
  z-index: 99999;

  .nav-item {
    flex: 1;
    flex-direction: column;
    height: 100%;

    .icon {
      .wh(0.48rem, 0.48rem);
      .sc(0.48rem, @color-light-grey);
      margin-bottom: 0.08rem;
    }

    .nav-txt {
      .sc(0.22rem, @color-light-grey);
      line-height: 0.32rem;
    }

    .yi_ic_home_blue_,
    .yi_ic_type_blue_,
    .yi_ic_shopping_blue_,
    .yi_ic_my_blue_ {
      color: var(--main-color);
    }

    &.router-link-active .nav-txt {
      color: var(--main-color);
    }

    .grey {
      color: #b3b3b3;
    }
  }
}
</style>
