/**
 * @todo: record page's scroll position(scrollTop)
 * @param: none
 * @return: mixin object
 * @author: zcl
 * @date: 2019-06-17 14:35:51
 * @version: V1.0.0
 */
import { is_ios, is_android } from "@/utils/common.js";
export const scrollerMixin = {
  data() {
    return {
      scrollTop: 0
    };
  },
  activated() {
    if (document.documentElement) {
      this.$nextTick(() => {
        document.documentElement.scrollTop = this.scrollTop;
      });
    } else {
      this.$nextTick(() => {
        document.body.scrollTop = this.scrollTop;
      });
    }
  },
  beforeRouteLeave(to, from, next) {
    const scroller = document.documentElement || document.body;
    this.scrollTop = scroller.scrollTop || 0;
    next();
  }
};

export const focusMixin = {
  mounted() {
    let _isReset_ = true; //是否归位
    const _h_ = document.body.scrollHeight;
    if (is_ios()) {
      document.body.addEventListener("focusin", () => {
        //软键盘弹出的事件处理
        _isReset_ = false;
      });
      document.body.addEventListener("focusout", () => {
        //软键盘收起的事件处理
        _isReset_ = true;
        setTimeout(() => {
          //当焦点在弹出层的输入框之间切换时先不归位
          if (_isReset_) {
            window.scroll(0, 0); //失焦后强制让页面归位
          }
        }, 300);
      });
    } else if (is_android()) {
      window.onresize = function() {
        //键盘弹起与隐藏都会引起窗口的高度发生变化
        let resizeHeight =
          document.documentElement.clientHeight || document.body.clientHeight;
        if (resizeHeight < _h_) {
          //当软键盘弹起，在此处操作
          _isReset_ = false;
        } else {
          //当软键盘收起，在此处操作
          _isReset_ = true;
          setTimeout(() => {
            if (_isReset_) {
              window.scroll(0, 0); //失焦后强制让页面归位
            }
          }, 300);
        }
      };
    }
  }
};
