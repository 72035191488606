<template>
  <div class="page">
    <div class="info-box">
      <div class="title">
        <span class="txt">商城公告</span>
        <span class="time">{{info.ctime | formatDateTimeHm}}</span>
      </div>
      <div class="content">
        <div class="no-title">{{info.title}}</div>
        <div v-html="info.contents"></div>
      </div>
    </div>
  </div>
</template>

<script>
import {getNoticeDetail} from "@/services";
export default {
  name: "AnnouncementDetails",
  data() {
    return {
      info: {} // 公告信息
    };
  },
  created() {
    this.loadInfo();
  },
  methods: {
    // 获取信息
    async loadInfo() {
      console.log(this.$route.query.id);
      try {
        const res = await getNoticeDetail(this.$route.query.id);
        if (res.code === 0) {
          this.info = res.data;
        } else {
          this.$dialog.toast({
            mes: res.msg,
            timeout: 1500,
            icon: "error"
          });
        }
      } catch (error) {
        console.warn("Something bad happened: ", error);
      }
    }
  }
};
</script>

<style lang="less" scoped>
@import "../../../common/less/variable.less";
@import "../../../common/less/mixin.less";

.page {
  .bj(@color-background-grew);

  .info-box {
    padding: 0 .30rem;
    background-color: #fff;

    .title {
      position: relative;
      .fj;
      align-items: center;
      .wh(100%, 1rem);
      border-bottom: 1px solid #F1F1F1;

      .txt {
        .sc(@fontsize-medium, @color-light-grey);
        line-height: 0.4rem;
      }

      .time {
        .sc(@fontsize-small, @color-light-grey);
        line-height: 0.34rem;
        text-align: right;
      }
    }

    .content {
      .no-title{
        text-align: center;
        .sc(@fontsize-large, @color-dark-grey);
      }
      padding: .20rem 0;
      .sc(@fontsize-medium, @color-dark-grey);
      line-height: .50rem;
      font-weight: bold;
      word-wrap: break-word;
      word-break: break-all;
    }
  }
}
</style>
