var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return !_vm.seaStatus
    ? _c(
        "div",
        { staticClass: "page", style: _vm.backImg },
        [
          _vm.businessInfo && _vm.businessInfo.is_activity_rules_show
            ? _c("img", {
                staticClass: "activityicon",
                attrs: { src: require("../../assets/activityicon.png") },
                on: { click: _vm.activityiconto },
              })
            : _vm._e(),
          _vm.businessInfo && _vm.businessInfo.is_service_tel_show
            ? _c("img", {
                staticClass: "zhIndexIconKf",
                attrs: { src: require("../../assets/zhIndexIconKf.png") },
                on: { click: _vm.zhIndexIconKfClick },
              })
            : _vm._e(),
          _vm.businessInfo && _vm.businessInfo.is_order_show
            ? _c("img", {
                staticClass: "indexOrdericon",
                attrs: { src: require("../../assets/indexOrdericon.png") },
                on: { click: _vm.indexiconto },
              })
            : _vm._e(),
          _c("HeadNavIcbc"),
          _vm.isTaben
            ? _c("Scroller", {
                staticClass: "scrollContainer left",
                attrs: { lists: _vm.lists },
              })
            : _vm._e(),
          _vm._l(_vm.templateArr, function (item, index) {
            return _c(
              "div",
              { key: index },
              [
                item.module === "search" ? _c("Search") : _vm._e(),
                item.module === "slider" && _vm.hackReset
                  ? _c("Banner")
                  : _vm._e(),
                item.module === "subject"
                  ? _c("NewCategory", {
                      attrs: { config: item.config, show: _vm.show },
                    })
                  : _vm._e(),
                item.module === "coupon" ? _c("Coupon") : _vm._e(),
                item.module === "area"
                  ? _c("Prefecture", {
                      attrs: { config: item.config, show: _vm.show },
                    })
                  : _vm._e(),
                item.module === "notice" ? _c("Announcement") : _vm._e(),
                item.module === "promotion"
                  ? _c("Promotion", { attrs: { config: item.config } })
                  : _vm._e(),
                item.module === "seconds"
                  ? _c("SecondsKill", { attrs: { brief: item.desc } })
                  : _vm._e(),
                item.module === "group"
                  ? _c("GroupBuy", { attrs: { brief: item.desc } })
                  : _vm._e(),
                item.module === "item" ? _c("SpecialTopic") : _vm._e(),
              ],
              1
            )
          }),
          _vm.guangdaStatus
            ? _c(
                "a",
                {
                  staticClass: "custom-img",
                  attrs: {
                    href: "https://mp.weixin.qq.com/s/0VzlWb_q9MvIUZIBnqIC5w",
                  },
                },
                [
                  _c("img", {
                    attrs: {
                      src: require("@/static/image/guangda.png"),
                      alt: "guangda",
                    },
                  }),
                ]
              )
            : _vm._e(),
          _c("foot-nav", { attrs: { is_show: _vm.cart_show } }),
          _c("yd-backtop"),
          _vm.zgyh_show
            ? _c(
                "p",
                {
                  staticClass: "auto_info",
                  on: {
                    click: function ($event) {
                      return _vm.toAuthInfo()
                    },
                  },
                },
                [_vm._v("关于我们")]
              )
            : _vm._e(),
        ],
        2
      )
    : _c(
        "div",
        { staticClass: "member-mall" },
        [
          _c("member-mall", { attrs: { is_login: _vm.is_login } }),
          _c("yqt-foot"),
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }