var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "page" },
    [
      _c("HeadNav"),
      _c(
        "yd-infinitescroll",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.list.length,
              expression: "list.length",
            },
          ],
          ref: "infinitescrollDemo",
          attrs: { callback: _vm.loadList },
        },
        [
          _c("coupon-list", {
            attrs: { slot: "list", is_start: false, list: _vm.list },
            on: { operate: _vm.operate },
            slot: "list",
          }),
          _c("span", { attrs: { slot: "doneTip" }, slot: "doneTip" }, [
            _vm._v("- 没有更多了 -"),
          ]),
        ],
        1
      ),
      _c("yd-backtop"),
      _vm.emptyList
        ? _c("empty", { attrs: { tip: "本次活动已经结束，敬请期待下次~" } })
        : _vm._e(),
      _c("alert-tip", {
        attrs: { type: _vm.type, showAlert: _vm.show_tip, alertText: _vm.tip },
        on: { changeShowAlert: _vm.changeShow },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }