<template>
  <div id="special-topic">
    <div v-for="(item, index) in itemList" :key="index">
      <div class="style1" v-if="item.template === 1">
        <h3 class="title">{{ item.subjects.title }}</h3>
        <div class="cell">
          <span class="left">
            <span class="brief">{{ item.subjects.desc }}</span>
          </span>
          <span class="right" @click.stop="checkMore(item.subjects.id)">
            <span class="btn check-btn">查看更多</span>
          </span>
          <i class="icon icon-arrow-right"></i>
        </div>
        <ul class="item-list">
          <li
            class="item"
            v-for="(i, j) in item.subjects.items"
            :key="j"
            @click="clickItem(i)"
          >
            <div class="img-box">
              <yqt-image
                class="img"
                :src="i.base_pic | setHttps"
                lazyload
                :loading-img="loadingImg"
                fit="cover"
                alt="item pic"
              />
            </div>
            <p class="item-name">
              <span class="sourceName" v-if="i.source_type">
                {{ getSourceTypes(i.source_type) }}
              </span>
              {{ i.item_name }}
            </p>
            <p class="sales" v-if="salesStatus()">
              <span>已售</span>
              <span>{{ getSales(i.id, i.cost_price) }}</span>
            </p>
            <div class="priceWithBtn">
              <p class="item-price" v-if="businessType == 2">
                <span class="price" v-if="price_status == 1">
                  <span class="num">
                    {{
                      priceShow(
                        i.display_type,
                        i.price,
                        i.max_integral_percentage
                      )
                    }}
                  </span>
                </span>
              </p>
              <p class="item-price" v-else>
                <span class="price" v-if="price_status == 1">
                  <span class="sign">&yen;</span>
                  <span class="num">{{ i.price }}</span>
                </span>
              </p>
              <span v-if="businessType != 2 && buyBtn" class="btn buy-btn">
                购买
              </span>
            </div>
            <div
              v-if="tripartite_type === 'VUE_APP_ZJG' && i.display_type !== 1"
              class="zjg-max"
            >
              积分最高抵扣{{ i.max_integral_percentage }}%
            </div>
          </li>
        </ul>
      </div>
      <div class="style2" v-if="item.template === 3">
        <h3 class="title">{{ item.subjects.title }}</h3>
        <div class="cell">
          <span class="left">
            <span class="brief">{{ item.subjects.desc }}</span>
          </span>
        </div>
        <div
          class="theme-img-box flex-center"
          @click.stop="checkMore(item.subjects.id)"
        >
          <yqt-image
            class="img"
            :src="item.subjects.img | setHttps"
            lazyload
            :loading-img="loadingImg3"
            fit="cover"
            alt="item pic"
          />
        </div>
        <!-- <div class="item-list-wrap"> -->
        <ul class="item-list">
          <li
            class="item"
            v-for="(i, j) in item.subjects.items"
            :key="j"
            @click="clickItem(i)"
          >
            <div class="img-box">
              <yqt-image
                class="img"
                :src="i.base_pic | setHttps"
                lazyload
                :loading-img="loadingImg"
                fit="cover"
                alt="item pic"
              />
            </div>
            <p class="item-name">
              <span class="sourceName" v-if="i.source_type">
                {{ getSourceTypes(i.source_type) }}
              </span>
              {{ i.item_name }}
            </p>
            <p class="sales" v-if="salesStatus()">
              <span>已售</span>
              <span>{{ getSales(i.id, i.cost_price) }}</span>
            </p>
            <div class="priceWithBtn">
              <p class="item-price" v-if="businessType == 2">
                <span class="price" v-if="price_status == 1">
                  <span class="num">
                    {{
                      priceShow(
                        i.display_type,
                        i.price,
                        i.max_integral_percentage
                      )
                    }}
                  </span>
                </span>
              </p>
              <p class="item-price" v-else>
                <span class="price" v-if="price_status == 1">
                  <span class="sign">&yen;</span>
                  <span class="num">{{ i.price }}</span>
                </span>
              </p>
            </div>
            <div
              v-if="tripartite_type === 'VUE_APP_ZJG'"
              class="zjg-max no-padding"
            >
              积分最高抵扣{{ i.max_integral_percentage }}%
            </div>
          </li>
        </ul>
        <!-- </div> -->
        <div
          class="check-more flex-center"
          @click.stop="checkMore(item.subjects.id)"
        >
          <span class="check-btn">查看更多</span>
          <i class="icon icon-arrow-right"></i>
        </div>
      </div>
      <div class="style3" v-if="item.template === 2">
        <div class="theme-img-box" @click.stop="checkMore(item.subjects.id)">
          <yqt-image
            class="img"
            :src="item.subjects.img | setHttps"
            lazyload
            :loading-img="loadingImg2"
            fit="cover"
            alt="item pic"
          />
        </div>
        <ul class="item-list">
          <li
            v-for="(i, j) in item.subjects.items"
            :key="j"
            @click="clickItem(i)"
            class="item"
            :class="tripartite_type === 'VUE_APP_ZJG' ? 'zjg-item' : ''"
          >
            <div class="img-box">
              <yqt-image
                class="img"
                :src="i.base_pic | setHttps"
                lazyload
                :loading-img="loadingImg"
                fit="cover"
                alt="item pic"
              />
            </div>
            <p class="item-name">
              <span class="sourceName" v-if="i.source_type">
                {{ getSourceTypes(i.source_type) }}
              </span>
              {{ i.item_name }}
            </p>
            <p class="sales" v-if="salesStatus()">
              <span>已售</span>
              <span>{{ getSales(i.id, i.cost_price) }}</span>
            </p>
            <div class="priceWithBtn">
              <p class="item-price" v-if="businessType == 2">
                <span class="price" v-if="price_status == 1">
                  <span class="num">
                    {{
                      priceShow(
                        i.display_type,
                        i.price,
                        i.max_integral_percentage
                      )
                    }}
                  </span>
                </span>
              </p>
              <p class="item-price" v-else>
                <span class="price" v-if="price_status == 1">
                  <span class="sign">&yen;</span>
                  <span class="num">{{ i.price }}</span>
                </span>
              </p>
            </div>
            <div
              v-if="tripartite_type === 'VUE_APP_ZJG' && i.display_type !== 1"
              class="zjg-max no-padding"
            >
              积分最高抵扣{{ i.max_integral_percentage }}%
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { getItems } from "@/services";
import { getSales, priceShow } from "@/utils/goodsUtil";
import { getStore } from "@/utils/common";
import { orderMixin } from "@/mixins/orderMixin";
import { mapState } from "vuex";
import YqtImage from "@/components/image/Image";
const loadingImg = require("@/static/image/loading_320x320.gif");
const loadingImg2 = require("@/static/image/loading_750x80.gif");
const loadingImg3 = require("@/static/image/loading_750x320.gif");

export default {
  name: "SpecialTopic",
  components: {
    YqtImage,
  },
  mixins: [orderMixin],
  data() {
    return {
      theme: "", // 主题色
      itemList: [], // 秒杀列表
      buyBtn: true, // 购买按钮显隐
      loadingImg: loadingImg,
      loadingImg2: loadingImg2,
      loadingImg3: loadingImg3,
    };
  },
  computed: {
    ...mapState({
      bid: (state) => state.businessInfo.id, //商城id
      businessType: (state) => state.businessInfo.type, //1现金商城，2积分商品，3内采商城
      price_status: (state) => state.config.price_status, // 是否展示价格 1展示 0 不展示
      mall_price_status: (state) => state.config.mall_price_status, //  是否展示价格 1展示 0 不展示
      integralRate: (state) => state.config.integral_rate, // 积分比例
      tripartite_type: (state) => state.tripartite_type,
    }),
  },
  created() {
    this.loadData();
    this.buyStatus();
  },
  methods: {
    // 获取数据
    async loadData() {
      try {
        const res = await getItems(this.$store.state.userInfo.business_id);
        if (res.code === 0) {
          this.itemList = res.data;
        } else {
          this.$dialog.toast({
            mes: res.msg,
            timeout: 1500,
            icon: "error",
          });
        }
      } catch (error) {
        console.warn("Something bad happened: ", error);
      }
    },
    // 点击商品
    clickItem(item) {
      this.$router.push({
        name: "goods-detail",
        query: {
          item_id: item.pivot.item_id,
        },
      });
    },
    // 点击查看更多
    checkMore(subject_id) {
      this.$router.push({
        name: "special_topic",
        query: {
          subject_id: subject_id,
        },
      });
    },

    // 积分3.0显示
    priceShow,

    //处理已售
    getSales,

    //已售显隐
    salesStatus() {
      if (this.bid === 1131 || this.bid === 1134) {
        return true;
      } else {
        return false;
      }
      //return true;
    },

    buyStatus() {
      let inf = getStore("businessInfo");
      if (JSON.parse(inf).hasOwnProperty("show_item_sn")) {
        if (JSON.parse(inf).show_item_sn === 1) {
          this.buyBtn = false;
        }
      }
    },
  },
};
</script>

<style lang="less" scoped>
@import "../../common/less/variable.less";
@import "../../common/less/mixin.less";

#special-topic {
  // background-color: #fff;

  .title {
    .sc(@fontsize-large-xx, @color-dark-grey);
    height: 0.56rem;
    line-height: 0.56rem;
    font-weight: bold;
  }

  .zjg-max {
    color: var(--main-color);
    padding-left: 0.2rem;
    margin-top: 0.1rem;
    font-size: 0.2rem;
  }

  .cell {
    position: relative;
    .fj;
    align-items: center;
    padding-right: 0.24rem;

    .left {
      .brief {
        .sc(@fontsize-large, @color-light-grey);
        line-height: 0.44rem;
      }
    }

    .right {
      position: relative;
      display: flex;
      justify-content: flex-end;

      .check-btn {
        .sc(@fontsize-medium, @color-light-grey);
        line-height: 0.4rem;
      }
    }

    .icon-arrow-right {
      .ct;
      right: 0;
      display: inline-block;
      vertical-align: middle;
      .wh(0.12rem, 0.21rem);
      .bis("../../assets/icpn_gengduo");
    }
  }

  .style2,
  .style3 {
    padding-bottom: 0.3rem;
  }

  .style2 .title,
  .style2 .cell,
  .style2 ul {
    padding: 0 0.3rem;
  }

  .style1 {
    padding: 0 0.3rem 0.4rem;

    .item-list {
      position: relative;
      overflow: hidden;

      .item {
        position: relative;
        .wh(3.35rem, 5.15rem);
        // width: 47.5%;
        margin-top: 0.2rem;
        padding-bottom: 0.15rem;
        float: left;
        position: relative;
        background: #fff;
        box-shadow: 0px 3px 20px 0px rgba(0, 0, 0, 0.05);
        border-radius: 0.08rem;
        overflow: hidden;
        z-index: 0;

        &:nth-child(odd) {
          margin-right: 0.2rem;
        }

        .img-box {
          .wh(100%, 3.35rem);
          margin-bottom: 0.14rem;

          .img {
            .wh(100%, 100%);
          }
        }

        .sales {
          width: 100%;
          display: flex;
          justify-content: space-between;
          margin-bottom: 0.1rem;
          padding: 0 0.1rem;
        }

        .item-name {
          padding: 0 0.2rem;
          height: 0.72rem;
          margin-bottom: 0.12rem;
          .sc(0.26rem, rgba(0, 0, 0, 0.87));
          line-height: 0.36rem;
          word-break: break-all;
          word-wrap: break-word;
          .ellipsis(2);

          .sourceName {
            position: relative;
            display: inline-block;
            height: 0.36rem;
            line-height: 0.36rem;
            padding: 0 0.15rem;
            border-radius: 0.18rem;
            background-color: var(--main-color);
            color: #ffffff;
            font-size: 0.18rem;
          }
        }

        .priceWithBtn {
          position: absolute;
          bottom: 4%;
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 100%;

          .item-price {
            padding-left: 0.2rem;

            .price {
              color: @color-dark-grey;
              line-height: 0.28rem;
              font-weight: bold;

              .sign {
                font-size: @fontsize-small;
              }

              .num {
                font-size: @fontsize-large;
              }
            }
          }

          .buy-btn {
            /* position: absolute;
						right: 0;
						bottom: 0.2rem; */
            .wh(0.9rem, 0.48rem);
            padding-left: 0.26rem;
            padding-top: 0.08rem;
            .sc(@fontsize-small, #fff);
            line-height: 0.36rem;
            font-weight: 500;
            white-space: nowrap;
            background-color: var(--main-color);
            border-radius: 2rem 0px 0px 2rem;
            z-index: 2;
          }
        }
      }
    }
  }

  .style2 {
    position: relative;

    .theme-img-box {
      .wh(100%, 4.2rem);
      margin-bottom: 0.18rem;

      .img {
        .wh(100%, 100%);
      }
    }

    ul {
      width: 100%;
      white-space: nowrap;
      overflow: hidden;
      overflow-x: auto;
      margin-bottom: 0.4rem;
      -webkit-overflow-scrolling: touch;

      li {
        display: inline-block;
        width: 2.4rem;
        padding-bottom: 0.1rem;
        position: relative;
        background: #fff;
        border-radius: 4px;
        overflow: hidden;
        z-index: 0;

        .img-box {
          .wh(2.4rem, 2.4rem);
          .img {
            .wh(100%, 100%);
          }
        }

        .sales {
          width: 100%;
          display: flex;
          justify-content: space-between;
          margin-top: 0.1rem;
          padding-right: 0.1rem;
        }

        .item-name {
          .wh(2.24rem, 0.32rem);
          margin: 0.1rem 0 0 0;
          padding: 0 0.08rem;
          .sc(0.22rem, rgba(0, 0, 0, 0.87));
          line-height: 0.32rem;
          word-wrap: break-word;
          word-break: break-all;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;

          .sourceName {
            position: relative;
            display: inline-block;
            height: 0.32rem;
            line-height: 0.32rem;
            padding: 0 0.15rem;
            border-radius: 0.16rem;
            background-color: var(--main-color);
            color: #ffffff;
            font-size: 0.18rem;
          }
        }

        .item-price {
          margin: 0.1rem 0 0 0;
          padding: 0 0.08rem;
          .sc(@fontsize-small, @color-dark-grey);
          line-height: 0.28rem;
          font-weight: bold;
        }

        .no-padding {
          padding-left: 0;
        }

        & + li {
          margin-left: 0.1rem;
        }
      }
    }

    .check-more {
      width: 100%;

      .check-btn {
        margin-right: 0.08rem;
        .sc(@fontsize-medium, @color-light-grey);
        line-height: 0.4rem;
      }

      .icon-arrow-right {
        display: inline-block;
        vertical-align: middle;
        .wh(0.12rem, 0.22rem);
        .bis("../../assets/icpn_gengduo");
      }
    }

    &:after {
      content: "";
      .cl;
      bottom: 0.1rem;
      display: block;
      width: calc(100% - 0.6rem);
      height: 1px;
      background-color: @color-row-line;
    }
  }

  .style3 {
    .theme-img-box {
      padding: 0 0.3rem;
      height: 1.88rem;

      .img {
        .wh(100%, 100%);
      }
    }

    ul {
      .wh(100%, 4.3rem);
      // width: 100%;
      padding: 0.18rem 0.3rem 0 0.18rem;
      overflow: hidden;
      overflow-x: auto;
      white-space: nowrap;
      // .bis("../../assets/bg_commodity");
      -webkit-overflow-scrolling: touch;

      .zjg-item {
        height: 4rem;
      }

      li {
        display: inline-block;
        .wh(2.4rem, 3.34rem);
        // width: 2.4rem;
        padding-bottom: 0.1rem;
        position: relative;
        background: #fff;
        border-radius: 4px;
        z-index: 0;
        overflow: hidden;

        & + li {
          margin-left: 0.16rem;
        }

        .img-box {
          .wh(2.4rem, 2.4rem);
          .img {
            .wh(100%, 100%);
          }
        }

        .sales {
          width: 100%;
          display: flex;
          justify-content: space-between;
          margin-bottom: 0.1rem;
          padding: 0 0.1rem;
        }

        .item-name {
          .wh(2.24rem, 0.34rem);
          padding: 0 0.08rem;
          margin: 0.08rem 0 0.12rem 0;
          .sc(@fontsize-small, rgba(0, 0, 0, 0.87));
          line-height: 0.34rem;
          .ellipsis(2);
          .sourceName {
            position: relative;
            display: inline-block;
            height: 0.34rem;
            line-height: 0.34rem;
            padding: 0 0.15rem;
            border-radius: 0.17rem;
            background-color: var(--main-color);
            color: #ffffff;
            font-size: 0.18rem;
          }
        }

        .item-price {
          padding: 0 0.08rem;
          margin: 0;
          .sc(@fontsize-small, @color-dark-grey);
          line-height: 0.28rem;
          font-weight: bold;
        }

        .no-padding {
          padding-left: 0;
        }
      }
    }
  }
}
</style>
