var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "banner" } },
    [
      _c(
        "yd-slider",
        {
          attrs: {
            autoplay: "3000",
            showPagination: false,
            callback: _vm.getCurrentPage,
          },
        },
        _vm._l(_vm.banners, function (item, index) {
          return _c(
            "yd-slider-item",
            {
              key: index,
              nativeOn: {
                click: function ($event) {
                  return _vm.clickBanner(item)
                },
              },
            },
            [
              _c("div", { staticClass: "img-box" }, [
                _c("img", {
                  attrs: {
                    src: _vm._f("ossProcess")(
                      _vm._f("setHttps")(item.img),
                      "style/w_960_h_auto"
                    ),
                    alt: "banner",
                  },
                }),
              ]),
            ]
          )
        }),
        1
      ),
      _c(
        "div",
        { staticClass: "pagination flex-center" },
        _vm._l(_vm.banners, function (item, index) {
          return _c("span", {
            key: index,
            staticClass: "pagination-item",
            class: { "pagination-item-active": _vm.currentPage === index },
          })
        }),
        0
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }