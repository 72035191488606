var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "yd-layout",
    { staticClass: "page" },
    [
      _vm.tabs.length
        ? _c(
            "div",
            {
              staticClass: "horizontal-scroll-wrap",
              attrs: { slot: "navbar" },
              slot: "navbar",
            },
            [
              _c(
                "ul",
                { staticClass: "tab-list" },
                _vm._l(_vm.tabs, function (item, index) {
                  return _c(
                    "li",
                    {
                      key: index,
                      staticClass: "flex-center",
                      class: { active: _vm.activeTab === index },
                      on: {
                        click: function ($event) {
                          return _vm.clickTab(index)
                        },
                      },
                    },
                    [
                      _c("span", { staticClass: "time" }, [
                        _vm._v(_vm._s(_vm._f("formatHm")(item.start_time))),
                      ]),
                      _c("span", { staticClass: "status" }, [
                        _vm._v(_vm._s(_vm.statusMap.get(item.status))),
                      ]),
                    ]
                  )
                }),
                0
              ),
            ]
          )
        : _vm._e(),
      _vm.tabs.length
        ? _c(
            "yd-infinitescroll",
            {
              ref: "infinitescrollDemo",
              attrs: { callback: _vm.loadItemList },
            },
            [
              _c("div", { attrs: { slot: "list" }, slot: "list" }, [
                _vm.tabs[_vm.activeTab].status === 1 ||
                _vm.tabs[_vm.activeTab].status === 2 ||
                _vm.tabs[_vm.activeTab].status === 3
                  ? _c("div", { staticClass: "time-clock" }, [
                      _c("span", { staticClass: "tip" }, [
                        _vm._v(_vm._s(_vm.tip)),
                      ]),
                      _c(
                        "span",
                        { staticClass: "countdown-wrap" },
                        [
                          _c("span", { staticClass: "txt" }, [
                            _vm._v("距" + _vm._s(_vm.countdownTxt)),
                          ]),
                          _c(
                            "yd-countdown",
                            {
                              attrs: {
                                time: _vm.countdown,
                                timetype: "timestamp",
                                format: "{%h}:{%m}:{%s}",
                              },
                            },
                            [
                              _c("em", { staticClass: "time" }, [
                                _vm._v("{%h}"),
                              ]),
                              _c("i", [_vm._v(":")]),
                              _c("em", { staticClass: "time" }, [
                                _vm._v("{%m}"),
                              ]),
                              _c("i", [_vm._v(":")]),
                              _c("em", { staticClass: "time" }, [
                                _vm._v("{%s}"),
                              ]),
                            ]
                          ),
                        ],
                        1
                      ),
                    ])
                  : _vm._e(),
                _c(
                  "div",
                  { staticClass: "item-list-wrap" },
                  [
                    _c("row-item-list", {
                      attrs: {
                        type: "seconds",
                        activeTab: _vm.activeTab,
                        itemList: _vm.itemList,
                      },
                      on: { "click-item": _vm.clickItem },
                    }),
                  ],
                  1
                ),
              ]),
              !_vm.emptyList
                ? _c("span", { attrs: { slot: "doneTip" }, slot: "doneTip" }, [
                    _vm._v("- 没有更多了 -"),
                  ])
                : _vm._e(),
            ]
          )
        : _vm._e(),
      _c("yd-backtop"),
      _vm.emptyList ? _c("empty") : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }