var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "ul",
    { staticClass: "item-list" },
    _vm._l(_vm.itemList, function (item, index) {
      return _c(
        "li",
        {
          key: index,
          staticClass: "item",
          class: item.display_type === 3 ? "had-stage" : "no-stage",
          on: {
            click: function ($event) {
              return _vm.clickItem(item, index)
            },
          },
        },
        [
          _c("div", { staticClass: "img-box" }, [
            _c("img", { attrs: { src: item.base_pic, alt: "item pic" } }),
          ]),
          _c("p", { staticClass: "item-name" }, [
            item.source_type
              ? _c("span", { staticClass: "sourceName" }, [
                  _vm._v(
                    "\n\t\t\t\t\t" +
                      _vm._s(_vm.getSourceTypes(item.source_type)) +
                      "\n\t\t\t\t"
                  ),
                ])
              : _vm._e(),
            _vm._v("\n\t\t\t\t" + _vm._s(item.item_name) + "\n\t\t\t"),
          ]),
          _c("div", { staticClass: "priceWithBtn" }, [
            _c("p", { staticClass: "item-price" }, [
              _c("span", { staticClass: "price" }, [
                _vm._v(
                  "\n\t\t\t\t\t\t" +
                    _vm._s(
                      _vm.priceShow(
                        item.display_type,
                        item.price,
                        item.max_integral_percentage
                      )
                    ) +
                    "\n\t\t\t\t\t"
                ),
              ]),
            ]),
          ]),
        ]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }