var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { id: "prefecture" } }, [
    _vm.activeNav === 1
      ? _c(
          "ul",
          { staticClass: "prefecture-list" },
          _vm._l(_vm.prefectureList, function (item, index) {
            return _c(
              "li",
              {
                key: index,
                staticClass: "prefecture-item",
                on: {
                  click: function ($event) {
                    return _vm.clickPrefecture(item)
                  },
                },
              },
              [
                _c("div", { staticClass: "img-box" }, [
                  _c("img", {
                    attrs: {
                      src: _vm._f("ossProcess")(
                        _vm._f("setHttps")(item.img),
                        "style/w_240_h_auto"
                      ),
                      alt: "prefecture",
                    },
                  }),
                ]),
              ]
            )
          }),
          0
        )
      : _vm._e(),
    _vm.activeNav === 2
      ? _c("div", { staticClass: "nav-type" }, [
          _vm.prefectureList[0]
            ? _c(
                "div",
                {
                  staticClass: "box",
                  on: {
                    click: function ($event) {
                      return _vm.clickPrefecture(_vm.prefectureList[0])
                    },
                  },
                },
                [
                  _c("img", {
                    attrs: {
                      src: _vm._f("ossProcess")(
                        _vm._f("setHttps")(_vm.prefectureList[0].img),
                        "style/w_240_h_auto"
                      ),
                    },
                  }),
                ]
              )
            : _vm._e(),
          _c("div", { staticClass: "box" }, [
            _vm.prefectureList[1]
              ? _c(
                  "div",
                  {
                    staticClass: "up",
                    on: {
                      click: function ($event) {
                        return _vm.clickPrefecture(_vm.prefectureList[1])
                      },
                    },
                  },
                  [
                    _c("img", {
                      attrs: {
                        src: _vm._f("ossProcess")(
                          _vm._f("setHttps")(_vm.prefectureList[1].img),
                          "style/w_240_h_auto"
                        ),
                      },
                    }),
                  ]
                )
              : _vm._e(),
            _vm.prefectureList[2]
              ? _c(
                  "div",
                  {
                    staticClass: "down",
                    on: {
                      click: function ($event) {
                        return _vm.clickPrefecture(_vm.prefectureList[2])
                      },
                    },
                  },
                  [
                    _c("img", {
                      attrs: {
                        src: _vm._f("ossProcess")(
                          _vm._f("setHttps")(_vm.prefectureList[2].img),
                          "style/w_240_h_auto"
                        ),
                      },
                    }),
                  ]
                )
              : _vm._e(),
          ]),
        ])
      : _vm._e(),
    _vm.activeNav === 3
      ? _c("div", { staticClass: "nav-type" }, [
          _c("div", { staticClass: "box" }, [
            _vm.prefectureList[0]
              ? _c(
                  "div",
                  {
                    staticClass: "up",
                    on: {
                      click: function ($event) {
                        return _vm.clickPrefecture(_vm.prefectureList[0])
                      },
                    },
                  },
                  [
                    _c("img", {
                      attrs: {
                        src: _vm._f("ossProcess")(
                          _vm._f("setHttps")(_vm.prefectureList[0].img),
                          "style/w_240_h_auto"
                        ),
                      },
                    }),
                  ]
                )
              : _vm._e(),
            _vm.prefectureList[1]
              ? _c(
                  "div",
                  {
                    staticClass: "down",
                    on: {
                      click: function ($event) {
                        return _vm.clickPrefecture(_vm.prefectureList[1])
                      },
                    },
                  },
                  [
                    _c("img", {
                      attrs: {
                        src: _vm._f("ossProcess")(
                          _vm._f("setHttps")(_vm.prefectureList[1].img),
                          "style/w_240_h_auto"
                        ),
                      },
                    }),
                  ]
                )
              : _vm._e(),
          ]),
          _vm.prefectureList[2]
            ? _c(
                "div",
                {
                  staticClass: "box",
                  on: {
                    click: function ($event) {
                      return _vm.clickPrefecture(_vm.prefectureList[2])
                    },
                  },
                },
                [
                  _c("img", {
                    attrs: {
                      src: _vm._f("ossProcess")(
                        _vm._f("setHttps")(_vm.prefectureList[2].img),
                        "style/w_240_h_auto"
                      ),
                    },
                  }),
                ]
              )
            : _vm._e(),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }