var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "page" }, [
    _c("div", { staticClass: "comment-box" }, [
      _c("div", { staticClass: "title" }, [
        _c("div", { staticClass: "left" }, [
          _c("div", { staticClass: "headimg-box" }, [
            _c("img", { attrs: { src: _vm.userInfo.heagurl, alt: "headimg" } }),
          ]),
          _c("span", { staticClass: "nickname" }, [
            _vm._v(_vm._s(_vm.userInfo.nickname)),
          ]),
        ]),
        _c(
          "div",
          { staticClass: "rate-wrap" },
          [
            _c("yd-rate", {
              attrs: {
                readonly: true,
                "active-color": "#FF5252",
                size: "0.32rem",
              },
              model: {
                value: _vm.info.star,
                callback: function ($$v) {
                  _vm.$set(_vm.info, "star", $$v)
                },
                expression: "info.star",
              },
            }),
          ],
          1
        ),
      ]),
      _c("p", { staticClass: "comment-txt" }, [
        _vm._v(_vm._s(_vm.info.content)),
      ]),
      _vm.info.photo_list.length > 0
        ? _c(
            "div",
            { staticClass: "comment-pic-wrap" },
            [
              _c(
                "yd-lightbox",
                {
                  staticClass: "img-list",
                  attrs: { num: _vm.info.photo_list.length },
                },
                _vm._l(_vm.info.photo_list, function (i, k) {
                  return _vm.info.photo_list.length > 0
                    ? _c("yd-lightbox-img", {
                        key: k,
                        attrs: { src: i.img },
                        nativeOn: {
                          click: function ($event) {
                            $event.stopPropagation()
                          },
                        },
                      })
                    : _vm._e()
                }),
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }