<template>
  <div class="mall">
    <Search />
    <Banner />
    <div class="my-points">
      <div class="points-com">
        <img src="@/assets/icon_topic_points.png" alt="">
      </div>
      <p class="points-com space">我的商城积分</p>
      <p class="points-com">{{use_info.integral}}</p>
    </div>
    <yd-infinitescroll :callback="loadItemList" ref="infinitescrollDemo">
      <div class="item-list" slot="list">
        <item-list :itemList="itemList" @click-item="clickItem"></item-list>
         <!-- 空视图 -->
      <empty :icon="emptyIcon" v-if="itemList.length ===0 && emptyStatus"></empty>
      </div>

      <span slot="doneTip" v-show="itemList.length">- 没有更多了 -</span>
    </yd-infinitescroll>
  </div>
</template>

<script>
import Search from "@/components/index/Search";
import Banner from "@/components/index/Banner";
import ItemList from "@/components/list/haiItemList";
import empty from "@/components/empty/Empty";
import { getStore, setStore } from "@/utils/common";
import { getItemList } from "@/services";
import { mapState } from "vuex";
import { isWeiXin } from "@/utils/common.js";

export default {
  name: 'memberMall',
  data() {
    return {
      points: '1234456',
      itemList: [],
      pageSize: 10, // 每次请求数据量
      itemList: [], // 搜索结果
      emptyResult: false,
      page: 1
    }
  },
  props: {
    is_login: {  //奖品列表
      type: Boolean,
      default: false
    },
  },
  components: {
    Search,
    Banner,
    ItemList,
    empty
  },
  watch: {
    is_login(val) {
      if (val) {
        this.loadItemList()
      }     
    }
  },
  computed: {
    ...mapState({
      //用户信息
      use_info: state => state.userInfo,
    })
  },
  activated() {
    sessionStorage.route = this.$route.name;  
    // this.$store.dispatch("getUserInfo");
  },
  mounted() {
    // this.$nextTick(() => {
    //   this.loadItemList()
    // })
    // document.write(12, this.is_login)
    if (isWeiXin()) {
        this.loadItemList()
      }
  },
  methods: {

    async loadItemList() {
      try {
        let where = {};
        where.page = this.page;
        where.listRows = this.pageSize;
        const res = await getItemList(where);

        if (res.code === 0) {
          const { data, total } = res.data;
          this.itemList = [...this.itemList, ...data];
          this.emptyResult = true
          if (!this.itemList.length) return;
          if (this.itemList.length === total) {
            // 所有数据加载完毕
            this.$refs.infinitescrollDemo.$emit(
              "ydui.infinitescroll.loadedDone"
            );
            return;
          }
          // 单次请求数据完毕
          this.$refs.infinitescrollDemo.$emit("ydui.infinitescroll.finishLoad");

          this.page++;
        } else {
          this.$dialog.toast({
            mes: res.msg,
            timeout: 1500,
            icon: "error"
          });
        }
        this.emptyResult = !this.itemList.length;
      } catch (error) {
        console.warn("Something bad happened: ", error);
      }
    },

    // 点击商品
    clickItem(item) {
      let where = {};
      where.keyword = this.keyword;
      where.price_start = this.min_price;
      where.price_end = this.max_price;
      this.$store.commit("getSearchWhere", where);
      this.$router.push({
        name: "goods-detail",
        query: {
          item_id: item.id
        }
      });
    },
  }
}
</script>

<style lang="less" scoped>
@import "../../common/less/variable.less";
@import "../../common/less/mixin.less";

  .mall {
    min-height: 100vh;
    margin-bottom: 1.3rem;

    .my-points {
      height: 0.8rem;
      margin: 0 0.3rem;
      background-image: url('../../assets/icon_topic_back.png');
      background-size: 100% 100%;
      background-repeat: no-repeat;
      border-radius: 0.64rem;
      color: rgb(255, 255, 255);
      font-size: 14px;

      .points-com {
        float: left;
        height: 0.8rem;
        line-height: 0.8rem;
        margin-left: 0.2rem;
      }

      .space {
        letter-spacing: 0.02rem;
      }

      img {
        .wh(0.48rem, 0.48rem);
        margin: 0.16rem 0 0 0.12rem;
      }
    }

    .item-list {
      margin: 0 0.3rem;
    }
  }
</style>