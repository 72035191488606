var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "coupon" } },
    [
      _c(
        "router-link",
        { staticClass: "img-box", attrs: { to: "coupon_center", tag: "div" } },
        [_c("img", { attrs: { src: _vm.coupon, alt: "coupon" } })]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }