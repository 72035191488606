<template>
  <div v-if="!seaStatus" class="page" :style="backImg">
    <img
      v-if="businessInfo&&businessInfo.is_activity_rules_show"
      src="../../assets/activityicon.png"
      class="activityicon"
      @click="activityiconto"
    />
    <img
      v-if="businessInfo&&businessInfo.is_service_tel_show"
      src="../../assets/zhIndexIconKf.png"
      class="zhIndexIconKf"
      @click="zhIndexIconKfClick"
    />
    <img
      v-if="businessInfo&&businessInfo.is_order_show"
      src="../../assets/indexOrdericon.png"
      class="indexOrdericon"
      @click="indexiconto"
    />
    <HeadNavIcbc />
    <!-- <component :is="item.module" v-for="(item, index) in templateArr" :key="index"></component> -->
    <Scroller v-if="isTaben" :lists="lists" class="scrollContainer left" />
    <div v-for="(item, index) in templateArr" :key="index">
      <Search v-if="item.module === 'search'" />
      <Banner v-if="item.module === 'slider' && hackReset" />
      <NewCategory
        v-if="item.module === 'subject'"
        :config="item.config"
        :show="show"
      />
      <!-- <Category v-if="item.module === 'subject'" :show="show" /> -->
      <Coupon v-if="item.module === 'coupon'" />
      <Prefecture
        v-if="item.module === 'area'"
        :config="item.config"
        :show="show"
      />
      <Announcement v-if="item.module === 'notice'" />
      <Promotion v-if="item.module === 'promotion'" :config="item.config" />
      <SecondsKill v-if="item.module === 'seconds'" :brief="item.desc" />
      <GroupBuy v-if="item.module === 'group'" :brief="item.desc" />
      <SpecialTopic v-if="item.module === 'item'" />
    </div>

    <a
      v-if="guangdaStatus"
      class="custom-img"
      href="https://mp.weixin.qq.com/s/0VzlWb_q9MvIUZIBnqIC5w"
    >
      <img src="@/static/image/guangda.png" alt="guangda" />
    </a>

    <!-- S 猜你喜欢 -->
    <!-- <recommend-list class="mt20" :itemList="recommendList" @click-item="clickItem"></recommend-list> -->
    <!-- E 猜你喜欢 -->
    <!-- S 底部导航 -->
    <foot-nav :is_show="cart_show"></foot-nav>
    <!-- E 底部导航 -->
    <!--返回顶部-->
    <yd-backtop></yd-backtop>
    <!-- 关于我们 -->
    <p class="auto_info" v-if="zgyh_show" @click="toAuthInfo()">关于我们</p>
  </div>
  <div v-else class="member-mall">
    <!-- <div v-if="this.userInfo.business_id"> -->
    <member-mall :is_login="is_login"></member-mall>
    <yqt-foot></yqt-foot>
    <!-- </div> -->
  </div>
</template>

<script>
import Search from "@/components/index/Search";
import Banner from "@/components/index/Banner";
import NewCategory from "@/components/index/NewCategory";
// import Category from "@/components/index/Category";
import Coupon from "@/components/index/Coupon";
import Prefecture from "@/components/index/Prefecture";
import Promotion from "@/components/index/Promotion";
import Announcement from "@/components/index/Announcement";
import SecondsKill from "@/components/index/SecondsKill";
import GroupBuy from "@/components/index/GroupBuy";
import SpecialTopic from "@/components/index/SpecialTopic";
import RecommendList from "@/components/recommend/RecommendList";
import FootNav from "@/components/footer/FootNav.vue";
import Scroller from "@/components/index/Scroller";
import { scrollerMixin } from "@/mixins/scrollerMixin";
import {
  getModules,
  getItemList,
  getShareText,
  cashAndPointLogin,
  userInfoViaGeneralize
} from "@/services";
import { mapMutations, mapState } from "vuex";
import { getBusinessRate } from "@/services/my";
import { getColor, cebLogin, getCebParams } from "@/services/index";
import { bocLogin } from "@/services/user";
import {
  wxShare,
  setStore,
  getStore,
  is_ios,
  is_android,
  isWeiXin,
  getUrlKey
} from "../../utils/common";
import { ossProcess } from "../../utils/alioss";
import { tripartite, customAccount, CustomShop, terminal } from "@/utils/custom";
import HeadNavIcbc from "@/components/index/HeadNavIcbc.vue";
import MemberMall from "@/views/index/memberMall.vue";
import YqtFoot from "@/components/footer/yqt-footer.vue";

export default {
  name: "Index",
  components: {
    Search,
    Banner,
    NewCategory,
    Coupon,
    Prefecture,
    Promotion,
    Announcement,
    SecondsKill,
    GroupBuy,
    SpecialTopic,
    RecommendList,
    FootNav,
    Scroller,
    HeadNavIcbc,
    MemberMall,
    YqtFoot
  },
  mixins: [scrollerMixin],
  data() {
    return {
      backImg: {}, // 背景样式
      cart_show: true,
      templateArr: [], // 模板数组，具体展示的内容模块
      recommendList: [], // 猜你喜欢列表
      hackReset: false,
      huanjing: "",
      pop: false,
      // hys: "../../assets/yl.jpg",
      positionType: "",
      show: false,
      lists: ["该页面由工行南宁分行授权，壹企通提供技术支持"],
      isTaben: false, // 是否拓本商城
      guangdaStatus: false, // 是否光大商场
      pointsTopic: false,
      is_login: false,
      zgyh_show: false, //中信显隐控制
      businessInfo: {
        is_activity_rules_show: 0,
        is_service_tel_show: 0,
        is_order_show: 0
      }
    };
  },

  beforeRouteLeave: function(to, from, next) {
    if (to.name == "Login" || to.name == "login") {
      if (this.$vnode && this.$vnode.data.keepAlive) {
        if (
          this.$vnode.parent &&
          this.$vnode.parent.componentInstance &&
          this.$vnode.parent.componentInstance.cache
        ) {
          if (this.$vnode.componentOptions) {
            var key =
              this.$vnode.key == null
                ? this.$vnode.componentOptions.Ctor.cid +
                  (this.$vnode.componentOptions.tag
                    ? `::${this.$vnode.componentOptions.tag}`
                    : "")
                : this.$vnode.key;
            var cache = this.$vnode.parent.componentInstance.cache;
            var keys = this.$vnode.parent.componentInstance.keys;
            if (cache[key]) {
              if (keys.length) {
                var index = keys.indexOf(key);
                if (index > -1) {
                  keys.splice(index, 1);
                }
              }
              delete cache[key];
            }
          }
        }
      }
    }
    if (customAccount("VUE_APP_ZHONGHANG") && to.name == "exchange_rules") {
      to.meta.title = "活动攻略";
    }
    // this.$destroy();
    next();
  },
  computed: {
    ...mapState({
      //商家id
      business_id: state => state.businessInfo.id || "",
      userInfo: state => state.userInfo, //用户信息
      seaStatus: state => state.seaStatus, //  海元素定制商城
      tripartite_type: state => state.tripartite_type
    })
  },
  created() {
    console.log("status", this.seaStatus);
    this.getBusinessRate();
    this.huanjing = process.env.NODE_ENV;
    window.localStorage.setItem("getHomePath", window.location.href);
    window.localStorage.setItem("getShareTitle", business_name);
    this.getThemeColor();
    this.getShare();
    if (this.$route.query.generalize) {
      window.localStorage.setItem(
        "generalize_code",
        this.$route.query.generalize
      );
      this.getusers();
    } else {
      this.$store.commit({
        type: "getUserRegister",
        is_show: "0"
      });
      window.localStorage.removeItem("generalize_code");
    }
    this.businessInfo = JSON.parse(getStore("businessInfo"));
    console.log(this.businessInfo, "银行中行");
  },
  async mounted() {
    const business_id = sessionStorage.getItem("businessId");
    const user_id = sessionStorage.getItem("userId");
    if (business_id && user_id) {
      this.cashAndPointLogin(business_id, user_id);
    }
    this.getCebParams();
    // this.businessInfo =  JSON.parse(getStore("businessInfo"))
    // console.log(this.businessInfo,"银行中行")
  },
  //路由改变jssdk参数重新赋值
  beforeRouteEnter(to, from, next) {
    next();
    wxShare();
  },
  methods: {
    zhIndexIconKfClick(){
      window.location.href=`tel:${this.businessInfo.service_mobile}`;
    },
    activityiconto() {
      this.$router.push("/exchange_rules");
    },
    indexiconto() {
      this.$router.push("/order_list?key=ALL");
    },
    ...mapMutations(["getUser", "getSessionId"]),
    closepOp() {
      this.pop = false;
    },
    // 中行显隐控制
    isZh() {
      // let id = JSON.parse(getStore("businessInfo")).id;
      // if (Number(process.env.VUE_APP_JPKID) === id) {
      //   this.zgyh_show = true;
      //   return;
      // }
      if (process.env.VUE_APP_ZHONGHANG) {
        let bids = JSON.parse(process.env.VUE_APP_ZHONGHANG);
        bids.forEach(val => {
          if (val == JSON.parse(getStore("businessInfo")).id) {
            this.zgyh_show = true;
            return;
          }
        });
      }
    },
    // 跳转个人中心
    toAuthInfo() {
      this.$router.push({
        path: "/auth_info"
      });
    },
    //获取推广信息
    async getusers() {
      var generalize_code = "";
      if (this.$route.query.generalize) {
        generalize_code = this.$route.query.generalize;
      } else {
        generalize_code = window.localStorage.getItem("generalize_code") || "";
      }
      let business_id = this.business_id;
      const res = await userInfoViaGeneralize(generalize_code, business_id);
      if (res.code === 0) {
        this.$store.commit({
          type: "getUserRegister",
          generalize_id: res.data.user.id,
          business_id,
          is_show: res.data.data.is_show,
          nickname: res.data.user.nickname || ""
        });
      } else {
        this.show_layer("cancel", res.error_msg);
      }
    },

    //获取分享描述
    async getShare() {
      const share = await getShareText();
      window.localStorage.setItem("share_text", share.data.share_text || "");
    },

    async getUserInfo() {
      try {
        await this.$store.dispatch("getUserInfo");
        this.loadData();
      } catch (e) {}
    },

    // 获取数据
    async loadData() {
      try {
        const res = await getModules(this.business_id);
        if (res.code === 0) {
          this.templateArr = res.data;
          this.positionType = res.data.length;
          this.guangdaStatus = tripartite("VUE_APP_GUANGDA");
        } else {
          this.$dialog.toast({
            mes: res.msg,
            timeout: 1500,
            icon: "error"
          });
        }
      } catch (error) {
        console.warn(error);
      }
    },

    // 获取猜你喜欢
    async getRecommendList() {
      try {
        let where = {};
        where.order = "sales";
        // where.page = this.page;
        // where.listRows = this.pageSize;
        const res = await getItemList(where);
        if (res.code === 0) {
          const { data, total } = res.data;
          this.recommendList = [...this.recommendList, ...data];

          /* if (this.recommendList.length === total) {
            // 所有数据加载完毕
            this.$refs.infinitescrollDemo.$emit("ydui.infinitescroll.loadedDone");
            return;
          }
          单次请求数据完毕
          this.$refs.infinitescrollDemo.$emit("ydui.infinitescroll.finishLoad");
          this.page++; */
        } else {
          this.$dialog.toast({
            mes: res.msg,
            timeout: 1500,
            icon: "error"
          });
        }
      } catch (error) {
        console.warn("Something bad happened: ", error);
      }
    },

    // 点击商品
    clickItem(item) {
      this.$router.push({
        name: "goods-detail",
        query: {
          item_id: item.item_id || item.id
        }
      });
    },

    async getBusinessRate() {
      try {
        const res = await getBusinessRate();
        if (res.code === 0) {
          const data = res.data;
          // console.log(data);
          this.business_id = data.id;
          setStore("businessInfo", data);
          this.cart_show = data.show_item_sn == 1 ? false : true;
          if (customAccount("VUE_APP_POINTS_TOPIC")) {
            this.pointsTopic = true;
            // console.log('12', this.pointsTopic)
          }
          this.guangdaStatus = tripartite("VUE_APP_GUANGDALOGIN");
          if (isWeiXin()) {
            if (
              !this.userInfo.business_id &&
              this.pointsTopic &&
              this.guangdaStatus
            ) {
              this.$router.push("/login");
            }
          } else {
            if (
              !this.userInfo.business_id &&
              this.pointsTopic &&
              !this.guangdaStatus
            ) {
              this.$router.push("/login");
            }
          }

          // this.tabenStatus();
          this.loadData();
        }
      } catch (e) {
        throw e;
      }
    },

    async cashAndPointLogin(item1, item2) {
      let params = {
        business_id: item1,
        user_id: item2
      };
      try {
        const res = await cashAndPointLogin(params);
        if (res.code === 0) {
          const { PHPSESSID, user } = res.data;
          SID = PHPSESSID;
          this.getSessionId(PHPSESSID);
          this.$store.dispatch("getUserInfo");
          await this.getUser(user);
          // await this.getThemeColor();
          try {
            const data = await this.$store.dispatch("getBusinessRate");
            if (JSON.stringify(data) !== "{}") {
              /* let goRoute = sessionStorage.route;
              console.log(goRoute);
              if (goRoute) {
                this.$router.replace(goRoute);
              } else {
                this.$router.replace("home");
              } */
              console.log("我进行到这里了");
            }
          } catch (e) {}
        }
        sessionStorage.removeItem("businessId");
        sessionStorage.removeItem("userId");
      } catch (e) {}
    },

    // 白色背景色，无背景图时搜索框边框处理
    dealBorder(data) {
      const { home_bg_color, home_bg_img } = data;
      if (home_bg_color === "#ffffff" || home_bg_color === "#FFFFFF") {
        // 白色背景色
        if (!home_bg_img) {
          localStorage.borderStatus = true;
        } else {
          localStorage.borderStatus = false;
        }
      } else {
        // 没有背景颜色或者其他背景颜色
        if (home_bg_color) {
          localStorage.borderStatus = false;
        } else {
          localStorage.borderStatus = true;
        }
      }
    },

    async getThemeColor() {
      try {
        const res = await getColor(this.$store.state.userInfo.business_id);
        this.img_request = true;
        if (res.code === 0) {
          if (res.data.home_bg_img) {
            res.data.home_bg_img = ossProcess(
              res.data.home_bg_img,
              "style/w_480_h_auto"
            )
          }
          const {
            color,
            login_img,
            login_text,
            home_bg_color,
            home_bg_img
          } = res.data;
          this.dealBorder(res.data);
          if (home_bg_color || home_bg_img) {
            this.backImg.background = `${home_bg_color} url(${home_bg_img}) no-repeat 0 0/100%`;
          }
          // 没有设置背景色背景图时，默认背景色为#FFFFFF
          if (!home_bg_color && !home_bg_img) {
            this.backImg.backgroundColor = "#FFFFFF";
          }
          this.login_img = login_img;
          this.login_text = login_text;
          this.txtColor = color;
          document.body.style.setProperty("--main-color", color);
          this.$forceUpdate();
        }
      } catch (error) {
        console.warn("Something bad happened in api getColor: ", error);
      }
    },

    // guangdaLogin() {
    //   this.guangdaStatus = tripartite('VUE_APP_GUANGDALOGIN')
    //   let params = {
    //     appid: '0d17557f-7c68-4f22-9d41-b31c588328b5',
    //     merId: '2001',
    //     scope: '1 3 5',
    //     callbackName: 'loginCallback',
    //     state: JSON.stringify(Date.parse(new Date()))
    //   }
    //   if (this.guangdaStatus) {
    //     this.getCebParams()
    //     window.loginCallback = this.loginCallback
    //     if (is_android()) {
    //       window.jsInterface.getUserAuthCode(JSON.stringify(params))
    //     } else if (is_ios()) {
    //       window.webkit.messageHandlers.getUserAuthCode.postMessage(JSON.stringify(params))
    //     }
    //   }
    // },

    loginCallback(code) {
      if (code) {
        this.cebLogin(code);
      } else {
        this.dialogCon("授权失败！");
      }
      // this.cebLogin(code)
      // document.write(123, code)
    },

    async cebLogin(code) {
      try {
        let params = {
          code: code
        };
        const res = await cebLogin(params);
        if (res.code === 0) {
          this.is_login = true;
          this.$store.dispatch("getUserInfo");
          // this.dialogCon(res.msg)
          this.$dialog.toast({
            mes: res.msg
          });
        } else {
          this.dialogCon(res.msg);
        }
      } catch (e) {}
    },

    dialogCon(val) {
      this.$dialog.confirm({
        title: "提示",
        mes: val,
        opts: [
          {
            txt: "确定",
            color: "#31BDDF",
            callback: async () => {
              if (is_android()) {
                window.jsInterface.close();
              } else if (is_ios()) {
                window.webkit.messageHandlers.close.postMessage("");
              }
            }
          }
        ]
      });
    },

    async getCebParams() {
      try {
        const res = await getCebParams();
        if (res.code === 0) {
          let params = {
            appid: res.data.appid,
            merId: res.data.merid,
            scope: "1 3 5",
            callbackName: "loginCallback",
            state: JSON.stringify(Date.parse(new Date()))
          };
          this.guangdaStatus = tripartite("VUE_APP_GUANGDALOGIN");
          if (this.guangdaStatus) {
            window.loginCallback = this.loginCallback;
            if (is_android()) {
              window.jsInterface.getUserAuthCode(JSON.stringify(params));
            } else if (is_ios()) {
              window.webkit.messageHandlers.getUserAuthCode.postMessage(
                JSON.stringify(params)
              );
            }
          }
        } else {
          this.dialogCon(res.msg);
        }
      } catch {}
    },

    /* 广西拓本商城判断
		tabenStatus() {
			if (tripartite('VUE_APP_TABEN')) {
				this.isTaben = true;
				const userInfo = getStore('userInfo');
				console.log({userInfo})
				if (!userInfo) {
					this.$router.replace('tabenLogin');
				}
			}
    } */

    // 中行用户数据
    async bocLogin(data) {
      try {
        const res = await bocLogin(data);
        console.log(res);
        if (res.code === 0) {
          const { PHPSESSID, user } = res.data;
          const show = res.data.show;
          if (show) {
            sessionStorage.setItem("show", show);
          } else {
            sessionStorage.setItem("show", "");
          }
          SID = PHPSESSID;
          this.getSessionId(PHPSESSID);
          this.$store.dispatch("getUserInfo");
          await this.getUser(user);
          await this.getThemeColor();
          try {
            const data = await this.$store.dispatch("getBusinessRate");
            this.$dialog.loading.close();
            // if (JSON.stringify(data) !== '{}') {
            //   this.$router.replace(sessionStorage.route || 'home');
            // }
          } catch (e) {}
        } else {
          alert(res.msg);
        }
      } catch (e) {}
    },

    // (中行 && 江阴)登录判断
    async bankChinaLogin() {
      let $this = this;
      if (!this.business_id) {
        await this.$store.dispatch("getBusinessRate");
      }
      const Custom = new CustomShop(this.business_id);
      if (Custom.backName() === "VUE_APP_ZHONGHANG" && terminal().bocApp) {
        this.$dialog.loading.open("加载中...");
        // 中行登录获取用户信息
        document.addEventListener("deviceready", function() {
          // “yes”登录;“no”未登录
          window.c_plugins.merchantBridge.getBocCustomerAllInfo(function(data) {
            if (data.cipherText) {
              $this.bocLogin(data.cipherText);
            } else {
              this.$dialog.toast({
                mes: "未获取到用户信息",
                timeout: 1500,
                icon: "error"
              });
            }
          });
        });
      }
    }
  },
  activated() {
    const show = sessionStorage.getItem("show")
      ? sessionStorage.getItem("show")
      : "";
    if (Number(show)) {
      this.show = true;
      console.log(this.show, "show");
    } else {
      this.show = false;
      console.log(this.show, "show");
    }
    document.title = business_name;
    this.hackReset = false;

    this.$nextTick(() => {
      if (this.business_id) {
        this.loadData();
        this.hackReset = true;
      } else {
        this.hackReset = true;
      }
      // this.bankChinaLogin();
    });
    this.getBusinessRate();
    // this.getRecommendList();
    this.isZh();
  }
  /*deactivated() {
    this.templateArr = [];
    this.recommendList = []
  },*/
};
</script>

<style lang="less" scoped>
.page {
  .zhIndexIconKf {
    position: fixed;
    z-index: 99;
    width: 70px;
    height: 27px;
    right: 0;
    margin-top: 150px;
  }
  .activityicon {
    position: fixed;
    z-index: 99;
    width: 70px;
    height: 27px;
    right: 0;
    margin-top: 70px;
  }
  .indexOrdericon {
    position: fixed;
    z-index: 99;
    width: 70px;
    height: 75px;
    right: 0;
    margin-top: 470px;
  }
  padding-bottom: 0.98rem;
  position: absolute;
  width: 100%;
  .to-shop {
    width: 100%;
    height: 0.8rem;
    background: yellow;
    text-align: center;
    line-height: 0.8rem;
  }

  .custom-img {
    display: block;
    width: 100%;
    // height: 1rem;
    margin: 0.1rem 0;
    padding: 0 0.3rem;
  }
}

.mt20 {
  margin-top: 0.4rem;
}

.zh_zzc {
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999999;
  background: #ffffff;
  opacity: 0;
  // opacity: 0;
}

.auto_info {
  text-align: center;
  margin: 0.2rem auto;
  text-decoration: underline;
}
</style>
