var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { id: "group-buy" } }, [
    _c("h3", { staticClass: "title" }, [_vm._v(_vm._s(_vm.title))]),
    _c("div", { staticClass: "cell" }, [
      _c("span", { staticClass: "left" }, [
        _c("span", { staticClass: "brief" }, [_vm._v(_vm._s(_vm.brief))]),
      ]),
      _c(
        "span",
        {
          staticClass: "right",
          on: {
            click: function ($event) {
              $event.preventDefault()
              return _vm.clickMore.apply(null, arguments)
            },
          },
        },
        [_c("span", { staticClass: "btn check-btn" }, [_vm._v("查看更多")])]
      ),
      _c("i", {
        staticClass: "icon icon-arrow-right",
        on: {
          click: function ($event) {
            $event.preventDefault()
            return _vm.clickMore.apply(null, arguments)
          },
        },
      }),
    ]),
    _c(
      "ul",
      { staticClass: "item-list" },
      _vm._l(_vm.groupList, function (item, index) {
        return _c(
          "li",
          {
            key: index,
            staticClass: "item",
            on: {
              click: function ($event) {
                return _vm.clickGroup(item)
              },
            },
          },
          [
            _c(
              "div",
              { staticClass: "img-box" },
              [
                _c("yqt-image", {
                  staticClass: "img",
                  attrs: {
                    src: item.base_pic,
                    lazyload: "",
                    "loading-img": _vm.loadingImg,
                    fit: "cover",
                    alt: "item pic",
                  },
                }),
              ],
              1
            ),
            _c("p", { staticClass: "item-name" }, [
              _vm._v(_vm._s(item.item_name)),
            ]),
            _vm.businessType == 2
              ? _c("p", { staticClass: "item-price" }, [
                  _vm.price_status == 1
                    ? _c("span", { staticClass: "price" }, [
                        _c("span", { staticClass: "num" }, [
                          _vm._v(
                            "\n            " +
                              _vm._s(
                                _vm.priceShow(
                                  item.display_type,
                                  item.group_buy_price
                                )
                              ) +
                              "\n          "
                          ),
                        ]),
                      ])
                    : _vm._e(),
                ])
              : _c("p", { staticClass: "item-price" }, [
                  _vm.price_status == 1
                    ? _c("span", { staticClass: "price" }, [
                        _c("span", { staticClass: "sign" }, [_vm._v("¥")]),
                        _c("span", { staticClass: "num" }, [
                          _vm._v(_vm._s(item.group_buy_price)),
                        ]),
                      ])
                    : _vm._e(),
                ]),
            _c("span", { staticClass: "btn buy-btn" }, [_vm._v("拼团")]),
            _c(
              "div",
              { staticClass: "group-num-wrap" },
              [
                _c(
                  "yd-badge",
                  { attrs: { bgcolor: "#FE552E", color: "#FFF" } },
                  [
                    _c("span", { staticClass: "group-num" }, [
                      _vm._v(_vm._s(item.people_limit) + "人成团"),
                    ]),
                  ]
                ),
              ],
              1
            ),
          ]
        )
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }