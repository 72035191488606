<template>
  <div class="page">
    <yd-infinitescroll :callback="loadList" ref="infinitescrollDemo">
      <ul slot="list" class="comment-list">
        <li
            class="comment-item"
            v-for="(item, index) in list"
            :key="index"
            @click.stop="clickComment(item)"
        >
          <div class="headimg-box">
            <img :src="item.heagurl" alt="headimg">
          </div>
          <div class="headimg-right">
            <div class="title">
              <span class="nickname">{{item.nickname}}</span>
              <div class="rate-wrap">
                <yd-rate v-model="item.star" :readonly="true" active-color="#FF5252" size="0.2rem"></yd-rate>
                <span class="time">{{item.create_time | formatDate}}</span>
              </div>
            </div>
            <p class="comment-txt">{{item.content}}</p>
            <div class="comment-pic-wrap" v-if="item.photo_list.length">
              <yd-lightbox class="img-list" :num="item.photo_list.length">
                <yd-lightbox-img
                    v-for="(i, k) in item.photo_list"
                    :key="k"
                    :src="i.img"
                    @click.native.stop
                ></yd-lightbox-img>
              </yd-lightbox>
            </div>
          </div>
        </li>
      </ul>
      <!-- 数据全部加载完毕显示 -->
      <span slot="doneTip">- 没有更多了 -</span>
    </yd-infinitescroll>

    <yd-backtop></yd-backtop>

    <!-- S 列表为空 -->
    <empty v-if="emptyList"></empty>
    <!-- E 列表为空 -->
  </div>
</template>

<script>
import Empty from '@/components/empty/Empty';

import {getEvaluateList} from '@/services';
export default {
  name: "ItemCommentList",
  components: {
    Empty
  },
  data() {
    return {
      page: 1, // 开始查询的下标
      pageSize: 10, // 每次查询的数量
      list: [], // 评论列表
      emptyList: false // 是否为空
    };
  },
  created() {
    this.loadList();
  },
  methods: {
    // 加载数据
    async loadList() {
      try {
        const res = await getEvaluateList(this.$route.query.item_id, this.page, this.pageSize);
        if (res.code === 0) {
          const {data, total} = res.data;
          this.list = [...this.list, ...data];
          this.emptyList = !this.list.length;
          
          if (this.list.length === total) {
            // 所有数据加载完毕
          this.$refs.infinitescrollDemo.$emit("ydui.infinitescroll.loadedDone");
          return;
          }
          // 单次请求数据完毕
        this.$refs.infinitescrollDemo.$emit("ydui.infinitescroll.finishLoad");

        this.page++;
        } else {
          this.$dialog.toast({
            mes: res.msg,
            timeout: 1500,
            icon: "error"
          });
        }
        this.emptyList = !this.list.length;
      } catch (error) {
        console.warn("Something bad happened: ", error);
      }
    },
    // 点击商品评论，跳转商品评论详情
    clickComment(item) {
      this.$router.push({
        name: "comment_details",
        query: {
          info: JSON.stringify(item)
        }
      });
    }
  }
};
</script>

<style lang="less" scoped>
@import "../../../common/less/variable.less";
@import "../../../common/less/mixin.less";

.page {
  .bj(#fff);

  & /deep/ .comment-list {
    padding: 0.3rem;

    .comment-item {
      display: flex;
      width: 100%;

      .headimg-box {
        .wh(0.7rem, 0.7rem);
        margin-right: 0.14rem;
        border-radius: 50%;
        overflow: hidden;

        img {
          .wh(100%, 100%);
        }
      }

      .headimg-right {
        width: calc(100% - 0.84rem);

        .title {
          position: relative;
          .fj;
          align-items: center;

          .nickname {
            max-width: 60%;
            .sc(@fontsize-medium, @color-dark-grey);
            line-height: 0.4rem;
            word-wrap: break-word;
            word-break: break-all;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }

          .rate-wrap {
            text-align: right;

            .time {
              margin-right: .05rem;
              .sc(@fontsize-small, @color-light-grey);
              line-height: 0.34rem;
              white-space: nowrap;
            }
          }
        }

        .comment-txt {
          padding-top: 0.2rem;
          padding-bottom: 0.2rem;
          .sc(@fontsize-medium, @color-dark-grey);
          line-height: 0.4rem;
          word-wrap: break-word;
          word-break: break-all;
        }

        .comment-pic-wrap {
          width: 100%;

          & /deep/ .img-list {
            display: flex;

            img {
              .wh(1.9rem, 1.9rem);

              & + img {
                margin-left: 0.24rem;
              }
            }
          }
        }
      }

      & + .comment-item {
        margin-top: 0.3rem;
      }
    }
  }
}
</style>
