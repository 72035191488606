<template>
  <div class="head_nav" v-show="showStues">
    <yd-navbar>
      <div class="home-icon" @click.stop="goBack()" slot="left">
        <img src="~@/assets/icon_go_page@2x.png" alt class="icon_img" />
      </div>
    </yd-navbar>
    <p class="blank"></p>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { isWeiXin } from "@/utils/common.js";
import { hybridAppBack } from "@/utils/hybrid_app.js";
import { wxShare } from "../../utils/common";

export default {
  name: "head_nav_icbc",
  data() {
    return {
      showStues: false,
      closeBid: [2353]
    };
  },
  computed: {
    ...mapState({
      userInfo: state => state.userInfo, //用户信息
      businessInfo: state => state.businessInfo
    })
  },
  watch: {
    "businessInfo.id": {
      handler(newName) {
        console.log(newName, "newName");
        if (newName == this.closeBid && !isWeiXin()) {
          this.showStues = true;
        } else {
          this.showStues = false;
        }
      },
      immediate: true
    }
  },
  methods: {
    goBack() {
      hybridAppBack();
    }
  }
};
</script>
<style lang="less" scoped>
@import "../../assets/iconfont/iconfont.css";
@import "../../common/less/variable.less";
@import "../../common/less/mixin.less";

.head_nav {
  .yd-navbar {
    position: fixed;
    top: 0;
    z-index: 9;
    width: 100%;
    .icon_img {
      width: 0.48rem;
      height: 0.48rem;
    }
    .grey {
      color: #b3b3b3;
      margin-top: 0.06rem;
    }
  }
  .blank {
    height: 0.88rem;
  }
}
</style>
