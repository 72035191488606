<template>
  <div class="page">
    <!-- S 专题列表 -->
    <yd-infinitescroll :callback="loadItemList" ref="pullItemList">
      <div class="search-result-wrap" slot="list">
        <HeadNav />
        <Search class="search-tab" />
        <div class="search-tab">
          <ul class="tab-list">
            <li
              v-for="(item, index) in tabs"
              :key="index"
              :class="{ active: activeTab === index }"
              @click="clickTab(index)"
            >
              <span class="txt">{{ item }}</span>
              <i
                class="icon icon-price"
                v-if="index === 2"
                :class="{
                  rise: activeTab === index && priceToggle,
                  drop: activeTab === index && !priceToggle,
                }"
              ></i>
            </li>
          </ul>
          <div class="btn toggle-btn flex-center" @click="toggleStyle">
            <i class="icon icon-col" :class="{ row: !!rowToggle }"></i>
          </div>
        </div>
        <!-- / 筛选的tab -->

        <div class="search-result" v-if="!rowToggle">
          <item-list :itemList="itemList" @click-item="clickItem"></item-list>
        </div>
        <!-- / 专题列表：两列的样式 -->

        <!-- / 专题列表: 一列的样式 -->
        <div class="search-result" v-else>
          <row-item-list
            :itemList="itemList"
            @click-item="clickItem"
          ></row-item-list>
        </div>
      </div>

      <!-- 数据全部加载完毕显示 -->
      <span slot="doneTip">- 没有更多了 -</span>
    </yd-infinitescroll>
    <!-- E 专题列表 -->

    <yd-backtop></yd-backtop>

    <!-- S 列表为空 -->
    <empty v-if="emptyList"></empty>
    <!-- E 列表为空 -->
  </div>
</template>

<script>
// import Test1 from "@/assets/img_home_fangjian01@3x.png";
// import Test2 from "@/assets/img_home_tuangou02@3x.png";
import ItemList from "@/components/list/ItemList";
import RowItemList from "@/components/list/RowItemList";
import Empty from "@/components/empty/Empty";
import Search from "@/components/index/Search";
import { getItemList } from "@/services";
import { wxShare } from "@/utils/common";
import { zjgKeepAlive } from "@/utils/custom";
import HeadNav from "@/components/common/HeadNav";

export default {
  name: "SpecialTopic",
  components: {
    ItemList,
    Search,
    RowItemList,
    Empty,
    HeadNav,
  },
  inject: ["reload"],
  data() {
    return {
      page: 1, // 初始页
      pageSize: 10, // 每次请求数据量
      itemList: [], // 搜索结果
      emptyList: false, // 是否为空
      tabs: ["综合", "销量", "价格"], // tab
      activeTab: 0, // 挡墙选择tab下标
      priceToggle: false, // 价格切换，默认false,升序
      rowToggle: false, // 样式切换， 默认false,显示两列
    };
  },
  methods: {
    // 加载数据
    async loadItemList() {
      try {
        let where = {};

        if (this.$route.query.subject_id) {
          where.subject_id = this.$route.query.subject_id;
        }
        if (this.$route.query.category_id) {
          where.category_id = this.$route.query.category_id;
        }

        if (this.activeTab === 0) {
          where.order = "merge";
        } else if (this.activeTab === 1) {
          where.order = "sales";
        } else if (this.activeTab === 2 && !this.priceToggle) {
          where.order = "price_desc";
        } else if (this.activeTab === 2 && this.priceToggle) {
          where.order = "price_asc";
        }
        where.page = this.page;
        where.listRows = this.pageSize;
        const res = await getItemList(where);
        if (res.code === 0) {
          const { data, total } = res.data;
          this.itemList = [...this.itemList, ...data];
          this.emptyList = !this.itemList.length;

          if (this.itemList.length === total) {
            // 所有数据加载完毕
            this.$refs.pullItemList.$emit("ydui.infinitescroll.loadedDone");
            return;
          }
          // 单次请求数据完毕
          this.page++;
          this.$refs.pullItemList.$emit("ydui.infinitescroll.finishLoad");
        } else {
          this.$dialog.toast({
            mes: res.msg,
            timeout: 1500,
            icon: "error",
          });
        }
        this.emptyList = !this.itemList.length;
      } catch (error) {
        console.warn("Something bad happened: ", error);
      }
    },
    // 点击商品
    clickItem(item) {
      this.$router.push({
        name: "goods-detail",
        query: {
          item_id: item.item_id || item.id,
        },
      });
    },
    // 点击tab
    clickTab(index) {
      zjgKeepAlive();

      this.activeTab = index;
      if (this.activeTab === 2) {
        this.priceToggle = !this.priceToggle;
      }
      this.page = 1;
      this.itemList = [];
      this.$refs.pullItemList.$emit("ydui.infinitescroll.reInit");
      this.loadItemList();
    },
    // 切换样式
    toggleStyle() {
      this.rowToggle = !this.rowToggle;
    },
  },
  activated() {
    this.name = this.$route.query.name ? this.$route.query.name : "";
    if (this.name) document.title = this.name;
    // this.loadItemList()
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (from.name !== "goods-detail") {
        // vm.reload();
        vm.page = 1;
        vm.itemList = [];
        vm.activeTab = 0;
        vm.$refs.pullItemList.$emit("ydui.infinitescroll.reInit");
        vm.loadItemList();
      }
      vm.$store.commit({
        type: "getParameterId",
        subject_id: to.query.subject_id,
        category_id: to.query.category_id,
      });
    });
    wxShare();
  },
  beforeRouteLeave(to, from, next) {
    this.$store.commit({
      type: "getParameterId",
      subject_id: "",
      category_id: "",
    });
    next();
  },
};
</script>

<style lang="less" scoped>
@import "../../common/less/variable.less";
@import "../../common/less/mixin.less";

.page {
  /deep/ .search-result-wrap {
    .search-tab {
      position: relative;
      display: flex;
      align-items: center;
      .wh(100%, 0.88rem);
      padding-right: 1.2rem;
      background-color: #fff;
      overflow: hidden;

      .tab-list {
        position: relative;
        width: 100%;
        overflow: hidden;

        li {
          width: 30%;
          float: left;
          text-align: center;
          position: relative;
          z-index: 0;

          .txt {
            position: relative;
            .sc(0.28rem, @color-light-grey);
            line-height: 0.4rem;
            letter-spacing: 0.01rem;
          }

          .icon-price {
            display: inline-block;
            .wh(0.18rem, 0.2rem);
            margin-left: 0.04rem;
            .bis("../../assets/icon_jiage");
          }

          &.active {
            .txt {
              color: @color-dark-grey;
              font-weight: 500;

              &:after {
                content: "";
                .cl;
                bottom: -0.04rem;
                .wh(0.5rem, 0.04rem);
                background-color: var(--main-color);
                border-radius: 0.06rem;
              }
            }
          }
        }
      }

      .toggle-btn {
        .ct;
        right: 0;
        .wh(1.2rem, 0.88rem);
        z-index: 2;

        .icon-col {
          .wh(0.4rem, 0.4rem);
          .bis("../../assets/icon_sousuo_qiehuan");

          &.row {
            .bis("../../assets/ic_list_qiehuan");
          }
        }
      }
    }

    .search-result {
      padding: 0 0.3rem 0.3rem 0.3rem;
      background-color: @color-background-grey;

      /deep/ .row-item-list {
        padding-top: 0.3rem;
      }
    }
  }
}
</style>
