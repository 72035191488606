<template>
  <div class="page">
    <div class="comment-box">
      <div class="title">
        <div class="left">
          <div class="headimg-box">
            <img :src="userInfo.heagurl" alt="headimg">
          </div>
          <span class="nickname">{{userInfo.nickname}}</span>
        </div>
        <div class="rate-wrap">
          <yd-rate v-model="info.star" :readonly="true" active-color="#FF5252" size="0.32rem"></yd-rate>
        </div>
      </div>
      <p class="comment-txt">{{info.content}}</p>
      <div class="comment-pic-wrap" v-if="info.photo_list.length>0">
        <yd-lightbox class="img-list" :num="info.photo_list.length">
          <yd-lightbox-img v-if="info.photo_list.length>0"  v-for="(i, k) in info.photo_list" :key="k" :src="i.img" @click.native.stop></yd-lightbox-img>
        </yd-lightbox>
      </div>
    </div>
  </div>
</template>

<script>
import {getEvaluateInfo} from '@/services/orderApi'
import {mapState} from 'vuex'

export default {
  name: "CommentDetails",
  data() {
    return {
      info: {

      }, // 评价信息
      order_id:this.$route.query.order_id,
    };
  },
  computed: {
    ...mapState({
      userInfo: state => state.userInfo,
    })
  },
  methods:{
    async getEvaluateInfo(){
      try {
        const res = await getEvaluateInfo(this.order_id);
        if (res.code === 0) {
          const data = res.data;
          this.info = data
        }
      }catch (e) {

      }
    },
  },
  mounted() {
    if(this.$route.query.order_id)
      this.getEvaluateInfo()
  }
};
</script>

<style lang="less" scoped>
@import "../../../common/less/variable.less";
@import "../../../common/less/mixin.less";

.page {
  .bj(#fff);
  padding: 0.3rem;

  .comment-box {
    width: 100%;
    padding: 0.2rem;
    background-color: #fff;
    box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.05);

    .title {
      position: relative;
      .fj;
      align-items: center;

      .left {
        flex: 1;
        display: flex;
        align-items: center;

        .headimg-box {
          .wh(0.48rem, 0.48rem);
          margin-right: 0.14rem;
          border-radius: 50%;
          overflow: hidden;

          img {
            .wh(100%, 100%);
          }
        }
      }

      .nickname {
        max-width: 60%;
        .sc(@fontsize-small, @color-light-grey);
        line-height: 0.34rem;
        word-wrap: break-word;
        word-break: break-all;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .rate-wrap {
        text-align: right;
      }
    }

    .comment-txt {
      padding-top: 0.2rem;
      padding-bottom: 0.2rem;
      .sc(@fontsize-small, @color-dark-grey);
      line-height: 0.34rem;
      word-wrap: break-word;
      word-break: break-all;
    }

    .comment-pic-wrap {
      width: 100%;

      & /deep/ .img-list {
        display: flex;

        img {
          .wh(1.2rem, 1.2rem);

          & + img {
            margin-left: 0.24rem;
          }
        }
      }
    }
  }
}
</style>
