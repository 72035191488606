<template>
  <div id="announcement">
    <div class="announcement-cell">
      <img
        :src="iconAnnouncement"
        alt="icon"
        class="icon icon-announcement"
        @click="clickIcon"
      />
      <yd-rollnotice autoplay="2000">
        <yd-rollnotice-item> </yd-rollnotice-item>
        <yd-rollnotice-item
          v-for="(item, index) in announcementList"
          :key="index"
          @click.native="clickAnnouncement(item)"
          class="dom"
        >
          <span class="announcement-content">{{ item.title }}</span>
        </yd-rollnotice-item>
      </yd-rollnotice>
    </div>
  </div>
</template>

<script>
import IconAnnouncement from "@/assets/img_mallreport@3x.png";
import { getNotice } from "@/services";
export default {
  name: "Announcement",
  data() {
    return {
      iconAnnouncement: IconAnnouncement, // 图标
      announcementList: [] // 公告数据
    };
  },
  created() {
    this.loadData();
  },
  methods: {
    // 获取数据
    async loadData() {
      try {
        const res = await getNotice(this.$store.state.userInfo.business_id);
        if (res.code === 0) {
          this.announcementList = res.data;
          let dom = document.getElementsByClassName("yd-rollnotice-item")[0];
          dom.parentNode.removeChild(dom);
        } else {
          this.$dialog.toast({
            mes: res.msg,
            timeout: 1500,
            icon: "error"
          });
        }
      } catch (error) {
        console.warn(error);
      }
    },
    // 点击图标
    clickIcon() {
      this.$router.push("announcement_list");
    },
    // 点击公告
    clickAnnouncement(item) {
      this.$router.push({
        name: "announcement_details",
        query: {
          id: item.id
        }
      });
    }
  }
};
</script>

<style lang="less" scoped>
@import "../../common/less/variable.less";
@import "../../common/less/mixin.less";

#announcement {
  .announcement-cell {
    position: relative;
    display: flex;
    align-items: center;
    height: 0.76rem;
    padding-left: 1.76rem;
    // background-color: #fff;

    .icon {
      .ct;
      left: 0.3rem;
      .wh(1.16rem, 0.38rem);
      background-color: var(--main-color);
      z-index: 1;
    }
  }

  .announcement-content {
    width: 100%;
    .sc(0.26rem, @color-grey);
    line-height: 0.32rem;
    letter-spacing: 0.01rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  /deep/.yd-rollnotice {
    background-color: transparent;
  }
}
</style>
