var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "nav-container" },
    [
      _c(
        "router-link",
        { staticClass: "nav-item flex-center", attrs: { to: "home" } },
        [
          _vm.$route.name === "home"
            ? _c("icon-svg", {
                staticClass: "main-color",
                attrs: { "icon-class": "yi_ic_home_blue_" },
              })
            : _c("icon-svg", {
                staticClass: "grey",
                attrs: { "icon-class": "yi_ic_home_grey_" },
              }),
          _c("span", { staticClass: "nav-txt" }, [_vm._v("会员商城")]),
        ],
        1
      ),
      _c(
        "router-link",
        { staticClass: "nav-item flex-center", attrs: { to: "special_my" } },
        [
          _vm.$route.name === "special_my"
            ? _c("icon-svg", {
                staticClass: "main-color",
                attrs: { "icon-class": "yi_ic_my_blue_" },
              })
            : _c("icon-svg", {
                staticClass: "grey",
                attrs: { "icon-class": "yi_ic_my_grey_" },
              }),
          _c("span", { staticClass: "nav-txt" }, [_vm._v("个人中心")]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }