<template>
  <ul class="coupon-list">
    <li
        class="coupon-item"
        :class="[{old: item.status === 4 || item.status === 5 || item.status === 3 || item.status === 6}, { view: item.status === 6 }]"
        v-for="(item, index) in list"
        :key="index"
    >
      <template v-if="businessType == 2">
        <span class="value">
          <!-- <span class="num">{{(parseFloat(item.amount || item.reduce) * integralRate).toFixed(2)}}</span> -->
          <span class="num">{{ item.amount || item.reduce }}</span>
		  <span class="sign">积分</span>
        </span>
        <div class="content">
          <p class="type">{{item.title}}</p>
          <!-- <p class="valid" v-if="!is_start">领取后{{item.day}}天内有效</p>
          <p class="valid" v-else>
            开始：{{item.start_time|formatDateStdCh}}<br/>
            结束：{{item.end_time|formatDateStdCh}}</p> -->
            <p class="valid">
            开始：{{item.start_time|formatDateStdCh}}<br/>
            结束：{{item.end_time|formatDateStdCh}}</p>
					<p class="limit">
						{{ item.full_type === 2 ? '每' : '' }}
						满{{ item.amount_limit || item.full }}积分可用
					</p>
        </div>
      </template>
      <template v-else>
        <span class="value">
          <span class="sign">&yen;</span>
          <span class="num">{{parseFloat(item.amount || item.reduce)}}</span>
        </span>
        <div class="content">
          <p class="type">{{item.title}}</p>
          <!-- <p class="valid" v-if="!is_start">领取后{{item.day}}天内有效</p>
          <p class="valid" v-else>
            开始：{{item.start_time|formatDateStdCh}}<br/>
            结束：{{item.end_time|formatDateStdCh}}</p> -->
             <p class="valid">
            开始：{{item.start_time|formatDateStdCh}}<br/>
            结束：{{item.end_time|formatDateStdCh}}</p>
					<p class="limit">
						{{ item.full_type === 2 ? '每' : '' }}
						满{{parseFloat(item.amount_limit || item.full)}}可用
					</p>
        </div>
        
      </template>
      
      <div class="dashed-line"></div>
      <div v-if="item.status !== 6">
        <div
            class="btn operate-btn flex-center"
            :class="map.get(item.status)"
            @click.stop="operate(item)"
        >{{txtMap.get(item.status)}}
        </div>
      </div>
      <div v-else>
        <div
            class="btn operate-btn flex-center"
            :class="map.get(item.status)"
        >{{txtMap.get(item.status)}}
        </div>
      </div>
    </li>
  </ul>
</template>

<script>
const map = new Map([
  [1, "unaccalimed"],
  [2, "unused"],
  [4, "used"],
  [5, "expired"],
  [3, "distributed"],
  [6, "viewed"],//不可使用
]);
const txtMap = new Map([
  [1, "立即领取"],
  [2, "立即使用"],
  [4, "已使用"],
  [5, "已过期"],
  [3, "分发完毕"],
  [6, "立即使用"],//不可使用
]);
import {mapState} from "vuex";
export default {
  name: "CouponList",
  props: {
    list: Array,
    is_start:Boolean,
  },
  data() {
    return {
      map: map,
      txtMap: txtMap
    };
  },
  computed: {
    ...mapState({
      businessType: state => state.businessInfo.type,//1现金商城，2积分商品，3内采商城
      showWay: state => state.config.show_way, // 商品展示方式，1积分，2现金
      integralRate: state => state.config.integral_rate // 积分比例
    }),
  },
  methods: {
    // 点击按钮
    operate(item) {
      this.$emit("operate", item);
    }
  },
};
</script>

<style lang="less" scoped>
@import "../../common/less/variable.less";
@import "../../common/less/mixin.less";

.coupon-list {
  width: 100%;

  .coupon-item {
    position: relative;
    display: flex;
    align-items: center;
    .wh(100%, 3rem);
    /* padding-left: 2.4rem;
    padding-right: 2.14rem; */
    .bis("../../assets/img_lingquan_bg01");

    .value {
      position: absolute;
      top: 0.55rem;
      left: 0.68rem;
      color: var(--main-color);
      line-height: 0.48rem;
      font-weight: bold;
      z-index: 2;

      .sign {
        font-size: 0.2rem;
      }

      .num {
        font-size: 0.6rem;
      }
    }

    .limit {
      .sc(@fontsize-small, @color-light-grey);
      line-height: 0.34rem;
      z-index: 2;
    }

    .content {
      position: absolute;
      left: 0.68rem;
      top: 38%;
      // width: 100%;
      overflow: hidden;

      .type {
        .sc(@fontsize-medium, @color-dark-grey);
		height: 0.3rem;
		line-height: 0.3rem;
      }

      .valid {
        .sc(@fontsize-small, @color-light-grey);
        line-height: 0.3rem;
      }
    }

    .dashed-line {
      .ct;
      right: 2.14rem;
      .wh(0.02rem, 1.2rem);
      border-right: 1px dashed var(--main-color);
    }

    .operate-btn {
      .ct;
      right: 0.46rem;
      .wh(1.46rem, 0.52rem);
      text-align: center;
      border-radius: 0.26rem;
      overflow: hidden;
      z-index: 2;

      &.unaccalimed,
      &.unused {
        .sc(@fontsize-small, #fff);
        line-height: 0.34rem;
        background: var(--main-color);
      }

      &.used {
        .sc(@fontsize-small, var(--main-color));
        line-height: 0.34rem;
        border: 1px solid var(--main-color);
      }

      &.expired {
        .sc(@fontsize-small, #ff5252);
        line-height: 0.34rem;
      }

      &.distributed {
        .sc(@fontsize-medium, @color-light-grey);
        line-height: 0.4rem;
      }

      &.viewed {
        .sc(@fontsize-small, @color-white);
        line-height: 0.34rem;
        background: @color-light-grey;
      }
    }

    &.old {
      .value {
        color: #ccc;
      }

      .dashed-line {
        border-right: 1px dashed #ccc;
      }
    }

    &.view {
      .value {
        color: @color-light-grey;
      }
    }

    & + .coupon-item {
      margin-top: -0.3rem;
    }
  }
}
</style>
