<template>
  <div id="prefecture">
    <ul class="prefecture-list" v-if="activeNav === 1">
      <li
        class="prefecture-item"
        v-for="(item, index) in prefectureList"
        :key="index"
        @click="clickPrefecture(item)"
      >
        <div class="img-box">
          <img
            :src="item.img | setHttps | ossProcess('style/w_240_h_auto')"
            alt="prefecture"
          />
        </div>
      </li>
    </ul>
    <div v-if="activeNav === 2" class="nav-type">
      <div
        class="box"
        v-if="prefectureList[0]"
        @click="clickPrefecture(prefectureList[0])"
      >
        <img
          :src="
            prefectureList[0].img | setHttps | ossProcess('style/w_240_h_auto')
          "
        />
      </div>
      <div class="box">
        <div
          class="up"
          v-if="prefectureList[1]"
          @click="clickPrefecture(prefectureList[1])"
        >
          <img
            :src="
              prefectureList[1].img
                | setHttps
                | ossProcess('style/w_240_h_auto')
            "
          />
        </div>
        <div
          class="down"
          v-if="prefectureList[2]"
          @click="clickPrefecture(prefectureList[2])"
        >
          <img
            :src="
              prefectureList[2].img
                | setHttps
                | ossProcess('style/w_240_h_auto')
            "
          />
        </div>
      </div>
    </div>
    <div v-if="activeNav === 3" class="nav-type">
      <div class="box">
        <div
          class="up"
          v-if="prefectureList[0]"
          @click="clickPrefecture(prefectureList[0])"
        >
          <img
            :src="
              prefectureList[0].img
                | setHttps
                | ossProcess('style/w_240_h_auto')
            "
          />
        </div>
        <div
          class="down"
          v-if="prefectureList[1]"
          @click="clickPrefecture(prefectureList[1])"
        >
          <img
            :src="
              prefectureList[1].img
                | setHttps
                | ossProcess('style/w_240_h_auto')
            "
          />
        </div>
      </div>
      <div
        class="box"
        v-if="prefectureList[2]"
        @click="clickPrefecture(prefectureList[2])"
      >
        <img
          :src="
            prefectureList[2].img | setHttps | ossProcess('style/w_240_h_auto')
          "
        />
      </div>
    </div>
  </div>
</template>

<script>
import { getSubject } from "@/services";
export default {
  name: "Prefecture",
  props: ["show", "config"],
  data() {
    return {
      prefectureList: [], // 专区列表
      // show: sessionStorage.getItem('show') ? sessionStorage.getItem('show') : '',
      /* businessType: [
        310,
        336
      ] */ //指定商城跳转
      activeNav: null, // 图片导航样式;1一排两列（默认样式）、2左一右二、3左二右一
    };
  },
  created() {
    this.loadData();
  },
  methods: {
    // 获取数据
    async loadData() {
      try {
        const res = await getSubject(this.$store.state.userInfo.business_id, 2);
        if (res.code === 0) {
          this.prefectureList = res.data;
          if (this.config) {
            this.activeNav = this.config.areaType;
          } else {
            this.activeNav = 1;
          }
          /* if (this.businessType.includes(this.businessId)) {
              this.prefectureList.forEach((item, index) => {
              if (index === 3) {
                this.$set(this.prefectureList[3], 'type', 5)
                console.log(this.prefectureList)
              }
            })
          } */
        } else {
          this.$dialog.toast({
            mes: res.msg,
            timeout: 1500,
            icon: "error",
          });
        }
      } catch (error) {
        console.warn(error);
      }
    },
    // 点击专区
    clickPrefecture(item) {
      // 类型 1无跳转 2商品专题 3商品 4自定义网址
      switch (item.type) {
        case 1:
          break;
        case 2:
          console.log("type", item.type);
          this.$router.push({
            name: "special_topic",
            query: {
              subject_id: item.subject_id,
            },
          });
          break;
        case 3:
          console.log("type", item.type);
          this.$router.push({
            name: "goods-detail",
            query: {
              item_id: item.item_id,
            },
          });
          break;
        case 4:
          console.log("type", item.type);
          window.open(item.link);
          break;
        // case 5:
        //   const shopType = localStorage.getItem('businessInfo') ?  JSON.parse(localStorage.getItem('businessInfo')) : {}
        //   shopType.type === 1 ? this.toPointShop() : this.toCashShop()
        //   break;
        default:
          break;
      }
    },
  },
  watch: {},
  computed: {},
};
</script>

<style lang="less" scoped>
@import "../../common/less/variable.less";
@import "../../common/less/mixin.less";

#prefecture {
  padding: 0.2rem 0.32rem 0.1rem;
  // margin-top: .16rem;

  .prefecture-list {
    position: relative;
    overflow: hidden;

    .prefecture-item {
      width: 48.7%;
      margin-bottom: 0.16rem;
      float: left;
      position: relative;
      z-index: 0;

      .img-box {
        .wh(100%, 1.36rem);

        img {
          .wh(100%, 100%);
        }
      }

      &:nth-child(odd) {
        margin-right: 2.4%;
      }
    }
  }

  .nav-type {
    overflow: hidden;
    width: 100%;

    .box {
      width: 48.7%;
      float: left;

      &:first-child {
        margin-right: 2.2%;
      }

      img {
        width: 100%;
        height: auto;
      }

      .up {
        margin-bottom: 2.2%;
      }
    }
  }
}
</style>
