<template>
  <div class="page">
    <HeadNav />
    <!-- S 搜索输入框 -->
    <div class="search-blank flex-center" slot="navbar">
      <search-box
        type="search"
        :search_val="keyword"
        @search="searchItem"
        @input="checkInput"
        @changeKeyword="changeKeyword"
      ></search-box>
    </div>
    <!-- E 搜索输入框 -->

    <!-- S 搜索结果 -->
    <yd-infinitescroll :callback="loadItemList" ref="infinitescrollDemo">
      <div class="search-result-wrap" slot="list">
        <!-- <div class="price-search" v-if="itemList.length">
          <div class="price-input flex-center">
            <input type="number" v-model="min_price" placeholder="最低价格" />
          </div>
          <span class="line"></span>
          <div class="price-input flex-center">
            <input type="number" v-model="max_price" placeholder="最高价格" />
          </div>
          <span class="btn search-btn" @click="searchItem(keyword)">搜索</span>
        </div>-->
        <div class="price-search">
          <div class="price-input flex-center" style="width:3.8rem">
            <input type="number" v-model="min_price" placeholder="最低价格" />
          </div>
          <span class="line"></span>
          <div class="price-input flex-center" style="width:3.8rem">
            <input type="number" v-model="max_price" placeholder="最高价格" />
          </div>
        </div>
        <!-- / 最低/最高价格 -->

        <div class="search-tab" v-show="itemList.length">
          <ul class="tab-list">
            <li
              v-for="(item, index) in tabs"
              :key="index"
              :class="{ active: activeTab === index }"
              @click="clickTab(index)"
            >
              <span class="txt">{{ item }}</span>
              <i
                class="icon icon-price"
                v-if="index === 2"
                :class="{
                  rise: activeTab === index && priceToggle,
                  drop: activeTab === index && !priceToggle
                }"
              ></i>
            </li>
          </ul>
          <div class="btn toggle-btn flex-center" @click="toggleStyle">
            <i class="icon icon-col" :class="{ row: !!rowToggle }"></i>
          </div>
        </div>
        <!-- / 筛选的tab -->

        <div class="search-result" v-if="!rowToggle">
          <item-list :itemList="itemList" @click-item="clickItem"></item-list>
        </div>
        <!-- / 搜索结果：两列的样式 -->

        <!-- / 搜索结果： 一列的样式 -->
        <div class="search-result" v-else>
          <row-item-list
            :itemList="itemList"
            @click-item="clickItem"
          ></row-item-list>
        </div>
      </div>

      <!-- 数据全部加载完毕显示 -->
      <span slot="doneTip" v-show="itemList.length">- 没有更多了 -</span>
    </yd-infinitescroll>
    <!-- E 搜索结果 -->

    <yd-backtop></yd-backtop>

    <!-- S 搜索历史记录 -->
    <div class="search-history-wrap" v-if="searchHistory.length && showHistory">
      <h3 class="title">搜索历史记录</h3>
      <ul class="search-history">
        <li
          v-for="(item, index) in searchHistory"
          :key="index"
          @click.stop="searchItem(item)"
        >
          <i class="icon icon-time"></i>
          <p class="txt">{{ item }}</p>
          <div
            class="btn del-btn flex-center"
            @click.stop="deleteHistory(index)"
          >
            <i class="icon icon-delete"></i>
          </div>
        </li>
      </ul>
      <footer class="clear-history" @click="clearAllHistory">
        清空搜索历史
      </footer>
    </div>
    <!-- E 搜索历史记录 -->

    <!-- S 搜索为空 -->
    <div class="search-none" v-if="emptyResult">
      <div class="icon icon-search-none"></div>
      <p class="tip">没有搜到相关产品，换个名称试试吧</p>
      <div class="btn go-btn flex-center" @click="goShopping">去逛逛</div>
    </div>
    <!-- E 搜索为空 -->

    <!-- S 提示框 -->
    <confirm-alert
      v-model="showClearAlert"
      confirmText="确定清空所有历史记录吗"
      @cancel="cancelClear"
      @confirm="confirmClear"
    ></confirm-alert>
    <!-- E 提示框 -->
  </div>
</template>

<script>
import Test1 from "@/assets/img_home_fangjian01@3x.png";
import Test2 from "@/assets/img_home_tuangou02@3x.png";
import SearchBox from "@/components/search/SearchBox";
import ItemList from "@/components/list/ItemList";
import RowItemList from "@/components/list/RowItemList";
import ConfirmAlert from "@/components/common/ConfirmAlert";
import { getStore, setStore } from "@/utils/common";
import { mapState } from "vuex";
import { getItemList } from "@/services";
import HeadNav from "@/components/common/HeadNav";

export default {
  name: "SearchJp",
  components: {
    SearchBox,
    ItemList,
    RowItemList,
    ConfirmAlert,
    HeadNav
  },
  data() {
    return {
      keyword: "", // 搜索关键词
      page: 1, // 初始页
      pageSize: 10, // 每次请求数据量
      itemList: [], // 搜索结果
      min_price: "", // 最低价
      max_price: "", // 最高价
      subject_id: "",
      category_id: "",
      tabs: ["综合", "销量", "价格"], // tab
      activeTab: 0, // 挡墙选择tab下标
      priceToggle: false, // 价格切换，默认false,升序
      rowToggle: false, // 样式切换， 默认false,显示两列
      searchHistory: [], // 搜索历史记录
      showHistory: true, // 是否显示历史记录，只有在返回搜索结果后隐藏
      emptyResult: false, // 搜索结果为空时显示
      showClearAlert: false, // 是否显示清空弹窗
      homeTop: 0
    };
  },
  computed: {
    ...mapState({
      //搜索参数
      search_where: state => state.search_where
    })
  },
  created() {
    this.loadItemList();
  },
  // created() {
  //   if (this.search_where) {
  //     let where = this.search_where
  //     this.keyword = where.keyword;
  //     this.min_price = where.price_start;
  //     this.max_price = where.price_end;
  //     this.searchItem(this.keyword);
  //     this.changeKeyword(this.keyword);
  //   }
  // },
  // mounted() {
  //   //获取搜索历史记录
  //   if (getStore("searchHistory")) {
  //     this.searchHistory = JSON.parse(getStore("searchHistory"));
  //   }
  // },
  activated() {
    // if (getStore("searchHistory")) {
    //   this.searchHistory = JSON.parse(getStore("searchHistory"));
    // }
  },
  methods: {
    // 发起搜索
    searchItem(historyValue) {
      this.$refs.infinitescrollDemo.$emit("ydui.infinitescroll.reInit");
      if (historyValue) {
        this.keyword = historyValue;
      }
      //隐藏历史记录
      this.showHistory = false;
      this.itemList = [];
      //获取搜索结果
      this.page = 1;
      this.loadItemList();
      /**
       * 点击搜索结果进入下一页面时进行判断是否已经有一样的历史记录
       * 如果没有则新增，如果有则不做重复储存，判断完成后进入下一页
       */
      let history = getStore("searchHistory");
      if (history) {
        let checkrepeat = false;
        this.searchHistory = JSON.parse(history);
        this.searchHistory.forEach(item => {
          if (item == this.keyword) {
            checkrepeat = true;
          }
        });
        if (!checkrepeat) {
          // 判断是商品搜索，如果有就显示历史记录  如果是价格搜索就不显示
          if (this.keyword) {
            this.searchHistory.push(this.keyword);
          }
        }
      } else {
        // 判断是商品搜索，如果有就显示历史记录  如果是价格搜索就不显示
        if (this.keyword) {
          this.searchHistory.push(this.keyword);
        }
      }
      setStore("searchHistory", this.searchHistory);
    },
    // 获取搜索结果
    async loadItemList() {
      console.log(this.min_price);
      try {
        let where = {};
        let source_type = this.$route.query.source_type || "";
        where.source_type = source_type;
        where.keyword = this.keyword;
        where.price_start = this.min_price;
        where.price_end = this.max_price;
        where.subject_id = this.$route.query.subject_id || "";
        (where.category_id = this.$route.query.category_id || ""),
          console.log(this.activeTab);

        if (this.activeTab === 0) {
          where.order = "merge";
        } else if (this.activeTab === 1) {
          where.order = "sales";
        } else if (this.activeTab === 2 && !this.priceToggle) {
          where.order = "price_desc";
        } else if (this.activeTab === 2 && this.priceToggle) {
          where.order = "price_asc";
        }
        where.page = this.page;
        where.listRows = this.pageSize;
        const res = await getItemList(where);

        if (res.code === 0) {
          const { data, total } = res.data;
          this.itemList = [...this.itemList, ...data];
          this.emptyResult = !this.itemList.length;
          if (!this.itemList.length) return;
          if (this.itemList.length === total) {
            // 所有数据加载完毕
            this.$refs.infinitescrollDemo.$emit(
              "ydui.infinitescroll.loadedDone"
            );
            return;
          }
          // 单次请求数据完毕
          this.$refs.infinitescrollDemo.$emit("ydui.infinitescroll.finishLoad");

          this.page++;
        } else {
          this.$dialog.toast({
            mes: res.msg,
            timeout: 1500,
            icon: "error"
          });
        }
        this.emptyResult = !this.itemList.length;
      } catch (error) {
        console.warn("Something bad happened: ", error);
      }
    },
    // 点击商品
    clickItem(item) {
      let where = {};
      where.keyword = this.keyword;
      where.price_start = this.min_price;
      where.price_end = this.max_price;
      this.$store.commit("getSearchWhere", where);
      this.$router.push({
        name: "goods-detail",
        query: {
          item_id: item.id
        }
      });
    },
    //搜索结束后，删除搜索内容直到为空时清空搜索结果，并显示历史记录
    checkInput() {
      // console.log("input?", this.keyword);
      this.$nextTick(() => {
        if (!this.keyword) {
          this.showHistory = true; //显示历史记录
          this.itemList = []; //清空搜索结果
          this.page = 1;
          this.emptyResult = false; //隐藏搜索为空提示
        }
      });
    },
    // 改变input的值
    changeKeyword(val) {
      this.keyword = val;
      this.page = 1;
    },
    // 点击tab
    clickTab(index) {
      if (this.itemList.length) {
        this.activeTab = index;
        if (this.activeTab === 2) {
          this.priceToggle = !this.priceToggle;
        }
        this.page = 1;
        this.itemList = [];
        this.loadItemList();
      }
    },
    // 切换样式
    toggleStyle() {
      this.rowToggle = !this.rowToggle;
    },
    //点击删除按钮，删除当前历史记录
    deleteHistory(index) {
      this.searchHistory.splice(index, 1);
      setStore("searchHistory", this.searchHistory);
    },
    // 点击去逛逛
    goShopping() {
      this.$router.push("home");
    },
    // 点击清空按钮
    clearAllHistory() {
      this.showClearAlert = true;
    },
    // 点击取消按钮
    cancelClear() {
      this.showClearAlert = false;
    },
    // 点击确定按钮，清除所有历史记录
    confirmClear() {
      this.showClearAlert = false;
      this.searchHistory = [];
      setStore("searchHistory", this.searchHistory);
    }
  },
  beforeRouteLeave(to, from, next) {
    if (to.name !== "goods-detail") {
      this.$store.commit("clearSearchWhere"); //清空搜索
    }
    next();
  }
  // beforeRouteEnter(to, from, next) {
  //   next(vm => {
  //     if (from.name !== "goods-detail") {
  //       // if (vm.search_where) {
  //       //   let where = vm.search_where
  //       //   vm.keyword = where.keyword;
  //       //   vm.min_price = where.price_start;
  //       //   vm.max_price = where.price_end;
  //       //   vm.searchItem(vm.keyword);
  //       //   vm.changeKeyword(vm.keyword);
  //       // }
  //       //获取搜索历史记录
  //       vm.keyword = ""; // 搜索关键词
  //       vm.page = 1; // 初始页
  //       vm.pageSize = 10; // 每次请求数据量
  //       vm.itemList = []; // 搜索结果
  //       vm.min_price = ""; // 最低价
  //       vm.max_price = ""; // 最高价
  //       vm.activeTab = 0; // 挡墙选择tab下标
  //       vm.priceToggle = false; // 价格切换，默认false,升序
  //       vm.rowToggle = false; // 样式切换， 默认false,显示两列
  //       vm.searchHistory = []; // 搜索历史记录
  //       vm.showHistory = true; // 是否显示历史记录，只有在返回搜索结果后隐藏
  //       vm.emptyResult = false; // 搜索结果为空时显示
  //       vm.showClearAlert = false; // 是否显示清空弹窗
  //       vm.$refs.infinitescrollDemo.$emit("ydui.infinitescroll.reInit");
  //       if (getStore("searchHistory")) {
  //         vm.searchHistory = JSON.parse(getStore("searchHistory"));
  //       }
  //     }
  //   });
  // }
};
</script>

<style lang="less" scoped>
@import "../../common/less/variable.less";
@import "../../common/less/mixin.less";

.page {
  background-color: #fff;
  height: 100%;

  .search-blank {
    .wh(100%, 1rem);
    padding: 0 0.3rem;
  }

  .search-history-wrap {
    border-top: 0.2rem solid @color-background-grey;

    .title {
      padding: 0.2rem 0.3rem;
      .sc(@fontsize-small, @color-dark-grey);
      line-height: 0.34rem;
      font-weight: 500;
    }

    .search-history {
      padding-left: 0.3rem;

      li {
        position: relative;
        display: flex;
        width: 100%;
        padding: 0.22rem 0;
        border-bottom: 1px solid #ededee;
        overflow: hidden;
        z-index: 1;

        .icon-time {
          display: inline-block;
          .wh(0.2rem, 0.2rem);
          margin-right: 0.2rem;
          .bis("../../assets/ic_time");
        }

        .txt {
          .sc(@fontsize-medium, @color-dark-grey);
          line-height: 0.32rem;
          word-wrap: break-word;
          word-break: break-all;
        }

        .del-btn {
          position: absolute;
          top: 0;
          right: 0;
          .wh(0.96rem, 0.8rem);
          z-index: 2;

          .icon-delete {
            .wh(0.36rem, 0.36rem);
            .bis("../../assets/del");
          }
        }
      }
    }

    .clear-history {
      .wh(2rem, 0.6rem);
      margin: 0.78rem auto 0;
      .sc(@fontsize-small, @color-grey);
      line-height: 0.6rem;
      text-align: center;
      white-space: nowrap;
      background-color: @color-background-grey;
      border-radius: 0.08rem;
      overflow: hidden;
    }
  }

  .search-none {
    display: flex;
    flex-direction: column;
    align-items: center;
    border-top: 0.2rem solid @color-background-grey;

    .icon-search-none {
      .wh(2.2rem, 1.8rem);
      margin: 2.34rem auto 0.6rem;
      .bis("../../assets/icon_serch_kong");
    }

    .tip {
      margin-bottom: 0.2rem;
      .sc(@fontsize-medium, #909090);
      line-height: 0.4rem;
      text-align: center;
    }

    .go-btn {
      .wh(2.8rem, 0.68rem);
      .sc(@fontsize-large, #fff);
      line-height: 0.68rem;
      text-align: center;
      white-space: nowrap;
      background-color: var(--main-color);
      border-radius: 0.44rem;
      overflow: hidden;
      .touch-active;
    }
  }

  /deep/ .search-result-wrap {
    margin-bottom: 0.06rem;

    .price-search {
      display: flex;
      align-items: center;
      padding: 0 0.3rem 0.06rem;

      .price-input {
        .wh(2.8rem, 0.64rem);
        border-radius: 0.08rem;
        border: 1px solid @color-row-line;
        overflow: hidden;

        input {
          .wh(100%, 100%);
          padding: 0.1rem 0.4rem;
          .sc(@fontsize-medium, @color-dark-grey);
          line-height: 0.44rem;

          &:disabled {
            outline: none;
            border: none;
          }

          &:active {
            outline: var(--main-color);
          }
        }
      }

      .line {
        display: inline-block;
        .wh(0.2rem, 0.02rem);
        margin: 0 0.08rem;
        background-color: var(--main-color);
      }

      .search-btn {
        margin-left: 0.3rem;
        .sc(@fontsize-medium, @color-grey);
        line-height: 0.4rem;
      }
    }

    .search-tab {
      position: relative;
      display: flex;
      align-items: center;
      .wh(100%, 0.88rem);
      padding-right: 1.2rem;
      overflow: hidden;
      box-shadow: 0px 0.06rem 0.4rem rgba(0, 0, 0, 0.05);

      .tab-list {
        position: relative;
        width: 100%;
        overflow: hidden;

        li {
          width: 30%;
          float: left;
          text-align: center;
          position: relative;
          z-index: 0;

          .txt {
            position: relative;
            .sc(0.28rem, @color-light-grey);
            line-height: 0.4rem;
            letter-spacing: 0.01rem;
          }

          .icon-price {
            display: inline-block;
            .wh(0.18rem, 0.2rem);
            margin-left: 0.04rem;
            .bis("../../assets/icon_jiage");
          }

          &.active {
            .txt {
              color: @color-dark-grey;
              font-weight: 500;

              &:after {
                content: "";
                .cl;
                bottom: -0.04rem;
                .wh(0.5rem, 0.04rem);
                background-color: var(--main-color);
                border-radius: 0.06rem;
              }
            }
          }
        }
      }

      .toggle-btn {
        .ct;
        right: 0;
        .wh(1.2rem, 0.88rem);
        z-index: 2;

        .icon-col {
          .wh(0.4rem, 0.4rem);
          .bis("../../assets/icon_sousuo_qiehuan");

          &.row {
            .bis("../../assets/ic_list_qiehuan");
          }
        }
      }
    }

    .search-result {
      padding: 0.1rem 0.3rem;
      // background-color: @color-background-grey;
    }
  }
}
</style>
