var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "page" }, [
    _c("div", { staticClass: "info-box" }, [
      _c("div", { staticClass: "title" }, [
        _c("span", { staticClass: "txt" }, [_vm._v("商城公告")]),
        _c("span", { staticClass: "time" }, [
          _vm._v(_vm._s(_vm._f("formatDateTimeHm")(_vm.info.ctime))),
        ]),
      ]),
      _c("div", { staticClass: "content" }, [
        _c("div", { staticClass: "no-title" }, [
          _vm._v(_vm._s(_vm.info.title)),
        ]),
        _c("div", { domProps: { innerHTML: _vm._s(_vm.info.contents) } }),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }