<template>
  <div id="coupon">
    <router-link class="img-box" to="coupon_center" tag="div">
      <img :src="coupon" alt="coupon">
    </router-link>
  </div>
</template>

<script>
import Coupon from "@/assets/img_home_qianggou@3x.png";
import { getCoupon } from "@/services";
import { ossProcess } from "../../utils/alioss";
export default {
  name: "Coupon",
  data() {
    return {
      coupon: Coupon // 领券中心图
    };
  },
  created() {
    this.loadData();
  },
  methods: {
    // 获取数据
    async loadData() {
      try {
        const res = await getCoupon(this.$store.state.userInfo.business_id);
        if (res.code === 0) {
          this.coupon = ossProcess(res.data.img, "style/w_480_h_auto");
        } else {
          this.$dialog.toast({
            mes: res.msg,
            icon: "error"
          });
        }
      } catch (error) {
        console.warn(error);
      }
    }
  }
};
</script>

<style lang="less" scoped>
@import "../../common/less/mixin.less";

#coupon {
  .img-box {
    // .wh(100%, 2rem);
	width: 100%;
	margin-top: 3px;
    img {
      width: 96%;
      margin-left: 2%;
    }
  }
}
</style>
