<template>
  <div id="banner">
    <yd-slider autoplay="3000" :showPagination="false" :callback="getCurrentPage">
      <yd-slider-item
        v-for="(item, index) in banners"
        :key="index"
        @click.native="clickBanner(item)"
      >
        <div class="img-box">
          <img :src="item.img | setHttps | ossProcess('style/w_960_h_auto')" alt="banner">
        </div>
      </yd-slider-item>
    </yd-slider>
    <div class="pagination flex-center">
      <span
        class="pagination-item"
        :class="{'pagination-item-active': currentPage === index}"
        v-for="(item, index) in banners"
        :key="index"
      ></span>
    </div>
    <!-- <div class="shadow"></div> -->
  </div>
</template>

<script>
import testImg from "@/assets/img_home_banner@3x.png";
import { getSlider } from "@/services";
import { wxShare } from "../../utils/common";
export default {
  name: "Banner",
  data() {
    return {
      banners: [], // 轮播图数据
      currentPage: 0 // 当前页数
    };
  },
  created() {
    this.loadData();
  },
  methods: {
    // 请求数据
    async loadData() {
      try {
        const res = await getSlider(this.$store.state.userInfo.business_id);
        window.localStorage.setItem("getShareImg",res.data[0].img+"?x-oss-process=image/resize,m_fill,h_100,w_100")
        if (res.code === 0) {
          this.banners = res.data;
        } else {
          this.$dialog.toast({
            mes: res.msg,
            icon: "error"
          });
        }
      } catch (error) {
        console.warn(error);
      }
      wxShare()
    },
    // 点击banner
    clickBanner(item) {
      // 类型 1无跳转 2商品专题 3商品 4自定义网址
      switch (item.type) {
        case 1:
          break;
        case 2:
          this.$router.push({
            name: "special_topic",
            query: {
              subject_id: item.subject_id
            }
          });
          break;
        case 3:
          this.$router.push({
            name: "goods-detail",
            query: {
              item_id: item.item_id
            }
          });
          break;
        case 4:
          window.location.href = item.link
          break;
        default:
          break;
      }
    },
    // 获取当前页数
    getCurrentPage(page) {
      this.currentPage = page;
    }
  }
};
</script>

<style lang="less" scoped>
@import "../../common/less/mixin.less";

@keyframes opacity-in {
  0% {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

#banner {
  position: relative;
  .wh(100%, 4.28rem);
  padding: 0.16rem 0.311rem 0.34rem;
  z-index: 1;

  .img-box {
    .wh(100%, 3.3rem);
		padding: 0 5px;

    img {
      .wh(100%, 100%);
    }
  }

  .pagination {
    .cl;
    bottom: 0.34rem;
    width: 100%;
    z-index: 2;
    pointer-events: none;
    opacity: 0;
    animation: opacity-in 0.3s linear forwards;

    .pagination-item {
      display: inline-block;
      .wh(0.1rem, 0.1rem);
      margin: 0.05rem;
      border-radius: 0.05rem;
      background-color: #e1e1e1;

      &.pagination-item-active {
        width: 0.24rem;
        background-color: #333;
      }
    }
  }

  .shadow {
    .cl;
    bottom: 0.34rem;
    .wh(100%, 1.14rem);
    .bis("../../assets/img_home_banner_shadow");
  }
}
</style>
