var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "mall" },
    [
      _c("Search"),
      _c("Banner"),
      _c("div", { staticClass: "my-points" }, [
        _vm._m(0),
        _c("p", { staticClass: "points-com space" }, [_vm._v("我的商城积分")]),
        _c("p", { staticClass: "points-com" }, [
          _vm._v(_vm._s(_vm.use_info.integral)),
        ]),
      ]),
      _c(
        "yd-infinitescroll",
        { ref: "infinitescrollDemo", attrs: { callback: _vm.loadItemList } },
        [
          _c(
            "div",
            { staticClass: "item-list", attrs: { slot: "list" }, slot: "list" },
            [
              _c("item-list", {
                attrs: { itemList: _vm.itemList },
                on: { "click-item": _vm.clickItem },
              }),
              _vm.itemList.length === 0 && _vm.emptyStatus
                ? _c("empty", { attrs: { icon: _vm.emptyIcon } })
                : _vm._e(),
            ],
            1
          ),
          _c(
            "span",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.itemList.length,
                  expression: "itemList.length",
                },
              ],
              attrs: { slot: "doneTip" },
              slot: "doneTip",
            },
            [_vm._v("- 没有更多了 -")]
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "points-com" }, [
      _c("img", {
        attrs: { src: require("@/assets/icon_topic_points.png"), alt: "" },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }